import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ApplicationTopBarContainer } from './ApplicationTopBarContainer';
import { Login } from './Login';

export interface IShellProps {
}

const Shell: FC<IShellProps> = () => {
    const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
    const isUnauthenticated = isAuthLoading || !isAuthenticated;
    return (
        <>
            <ApplicationTopBarContainer />
            <div style={{
                height: 'calc(100dvh - (48px + env(safe-area-inset-top) + env(safe-area-inset-bottom)))',
                paddingLeft: 'env(safe-area-inset-left)',
                paddingRight: 'env(safe-area-inset-right)',
                overflowX: 'auto',
                overflowY: 'auto',
                background: '#FAFAFA'
            }}>
                {isUnauthenticated ? <Login /> : <Outlet />}
            </div>
            {/* We use the bottom right of the screen very often in this app so I have commented this out. It is still a valuable troubleshooting tool so I don't want to completely remove it. */}
            {/* <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                children={<PermissionToggler getPermissionsQuery={useLazyGetUserPermissionsQuery} />}
            /> */}
        </>
    );
};

export default Shell;
