import { PersonRemove, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { Box, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import { UserGroupUserListViewDto } from '../../dtos';
import { useGetUserGroupsMembersQuery } from '../../store/generated/generatedApi';
import { useGroupMemberManager } from './useGroupMemberManager';

const EXPECTED_X_PADDING = 64;

const CUSTOM_BUTTON_STYLES = {
    bgcolor: 'primary.main',
    color: 'primary.contrastText',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
};

export interface IGroupMemberManagementSectionProps {
    groupId: string;
}

export const GroupMemberManagementSection: FC<IGroupMemberManagementSectionProps> = ({ groupId }) => {
    const { currentTab, setCurrentTab, handleAcceptMemberClicked, handleDenyMemberClicked, handleRemoveMemberClicked } = useGroupMemberManager(groupId);

    const renderPendingMemberActions = useCallback(
        (member: UserGroupUserListViewDto) => {
            return (
                <>
                    <IconButton size='small' onClick={() => handleAcceptMemberClicked(member.userId)}>
                        <Box {...CUSTOM_BUTTON_STYLES}>
                            <ThumbUpOutlined />
                        </Box>
                    </IconButton>
                    <IconButton size='small' onClick={() => handleDenyMemberClicked(member.userId)}>
                        <Box {...CUSTOM_BUTTON_STYLES}>
                            <ThumbDownOutlined />
                        </Box>
                    </IconButton>
                </>
            );
        },
        [handleAcceptMemberClicked, handleDenyMemberClicked]
    );

    const renderAcceptedMemberActions = useCallback(
        (member: UserGroupUserListViewDto) => {
            return (
                <IconButton size='small' color='primary' onClick={() => handleRemoveMemberClicked(member.userId)}>
                    <Box {...CUSTOM_BUTTON_STYLES}>
                        <PersonRemove />
                    </Box>
                </IconButton>
            );
        },
        [handleRemoveMemberClicked]
    );

    const getSharedColumn = useCallback(
        (otherColumnWidths: number): DataTableColumn<UserGroupUserListViewDto>[] => [
            {
                key: 'info',
                label: 'Name/Email',
                sortKey: 'NAME',
                unsorted: true,
                width: `${window.innerWidth - EXPECTED_X_PADDING - otherColumnWidths}px`,
                fieldMapper: (usr) => (
                    <Box display='flex' flexDirection='column' color={'#58585A'} overflow={'auto'}>
                        <Typography>{usr.userName}</Typography>
                        <Typography>{usr.userEmail}</Typography>
                    </Box>
                ),
            },
        ],
        []
    );

    const PendingMemberTableColumns: DataTableColumn<UserGroupUserListViewDto>[] = useMemo(
        () => [...getSharedColumn(100), { key: 'actions', label: '', sortKey: '', unsorted: true, width: '100px', fieldMapper: renderPendingMemberActions }],
        [renderPendingMemberActions, getSharedColumn]
    );

    const AcceptedMemberTableColumns: DataTableColumn<UserGroupUserListViewDto>[] = useMemo(
        () => [...getSharedColumn(50), { key: 'actions', label: '', sortKey: '', unsorted: true, width: '50px', fieldMapper: renderAcceptedMemberActions }],
        [renderAcceptedMemberActions, getSharedColumn]
    );

    return (
        <>
            <Paper>
                <Tabs value={currentTab} onChange={(_, v) => setCurrentTab(v)} variant='fullWidth'>
                    <Tab label='Pending Members' />
                    <Tab label='Members' />
                </Tabs>
                {currentTab === 0 && (
                    <MobileListView
                        additionalQueryParameters={{ groupId: groupId, status: 'Pending' }}
                        getDataQuery={useGetUserGroupsMembersQuery}
                        permissionName='userGroups'
                        tableColumns={PendingMemberTableColumns}
                        hideSearch
                        hideRowsPerPageSelect
                        fixedPageSize={10}
                    />
                )}
                {currentTab === 1 && (
                    <MobileListView
                        additionalQueryParameters={{ groupId: groupId, status: 'Accepted' }}
                        getDataQuery={useGetUserGroupsMembersQuery}
                        permissionName='userGroups'
                        tableColumns={AcceptedMemberTableColumns}
                        hideSearch
                        hideRowsPerPageSelect
                        fixedPageSize={10}
                    />
                )}
            </Paper>
        </>
    );
};
