import { GroupRemove, People, Settings } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback } from 'react';
import { ConfirmActionButton, PageHeader } from '../../Components/Global';
import { UserGroupDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { standardDateFormat } from '../../util';
import { GroupMetricsSection } from './GroupMetricsSection';
import { GroupSharedActsSection } from './GroupSharedActsSection';
import { useGroupMemberManager } from './useGroupMemberManager';

export interface IGroupDetailsFormProps {
    group: UserGroupDto;
}

export const GroupDetailsForm: FC<IGroupDetailsFormProps> = ({ group }) => {
    const goTo = useGoTo();
    const { handleLeaveGroupClicked } = useGroupMemberManager(group.id);

    const handleManageClicked = useCallback(() => {
        goTo.GroupEdit(group.id);
    }, [goTo, group.id]);

    return (
        <Box display='flex' flexDirection='column' rowGap={1} height='100%' sx={{ overflowY: 'auto' }}>
            <PageHeader
                onActionButtonClicked={goTo.GroupHome}
                icon={<People fontSize='large' />}
                title={group.name}
                endText={standardDateFormat(group.endDate)}
            />
            <Box display='flex' flexDirection='column' gap={1} px={1} flexGrow={1} minHeight={300}>
                <GroupMetricsSection metrics={group.metrics} />
                <GroupSharedActsSection groupId={group.id} />
            </Box>
            {group.isAdmin && (
                <Box display='flex' flexDirection='row' gap={1} px={1}>
                    <Button fullWidth startIcon={<Settings />} variant='contained' size='large' onClick={handleManageClicked}>
                        Manage
                    </Button>
                </Box>
            )}
            {!group.isAdmin && (
                <Box display='flex' flexDirection='row' gap={1} px={1}>
                    <ConfirmActionButton startIcon={<GroupRemove />} actionName='Leave Group' color='error' onConfirm={handleLeaveGroupClicked} />
                </Box>
            )}
        </Box>
    );
};
