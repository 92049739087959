import { DirectionsRun, DoneAll, Group } from '@mui/icons-material';
import { Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { UserGroupMetricsDto } from '../../dtos';

export interface IGroupMetricsSectionProps {
    metrics: UserGroupMetricsDto;
};

export const GroupMetricsSection: FC<IGroupMetricsSectionProps> = ({ metrics }) => {
    return (
        <Paper sx={{ p: 1 }}>
            <Box display='flex' justifyContent='space-between' alignItems='center' gap={1}>
                <Chip icon={<DirectionsRun />} label={`${metrics.numSharedActs} Act${metrics.numSharedActs !== 1 ? 's' : ''}`} />
                <Chip icon={<DoneAll />} label={`${metrics.numCompletedActs} Completed`} />
                <Chip icon={<Group />} label={`${metrics.numAcceptedMembers}`} />
            </Box>
        </Paper>
    );
};