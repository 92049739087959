import { FC } from 'react';
import { useGoTo } from '../../routing/useGoTo';
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PageHeader } from '../../Components/Global';
import { Home } from '@mui/icons-material';

export interface IInvitationPendingProps {
    groupName: string;
}

export const InvitationPending: FC<IInvitationPendingProps> = ({ groupName }) => {
    const goTo = useGoTo();

    return (
        <>
            <PageHeader title={`Invitation Accepted!`}/>
            <Paper sx={{ m: 1 }}>
                <Box p={1} textAlign='center'>
                    <Typography>
                        You have accepted an invitation to {groupName}. Your membership is pending until the group admin reviews your application.
                    </Typography>
                </Box>
            </Paper>
            <Box px={1}>
                <Button variant='outlined' onClick={goTo.Home} fullWidth startIcon={<Home />}>
                    Go Home
                </Button>
            </Box>
        </>
    );
};
