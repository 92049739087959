import { AddCircle, History } from '@mui/icons-material';
import { Box, Button, Icon, IconButton, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { IFormProps, LoadingIndicator } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { JournalDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { standardDateFormat } from '../../util';
import { emptyGuid } from '../../util/constants';
import { useJournalFormManager } from './useJournalFormManager';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const DEFAULT_JOURNAL: JournalDto = {
    date: new Date(),
    spaceId: '',
    people: [],
    questionSet: {},
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
    questions: [],
};

export const StartJournalForm: FC<IFormProps<JournalDto>> = ({ save, initValues = DEFAULT_JOURNAL, isLoading }) => {
    const { formJournal, currentSpaces, isLoadingSpaces } = useJournalFormManager(initValues);

    const goTo = useGoTo();

    const handleSave = (spaceId: string) => {
        save({ ...DEFAULT_JOURNAL, spaceId });
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader icon={<AddCircle />} title='Start Praying' endText={standardDateFormat(formJournal.date)} />
            <Box display='flex' flexDirection='column' gap={1} px={1}>
                <Paper>
                    <Box display='flex' flexDirection='column' gap={1} p={1}>
                        <Typography>What Space are you praying for?</Typography>
                        <Box display='flex' flexDirection='row' gap={1} flexWrap='wrap' justifyContent='center'>
                            {isLoadingSpaces && <LoadingIndicator />}
                            {currentSpaces?.map((space) => (
                                <Button key={space.id} onClick={() => handleSave(space.id)} variant='contained' startIcon={<Icon>{space.icon}</Icon>}>
                                    {space.name}
                                </Button>
                            ))}
                            {!isLoadingSpaces && (
                                <IconButton onClick={goTo.SpaceCreate} color='primary' sx={{ padding: 0 }}>
                                    <AddCircleIcon fontSize='large' />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Paper>
                <Button fullWidth startIcon={<History />} color='inherit' variant='outlined' size='large' onClick={goTo.JournalHome}>
                    View History
                </Button>
            </Box>
        </Box>
    );
};
