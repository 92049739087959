import { Box } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGetUserGroupsByIdQuery } from '../../store/generated/generatedApi';
import { GroupDetailsForm } from './GroupDetailsForm';

export const GroupDetailsView: FC = () => {
    const { id } = useParams();
    const { data, isLoading, error, refetch } = useGetUserGroupsByIdQuery({ id: id! });

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error || !data) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Box pb={1} height='100%'>
            <GroupDetailsForm group={data} />
        </Box>
    );
};
