import { Delete } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { PageHeader } from '../../Components/Global';

export interface IUserDeleteConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const UserDeleteConfirmationModal: FC<IUserDeleteConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm
}) => {
    const [dialogText, setDialogText] = useState('');

    const resetTextAndThen = useCallback((callback: () => void) => () => {
        setDialogText('');
        callback();
    }, [setDialogText]);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth='xs'>
            <PageHeader icon={<Delete />} title='Confirm Delete' />
            <DialogContent>
                <Box textAlign='center' mb={2}>
                    <Typography variant='body1'>To delete this account, type "DELETE" below. This action cannot be undone.</Typography>
                </Box>
                <TextField fullWidth variant='outlined' onChange={(e) => setDialogText(e.target.value)} placeholder='Type DELETE here' />
            </DialogContent>
            <DialogActions>
                <Button onClick={resetTextAndThen(onClose)} variant='outlined' color='primary'>
                    Cancel
                </Button>
                <Button onClick={resetTextAndThen(onConfirm)} variant='contained' color='error' disabled={dialogText !== 'DELETE'}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};