import { Autocomplete, AutocompleteRenderOptionState, Box, Chip, ListItem, TextField, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useGetUserGroupsSharedActSummariesQuery } from '../../store/generated/generatedApi';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { UserGroupUserSharedActsSummaryListItemDto } from '../../dtos';
import { DirectionsRun, DoneAll } from '@mui/icons-material';

export const SHARED_FEARLESS_ACTS_RESERVED_IDS = {
    Recent: 'recent',
    All: 'all',
};

const RECENT_FEARLESS_ACT_OPTION = {
    userId: SHARED_FEARLESS_ACTS_RESERVED_IDS.Recent,
    userName: 'Recently Completed',
    numSharedActs: 0,
    numCompletedActs: 0,
    isCurrentUsersSummary: false,
};

const ALL_FEARLESS_ACTS_OPTION = {
    userId: SHARED_FEARLESS_ACTS_RESERVED_IDS.All,
    userName: 'Show All',
    numSharedActs: 0,
    numCompletedActs: 0,
    isCurrentUsersSummary: false,
};

export interface ISharedFearlessActsSelectProps {
    groupId: string;
    value: string;
    onChange: (updatedValue: string) => void;
}

export const SharedFearlessActsSelect: FC<ISharedFearlessActsSelectProps> = ({ groupId, value, onChange }) => {
    const { data: sharedActs, isLoading: isLoadingSharedActs, isError: isErrorSharedActs } = useGetUserGroupsSharedActSummariesQuery({ groupId });
    useFailedActionSnackbar('loading', 'Shared Fearless Acts of Humility', isErrorSharedActs);

    const sharedActOptions: UserGroupUserSharedActsSummaryListItemDto[] = useMemo(() => {
        var options: UserGroupUserSharedActsSummaryListItemDto[] = [];
        if (sharedActs) {
            options = [...sharedActs];
        }
        options.push(RECENT_FEARLESS_ACT_OPTION);
        options.push(ALL_FEARLESS_ACTS_OPTION);
        return options;
    }, [sharedActs]);

    const selectedOption = useMemo(() => {
        let selectedOption = sharedActOptions.find((opt) => opt.userId === value);
        if (selectedOption?.userId === SHARED_FEARLESS_ACTS_RESERVED_IDS.Recent) {
            return RECENT_FEARLESS_ACT_OPTION;
        }
        if (selectedOption?.userId === SHARED_FEARLESS_ACTS_RESERVED_IDS.All) {
            return ALL_FEARLESS_ACTS_OPTION;
        }
        return selectedOption;
    }, [sharedActOptions, value]);

    const handleSelectionChange = useCallback(
        (_: React.SyntheticEvent<Element, Event>, value: UserGroupUserSharedActsSummaryListItemDto | null) => {
            if (value === null) {
                onChange(SHARED_FEARLESS_ACTS_RESERVED_IDS.All);
            } else {
                onChange(value.userId);
            }
        },
        [onChange]
    );

    const renderFearlessActOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: UserGroupUserSharedActsSummaryListItemDto, state: AutocompleteRenderOptionState) => {
            return (
                <ListItem {...props} sx={{ borderTop: option.isCurrentUsersSummary ? '1px solid #ebebeb' : undefined }}>
                    <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                        <Typography>{option.isCurrentUsersSummary ? 'My Shared Acts' : option.userName}</Typography>
                        {option.userId !== SHARED_FEARLESS_ACTS_RESERVED_IDS.Recent && option.userId !== SHARED_FEARLESS_ACTS_RESERVED_IDS.All && (
                            <Box display='flex' flexWrap='nowrap' gap={1}>
                                <Chip icon={<DirectionsRun />} label={option.numSharedActs.toString()} />
                                <Chip icon={<DoneAll />} label={option.numCompletedActs.toString()} />
                            </Box>
                        )}
                    </Box>
                </ListItem>
            );
        },
        []
    );

    return (
        <Autocomplete
            value={selectedOption}
            onChange={handleSelectionChange}
            options={sharedActOptions}
            getOptionLabel={(option) => (option.isCurrentUsersSummary ? 'My Shared Acts' : option.userName)}
            renderOption={renderFearlessActOption}
            loading={isLoadingSharedActs}
            renderInput={(params) => <TextField {...params} label='View Shared Fearless Acts' />}
            disableClearable
        />
    );
};
