import { Button } from '@mui/material';
import { FC, useCallback, useState } from 'react';

export interface IConfirmActionButtonProps {
    actionName: string;
    onConfirm: () => void;
    disabled?: boolean;
    startIcon?: JSX.Element;
    color?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning";
};

export const ConfirmActionButton: FC<IConfirmActionButtonProps> = ({ actionName, onConfirm, disabled, startIcon, color}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const handleClick = useCallback(() => {
        if (!isConfirming) {
            setIsConfirming(true)
            setTimeout(() => {
                setIsConfirming(false);
            }, 3000);
        } else {
            onConfirm();
            setIsConfirming(false);
        }
    }, [isConfirming, onConfirm]);
    
    return (
        <Button fullWidth startIcon={startIcon} color={color} variant='outlined' onClick={handleClick} disabled={disabled}>
            {isConfirming ? `Confirm ${actionName}` : actionName}
        </Button>
    );
};