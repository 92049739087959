import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ButtonGroup, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import useActManager from './useActManager';
import { JournalQuestionDto } from '../../dtos/generated/JournalQuestionDto';
import { FC } from 'react';
import { ActDto, ActListViewDto } from '../../dtos';

export interface IQuestion {
    currentQuestion: JournalQuestionDto;
    onChange: (updatedActs: ActListViewDto[]) => void;
    disableAutoFocus?: boolean
}

const ActManagementPanel: FC<IQuestion> = ({ currentQuestion, onChange, disableAutoFocus }) => {
    const { handleAddActButton, isGeneratingActs, handleRemoveCustomAct, handleOnChangeAct, handleIdentifyingActsButton } = useActManager(
        currentQuestion,
        onChange
    );

    return (
        <Box display='flex' flexDirection='column' px={1} gap={1}>
            <Typography variant='h5'> Fearless Acts of Humility</Typography>
            <Box display='flex' flexDirection='column' gap={1} flexWrap='wrap'>
                {currentQuestion.acts?.length > 0 &&
                    currentQuestion.acts.map((act: ActDto, idx) => (
                        <Box key={`${act.id} - ${idx}`} display='flex' alignItems='center'>
                            <TextField
                                name='text'
                                InputProps={{
                                    endAdornment: act.isAiGenerated && (
                                        <InputAdornment position='end'>
                                            <IconButton>
                                                <AutoAwesomeIcon sx={{ color: 'primary' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    maxLength: 250,
                                }}
                                variant='outlined'
                                helperText={act.text.length === 250 ? 'Text cannot be longer than 250 characters.' : ''}
                                value={act.text}
                                onChange={(e) => handleOnChangeAct(idx, e.target.value)}
                                multiline
                                fullWidth
                                autoFocus={!disableAutoFocus}
                            />
                            <IconButton onClick={() => handleRemoveCustomAct(idx)}>
                                <DoDisturbOnIcon color='primary' />
                            </IconButton>
                        </Box>
                    ))}
                <Box display='flex'>
                    <ButtonGroup>
                        <Button variant='contained' onClick={handleAddActButton} disabled={isGeneratingActs} startIcon={<AddCircleIcon />}>
                            Add Act
                        </Button>
                        {isGeneratingActs ? (
                            <Button variant='contained' color='primary' disabled>
                                <CircularProgress color='inherit' size={16} sx={{ mr: 1 }} /> Identifying Acts
                            </Button>
                        ) : (
                            <Button variant='contained' onClick={handleIdentifyingActsButton} startIcon={<AutoAwesomeIcon />}>
                                Identify Acts
                            </Button>
                        )}
                    </ButtonGroup>

                </Box>
            </Box>
        </Box>
    );
};

export default ActManagementPanel;
