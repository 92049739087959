import { useCallback, useMemo } from 'react';
import { IFormFieldValidationConfig, createValidatorConfig, isNotBlank, useStandardValidatedFormManager } from '../../Components/CoreLib/library';
import { JournalDto, JournalQuestionDto, PersonListViewDto } from '../../dtos';
import { useCreatePersonMutation, useGetSpaceQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';
import _ from 'lodash';

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

const JOURNAL_VALIDATION_CONFIG: FormValidationConfig<JournalDto> = new Map([['spaceId', createValidatorConfig([isNotBlank], 'Space')]]);

export function useJournalFormManager(initialJournal: JournalDto) {
    const { formRecord: formJournal, setFormRecord: setFormJournal, validateForm, isFormDirty } = useStandardValidatedFormManager(initialJournal, JOURNAL_VALIDATION_CONFIG);
    const [createPerson] = useCreatePersonMutation();

    const queryParams = {
        searchText: '',
        sortKey: '',
        sortAsc: true,
        page: 0,
        pageSize: 100,
        includeInactive: false,
    };
    let { currentData: { pageResults: currentSpaces = [] } = {}, isLoading: isLoadingSpaces } = useGetSpaceQuery(queryParams);

    const handlePersonAdded = useCallback(
        (personToAdd: PersonListViewDto) => {
            if (personToAdd.id === emptyGuid) {
                createPerson(personToAdd)
                    .unwrap()
                    .then((person) => handlePersonAdded(person));
            } else {
                setFormJournal((currentJournal) => ({ ...currentJournal, people: [...currentJournal.people, personToAdd] }));
            }
        },
        [setFormJournal, createPerson]
    );

    const isNew = useMemo(() => formJournal.id === emptyGuid, [formJournal.id]);

    const handleQuestionChange = useCallback(
        (updatedQuestion: JournalQuestionDto) => {
            setFormJournal((currentJournal) => {
                var updatedJournal = _.cloneDeep(currentJournal);
                updatedJournal.questions = updatedJournal.questions.map((question) =>
                    question.questionNumber === updatedQuestion.questionNumber ? updatedQuestion : question
                );
                return updatedJournal;
            });
        },
        [setFormJournal]
    );

    return {
        formJournal,
        validateForm,
        currentSpaces,
        isFormDirty,
        isNew,
        handleQuestionChange,
        isLoadingSpaces
    };
}
