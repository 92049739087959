import { Box, Button, Chip, Dialog, Paper } from '@mui/material';
import { FC, useCallback } from 'react';
import { PageHeader } from '../../Components/Global';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/Check';
import { PersonSelect, SpaceSelect, StandardDatePicker } from '../../Components/CommonInputs';
import { PersonListViewDto } from '../../dtos/generated/PersonListViewDto';
import { useJournalFilterManager } from './useJournalFilterManager';
import { SpaceListViewDto } from '../../dtos';
import ClearIcon from '@mui/icons-material/Clear';

const DEFAULT_FILTERS: IJournalFilterProps = {
    date: null,
    people: [],
    space: null,
};
export interface IJournalFilterProps {
    date: Date | null;
    people?: PersonListViewDto[];
    space: SpaceListViewDto | null;
}
export interface IFilterProps {
    open: boolean;
    onClose: () => void;
    initialValues?: IJournalFilterProps;
    onApply: (data: IJournalFilterProps) => void;
}

export const FilterJournal: FC<IFilterProps> = ({ open, onClose, initialValues = DEFAULT_FILTERS, onApply }) => {
    const { handleRemovePerson, handlePersonAdded, date, setDate, people, space, handleClearFilterButton, handleSpaceChange } =
        useJournalFilterManager(initialValues);
    const personIsNotAlreadySelectedFilter = useCallback((person: PersonListViewDto) => !people.map((p) => p.id).includes(person.id), [people]);

    const handlePersonSelected = useCallback(
        (selectedPerson?: PersonListViewDto) => {
            if (selectedPerson) {
                handlePersonAdded(selectedPerson);
            }
        },
        [handlePersonAdded]
    );

    const handleApplyButton = () => {
        onApply({
            date: date,
            people: people,
            space: space ?? null,
        });
        onClose();
    };

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <Box display='flex' flexDirection='column'>
                <PageHeader icon={<FilterListIcon />} title='Filter Journal History' onActionButtonClicked={onClose} />
                <Paper sx={{ display: 'flex', flexDirection: 'column', py: 1, m: 1 }}>
                    <Box display='flex' flexDirection='column' gap={1} px={1}>
                        <StandardDatePicker onChange={(e) => setDate(e)} value={date} label='Date' />
                        <SpaceSelect value={space ? space.id : ''} onChange={(value) => handleSpaceChange(value ?? null)} />
                        <Box display='flex' flexDirection='column' gap={1}>
                            <Box display='flex' flexDirection='row' flexWrap='wrap' gap={1}>
                                {people.map((p) => (
                                    <Chip label={p.name} onDelete={() => handleRemovePerson(p.id)} variant='outlined' key={p.id} />
                                ))}
                            </Box>
                            <PersonSelect
                                value={''}
                                spaceId={space?.id}
                                onChange={(value) => handlePersonSelected(value)}
                                localPersonFilter={personIsNotAlreadySelectedFilter}
                            />
                        </Box>
                    </Box>
                </Paper>
                <Box display='flex' flexDirection='row' gap={1} px={1}>
                    <Button variant='outlined' onClick={() => handleClearFilterButton()} startIcon={<ClearIcon />} size='large' fullWidth>
                        Clear All
                    </Button>
                    <Button variant='contained' onClick={() => handleApplyButton()} startIcon={<CheckIcon />} size='large' fullWidth>
                        Apply
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
