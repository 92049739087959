import WavingHandIcon from '@mui/icons-material/WavingHand';
import { FC, useState } from 'react';
import { PageHeader } from '../../Components/Global';
import { SpaceDto } from '../../dtos';
import { emptyGuid } from '../../util';
import { useSpaceFormManager } from '../Spaces/useSpaceFormManager';
import { CongratulationsPage } from './CongratulationsPage';
import { OnboardingPeopleForm } from './OnboardingPeopleForm';
import { OnboardingSpaceForm } from './OnboardingSpaceForm';
import { OnboardingVideo } from './OnboardingVideo';

const DEFAULT_SPACE: SpaceDto = {
    name: '',
    icon: '',
    people: [],
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
};

interface IOnboardingStepperProps {
    initValues?: SpaceDto;
}

export const OnboardingStepper: FC<IOnboardingStepperProps> = ({ initValues = DEFAULT_SPACE }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const { formSpace, fieldErrors, handleTextFieldChange, handleIconChange, validateField, handlePersonAdded, handleRemovePerson, validateForm } =
        useSpaceFormManager(initValues);

    const handleNextStep = () => {
        setActiveStep((prev) => prev + 1);
    };
    const handlePreviousStep = () => {
        setActiveStep((prev) => prev - 1);
    };

    switch (activeStep) {
        case 0:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingVideo handleOnClickNext={handleNextStep} videoId={'DE5E8F2F-3A71-4692-ADB4-37CBDE9C1936'} />
                </>
            );

        case 1:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingVideo
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        videoId={'187F4F71-E86F-45B3-8B24-B9E7414E1874'}
                    />
                </>
            );
        case 2:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingSpaceForm
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        formSpace={formSpace}
                        handleTextFieldChange={handleTextFieldChange}
                        handleIconChange={handleIconChange}
                        fieldErrors={fieldErrors}
                        validateField={validateField}
                    />
                </>
            );

        case 3:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingVideo
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        videoId={'F621DF68-C89B-46F8-8E18-02B76D673CF9'}
                    />
                </>
            );

        case 4:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingPeopleForm
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        formSpace={formSpace}
                        handlePersonAdded={handlePersonAdded}
                        handleRemovePerson={handleRemovePerson}
                        fieldErrors={fieldErrors}
                        validateForm={validateForm}
                    />
                </>
            );

        case 5:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingVideo
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        videoId={'207D21DE-667D-4494-8DE9-BFCCB7B23D03'}
                    />
                </>
            );

        case 6:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <OnboardingVideo
                        handleOnClickBack={handlePreviousStep}
                        handleOnClickNext={handleNextStep}
                        videoId={'191244C3-9D0E-448E-BEC0-B352F58DB126'}
                    />
                </>
            );
        case 7:
            return (
                <>
                    <PageHeader icon={<WavingHandIcon />} title='The Praying on Offense Pathway' />
                    <CongratulationsPage handleOnClickBack={handlePreviousStep} formSpace={formSpace} />
                </>
            );

        default:
            return null;
    }
};
