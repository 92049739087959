import { useCallback, useMemo, useState } from 'react';
import {
    useRecoverUserGroupsLeaveMutation,
    useRecoverUserGroupsMembersAcceptMutation,
    useRecoverUserGroupsMembersDenyMutation,
    useRecoverUserGroupsMembersRemoveMutation,
} from '../../store/generated/generatedApi';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';

export function useGroupMemberManager(groupId: string) {
    const goTo = useGoTo();
    const [currentTab, setCurrentTab] = useState(0);
    const [acceptPendingMember, { isLoading: isLoadingAcceptMember, isError: isErrorAcceptMember, error: errorAcceptMember, reset: resetAcceptMember }] =
        useRecoverUserGroupsMembersAcceptMutation();
    useFailedActionSnackbar('accept', 'member', isErrorAcceptMember, resetAcceptMember, errorAcceptMember);
    const [denyPendingMember, { isLoading: isLoadingDenyMember, isError: isErrorDenyMember, error: errorDelayMember, reset: resetDenyMember }] =
        useRecoverUserGroupsMembersDenyMutation();
    useFailedActionSnackbar('deny', 'member', isErrorDenyMember, resetDenyMember, errorDelayMember);
    const [removeAcceptedMember, { isLoading: isLoadingRemoveMember, isError: isErrorRemoveMember, error: errorRemoveMember, reset: resetRemoveMember }] =
        useRecoverUserGroupsMembersRemoveMutation();
    useFailedActionSnackbar('remove', 'member', isErrorRemoveMember, resetRemoveMember, errorRemoveMember);
    const [leaveGroup, { isLoading: isLoadingLeaveGroup, isError: isErrorLeaveGroup, error: errorLeaveGroup, isSuccess: isSuccessLeaveGroup, reset: resetLeaveGroup }] =
        useRecoverUserGroupsLeaveMutation();
    useFailedActionSnackbar('leave', 'group', isErrorLeaveGroup, resetLeaveGroup, errorLeaveGroup);
    useSuccessfulActionSnackbar('left', 'group', isSuccessLeaveGroup, goTo.GroupHome);

    const isLoading = useMemo(
        () => isLoadingAcceptMember || isLoadingDenyMember || isLoadingRemoveMember || isLoadingLeaveGroup,
        [isLoadingAcceptMember, isLoadingDenyMember, isLoadingRemoveMember, isLoadingLeaveGroup]
    );

    const handleAcceptMemberClicked = useCallback(
        (userId: string) => {
            if (isLoadingAcceptMember) {
                return;
            }
            acceptPendingMember({ groupId, userId });
        },
        [isLoadingAcceptMember, acceptPendingMember, groupId]
    );

    const handleDenyMemberClicked = useCallback(
        (userId: string) => {
            if (isLoadingDenyMember) {
                return;
            }
            denyPendingMember({ groupId, userId });
        },
        [isLoadingDenyMember, denyPendingMember, groupId]
    );

    const handleRemoveMemberClicked = useCallback(
        (userId: string) => {
            if (isLoadingRemoveMember) {
                return;
            }
            removeAcceptedMember({ groupId, userId });
        },
        [isLoadingRemoveMember, removeAcceptedMember, groupId]
    );

    const handleLeaveGroupClicked = useCallback(() => {
        if (isLoadingLeaveGroup) {
            return;
        }
        leaveGroup({ groupId });
    }, [isLoadingLeaveGroup, leaveGroup, groupId]);

    return {
        currentTab,
        setCurrentTab,
        handleAcceptMemberClicked,
        handleDenyMemberClicked,
        handleRemoveMemberClicked,
        handleLeaveGroupClicked,
        isLoading,
    };
}
