import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useMemo, useState } from 'react';
import { SHARED_FEARLESS_ACTS_RESERVED_IDS, SharedFearlessActsSelect } from './SharedFearlessActsSelect';
import { InfiniteLoadingListView } from '../../Components/Global';
import { ActListViewDto } from '../../dtos';
import { useLazyGetUserGroupsSharedActsQuery } from '../../store/generated/generatedApi';
import { ActListViewItem } from '../Acts/ActListViewItem';
import { useGoTo } from '../../routing/useGoTo';

export interface IGroupSharedActsSectionProps {
    groupId: string;
}

export const GroupSharedActsSection: FC<IGroupSharedActsSectionProps> = ({ groupId }) => {
    const goTo = useGoTo();
    // TODO: We would like ot make this keep the value the user last had selected if they left the page and came back using the share button and only the share button
    const [fearlessActsValue, setFearlessActsValue] = useState(SHARED_FEARLESS_ACTS_RESERVED_IDS.All);

    const renderRowItem = useCallback(
        (item: ActListViewDto, updateItem: (updatedItem: ActListViewDto) => void) => (
            <ActListViewItem
                act={item}
                onChange={updateItem}
                onShareClicked={() => goTo.GroupDetailsShareAct(groupId, item.id)}
                showActUser={fearlessActsValue === SHARED_FEARLESS_ACTS_RESERVED_IDS.Recent}
            />
        ),
        [fearlessActsValue, goTo, groupId]
    );

    const queryParameters = useMemo(() => {
        if (fearlessActsValue === SHARED_FEARLESS_ACTS_RESERVED_IDS.Recent) {
            return { groupId, onlyRecentlyCompleted: true };
        } else if (fearlessActsValue === SHARED_FEARLESS_ACTS_RESERVED_IDS.All) {
            return { groupId };
        } else {
            return { groupId, userId: fearlessActsValue };
        }
    }, [fearlessActsValue, groupId]);

    return (
        <Paper sx={{ p: 1, height: '100%' }}>
            <Box display='flex' flexDirection='column' gap={1} height='100%'>
                <Typography variant='h3'>Fearless Acts of Humility</Typography>
                <SharedFearlessActsSelect groupId={groupId} value={fearlessActsValue} onChange={setFearlessActsValue} />
                <InfiniteLoadingListView
                    dataLoadingQuery={useLazyGetUserGroupsSharedActsQuery}
                    renderRowDetails={renderRowItem}
                    additionalQueryParameters={queryParameters}
                    disableGutters
                />
            </Box>
        </Paper>
    );
};
