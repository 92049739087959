import { Paper } from '@mui/material';
import { FC } from 'react';

export interface IPageSubHeaderComponentVariantProps {
    child: any;
}

export const PageSubHeaderComponentVariant: FC<IPageSubHeaderComponentVariantProps> = ({ child }) => {
    return (
        <Paper
            square
            sx={{
                p: 1,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                bgcolor: 'white',
                justifyContent: 'center',
                zIndex: 4,
                borderBottom: 2,
                borderColor: 'primary.main',
            }}>
            <>{child}</>
        </Paper>
    );
};
