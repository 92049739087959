import { Check, Delete, Save } from '@mui/icons-material';
import { Box, Button, Icon, Paper, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { IFormProps, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { ConfirmActionButton, PageHeader } from '../../Components/Global';
import { JournalDto, JournalQuestionDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useArchiveJournalByIdMutation } from '../../store/generated/generatedApi';
import { standardDateFormat } from '../../util';
import { QuestionInput } from '../Journals/QuestionInput';
import { DEFAULT_JOURNAL } from '../Journals/StartJournalForm';
import { useJournalFormManager } from '../Journals/useJournalFormManager';
import { InfoVideoIcon } from '../../Components/CustomIcons/InfoVideoIcon';
import { VideoDialog } from '../Journals/VideoDialog';
import { QuestionSetDto } from '../../dtos/generated/QuestionSetDto';
import { IconButtonRound } from '../../Components/Global/IconButtonRound';

export const ReviewJournalForm: FC<IFormProps<JournalDto>> = ({ save, initValues = DEFAULT_JOURNAL, isLoading, cancel }) => {
    const goTo = useGoTo();
    const { formJournal, isFormDirty, validateForm, isNew, handleQuestionChange } = useJournalFormManager(initValues);
    const [archiveJournal, { isLoading: isDeleting, isSuccess: isSuccessfullyDeleted, isError: isErrorDeleting, reset: resetDelete }] =
        useArchiveJournalByIdMutation();
    useSuccessfulActionSnackbar('deleted', 'journal', isSuccessfullyDeleted, goTo.JournalHome);
    useFailedActionSnackbar('delete', 'journal', isErrorDeleting, resetDelete);

    const [open, setOpen] = useState<number>();

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formJournal);
        }
    }, [validateForm, formJournal, save]);

    const handleDeleteClicked = useCallback(() => {
        archiveJournal({ id: formJournal.id });
    }, [archiveJournal, formJournal.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const handleVideoOpenDialog = (questionIdx: number) => {
        setOpen(questionIdx);
    };

    const handleVideoCloseDialog = () => {
        setOpen(undefined);
    };

    const getVideoForQuestion = (question: JournalQuestionDto, questionSet: QuestionSetDto) => {
        switch (question.questionTypeName) {
            case 'Personal':
                return question.typeName === 'Do' ? questionSet.personalVideoDo : questionSet.personalVideoKnow;
            case 'Space':
                return question.typeName === 'Do' ? questionSet.spaceVideoDo : questionSet.spaceVideoKnow;
            case 'People':
                return question.typeName === 'Do' ? questionSet.peopleVideoDo : questionSet.peopleVideoKnow;
            default:
                return null;
        }
    };

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader
                onActionButtonClicked={cancel}
                icon={<Icon fontSize='large'>{formJournal.space?.icon}</Icon>}
                title={formJournal.space?.name ?? ''}
                endText={standardDateFormat(formJournal.date)}
            />
            <Paper sx={{ pb: 1, mx: 1 }}>
                <Box display='flex' flexDirection='column' gap={1}>
                    {formJournal.questions.map((question, idx) => {
                        const shouldShowQuestionTypeTitle = idx === 0 || question.questionTypeName !== formJournal.questions[idx - 1].questionTypeName;
                        const shouldShowDoKnowTitle = idx === 0 || question.typeName !== formJournal.questions[idx - 1].typeName;
                        let videoData = getVideoForQuestion(question, formJournal.questionSet);
                        return (
                            <Box key={`question-${question.questionNumber}`} display='flex' flexDirection='column'>
                                {shouldShowQuestionTypeTitle && (
                                    <Typography key={`question-${question.questionNumber}-question-type-title`} variant='h3' mt={1} mx={1}>
                                        {question.questionTypeName}
                                    </Typography>
                                )}
                                {shouldShowDoKnowTitle && (
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' marginBottom={1}>
                                        <Typography key={`question-${question.questionNumber}-type-title`} variant='h4' mx={1}>
                                            {question.typeName}
                                        </Typography>

                                        <VideoDialog open={open === idx} onClose={handleVideoCloseDialog} video={videoData || undefined} />
                                    </Box>
                                )}
                                <QuestionInput
                                    key={`question-${question.questionNumber}`}
                                    questionNumber={question.questionNumber + 1}
                                    question={question}
                                    onChange={handleQuestionChange}
                                    icon={<IconButtonRound onClick={() => handleVideoOpenDialog(idx)} icon={<InfoVideoIcon height={20} />} size={30} />}
                                    disableAutoFocus
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Paper>
            <Box display='flex' gap={1} px={1} pb={1}>
                {!isNew && (
                    <ConfirmActionButton startIcon={<Delete />} actionName='Delete' color='error' onConfirm={handleDeleteClicked} disabled={isDeleting} />
                )}
                <Button
                    fullWidth
                    startIcon={isFormDirty ? <Save /> : <Check />}
                    variant='contained'
                    size='large'
                    onClick={handleSave}
                    disabled={isDeleting || !isFormDirty}>
                    {isFormDirty ? 'Save' : 'Saved'}
                </Button>
            </Box>
        </Box>
    );
};
