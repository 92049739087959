import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Paper } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback } from 'react';
import { IconSelect } from '../../Components/CommonInputs';
import { FormInput } from '../../Components/CoreLib/library';
import { PageSubHeader } from '../../Components/Global';
import { SpaceDto } from '../../dtos';

interface IOnboardingSpaceFormProps {
    handleOnClickNext?: () => void;
    handleOnClickBack?: () => void;
    isFormValid?: boolean;
    formSpace: SpaceDto;
    handleTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleIconChange: (icon: string) => void;
    fieldErrors: Map<string, string>;
    validateField: (fieldName: keyof SpaceDto, updatedValue: any) => boolean;
}

export const OnboardingSpaceForm: FC<IOnboardingSpaceFormProps> = ({
    handleOnClickBack,
    handleOnClickNext,
    formSpace,
    handleTextFieldChange,
    handleIconChange,
    fieldErrors,
    validateField,
}) => {
    const handleSaveAndNext = useCallback(() => {
        const isNameValid = validateField('name', formSpace.name);
        const isIconValid = validateField('icon', formSpace.icon);
        const isFormValid = isNameValid && isIconValid;

        if (isFormValid) {
            handleOnClickNext?.();
        }
    }, [formSpace, handleOnClickNext, validateField]);

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageSubHeader text='Choose a Space' />
            <Paper sx={{ mx: 1 }}>
                <Box display='flex' flexDirection='column' p={1}>
                    <Box display='flex' flexDirection='row' flexWrap='nowrap' gap={1}>
                        <Box flexGrow={1}>
                            <FormInput
                                fullWidth
                                label='Name'
                                name='name'
                                value={formSpace.name}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('name')}
                                required
                            />
                        </Box>
                        <Box width='96px'>
                            <IconSelect
                                fullWidth
                                label='Icon'
                                value={formSpace.icon}
                                onChange={handleIconChange}
                                errorMessage={fieldErrors.get('icon')}
                                required
                            />
                        </Box>
                    </Box>
                    <Box></Box>
                </Box>
            </Paper>
            <Box display='flex' gap={1} px={1}>
                <Button fullWidth startIcon={<ChevronLeftIcon />} variant='outlined' size='large' onClick={handleOnClickBack}>
                    Back
                </Button>
                <Button fullWidth startIcon={<NavigateNextIcon />} variant='contained' size='large' onClick={handleSaveAndNext}>
                    Next
                </Button>
            </Box>
        </Box>
    );
};
