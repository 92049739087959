import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';
import { FC, useMemo } from 'react';
import { TagDto } from '../../dtos';
import { useGetVideoTagsQuery } from '../../store/generated/generatedApi';

interface CustomSelectProps {
    selectedValue: TagDto | null;
    handleChange: (event: TagDto | null) => void;
    label?: string;
}
const ALL_TAG = { id: '', name: 'Show All', isActive: false, createdOn: new Date() };

export const StandardSingleSelectTag: FC<CustomSelectProps> = ({ selectedValue, handleChange, label = 'Select Tag' }) => {
    const { data: tags, isLoading } = useGetVideoTagsQuery();
    const updatedTags = useMemo(() => {
        return tags ? [ALL_TAG, ...tags] : [ALL_TAG];
    }, [tags]);

    return (
        <FormControl variant='filled' fullWidth>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={selectedValue}
                onChange={(_, value) => handleChange(value)}
                loading={isLoading}
                options={updatedTags ?? []}
                getOptionLabel={(x) => x.name}
                renderInput={(params) => <TextField {...params} />}
                disabled={isLoading}
            />
        </FormControl>
    );
};
