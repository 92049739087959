import { FormControl, FormLabel, Autocomplete, TextField, FormHelperText, Divider, Box, AutocompleteRenderOptionState } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { PersonListViewDto, SpaceListViewDto } from '../../dtos';
import { emptyGuid } from '../../util';
import { useGetPersonQuery } from '../../store/generated/generatedApi';
import { AddCircle } from '@mui/icons-material';

export interface IPersonSelectProps {
    label?: string;
    value: string;
    onChange: (value?: PersonListViewDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: () => void;
    localPersonFilter?: (person: PersonListViewDto) => boolean;
    disableClearable?: boolean;
    customSortMethod?: (options: PersonListViewDto[]) => PersonListViewDto[];
    autoSelectFirstValue?: boolean;
    spaceId?: string;
    helperText?: string;
}

export const PersonSelect: FC<IPersonSelectProps> = ({
    label = 'Person',
    value,
    onChange,
    required,
    errorMessage,
    disabled,
    onBlur,
    localPersonFilter,
    disableClearable,
    customSortMethod,
    autoSelectFirstValue,
    spaceId,
    helperText,
}) => {
    const { data: people, isLoading: isLoadingPersons } = useGetPersonQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
        spaceId,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: PersonListViewDto | null) => {
        onChange(value ?? undefined);
    };

    const getSelectedValue = () => {
        if (value === '' || value === emptyGuid) {
            return null;
        }
        return people?.pageResults.find((x) => x.id === value) ?? null;
    };

    const personOptions = useMemo(() => {
        let options = people?.pageResults ?? [];
        if (localPersonFilter) {
            options = options.filter(localPersonFilter);
        }
        if (customSortMethod) {
            options = customSortMethod(options);
        }
        return options;
    }, [people?.pageResults, localPersonFilter, customSortMethod]);

    useEffect(() => {
        if (autoSelectFirstValue && (!value || value === emptyGuid) && personOptions.length > 0) {
            onChange(personOptions[0]);
        }
    }, [autoSelectFirstValue, value, personOptions, onChange]);

    const getSpacesListString = useCallback(
        (spaces: SpaceListViewDto[]) => {
            if (spaceId || spaces.length === 0) {
                return '';
            }
            return `(${spaces.map((s) => s.name).join(', ')})`;
        },
        [spaceId]
    );

    const renderListItemsWithOptionalAdd = useCallback(
        (props: React.HTMLAttributes<unknown>, option: PersonListViewDto, params: AutocompleteRenderOptionState) => {
            const isAddableOption = option.id === emptyGuid;
            const isInputValueEmpty = params.inputValue === '';

            if (isAddableOption) {
                if (!isInputValueEmpty) {
                    return (
                        <>
                            <Divider key={`${option.id}-add-divider`} />
                            <li {...props} key={option.id}>
                                <AddCircle color='primary' sx={{ mr: 1 }} /> Add "{option.name}"
                            </li>
                        </>
                    );
                }
                else {
                    return <></>
                }

            }
            var spacesList = getSpacesListString(option.spaces);
            return (
                <li {...props} key={option.id}>
                    <Box>
                        <span style={{ fontWeight: spaceId ? 'normal' : 'bold' }}>{option.name}</span> {spacesList}
                    </Box>
                </li>
            );
        },
        [getSpacesListString, spaceId]
    );

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={getSelectedValue()}
                onChange={handleSelectChange}
                loading={isLoadingPersons}
                options={personOptions}
                getOptionLabel={(x) => ''}
                renderOption={(props, option, params) => renderListItemsWithOptionalAdd(props, option, params)}
                filterOptions={(options, params) => {
                    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(params.inputValue.toLowerCase()));

                    filteredOptions.push({
                        name: params.inputValue,
                        spaces: [],
                        id: emptyGuid,
                        isActive: true,
                        createdOn: new Date(),
                    });

                    return filteredOptions;
                }}
                renderInput={(params) => <TextField error={!!errorMessage} {...params} />}
                disabled={disabled}
                onBlur={onBlur}
                disableClearable={disableClearable}
            />
            <FormHelperText>{errorMessage || helperText}</FormHelperText>
        </FormControl>
    );
};
