import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { PersonDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useCreatePersonMutation } from '../../store/generated/generatedApi';
import { PeopleForm } from './PeopleForm';

export const AddPeopleView = () => {
    const goTo = useGoTo();
    const [createPerson, { isSuccess, isError, reset }] = useCreatePersonMutation();

    useSuccessfulCreateSnackbar('Person', isSuccess, goTo.PeopleHome);
    useFailedCreateSnackbar('person', isError, reset);

    const handlePersonAdded = (newPerson: PersonDto) => {
        createPerson(newPerson);
    };
    return <PeopleForm save={(newPerson) => handlePersonAdded(newPerson)} cancel={goTo.PeopleHome} />;
};
