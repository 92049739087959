import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { JournalDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetJournalByIdQuery, useUpdateJournalMutation } from '../../store/generated/generatedApi';
import { ReviewJournalForm } from '../Reflection/ReviewJournalForm';

export const ReviewJournalView: FC = () => {
    const { id } = useParams();
    const goTo = useGoTo();
    const { data, isLoading, error, refetch } = useGetJournalByIdQuery({ id: id! });
    const [updateJournal, { isSuccess, isError, reset }] = useUpdateJournalMutation();

    useSuccessfulUpdateSnackbar('Journal', isSuccess, () => {
        reset();
        refetch();
    });
    useFailedUpdateSnackbar('journal', isError, reset);

    const handleSave = (values: JournalDto) => {
        updateJournal(values);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <ReviewJournalForm save={handleSave} cancel={goTo.JournalHome} initValues={data} />;
};
