import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { UserGroupDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetUserGroupsByIdQuery, useUpdateUserGroupsMutation } from '../../store/generated/generatedApi';
import { GroupForm } from './GroupForm';
import { ManageGroupForm } from './ManageGroupForm';

export const EditGroupView: FC = () => {
    const { id } = useParams();
    const goTo = useGoTo();
    const { data, isLoading, error, refetch } = useGetUserGroupsByIdQuery({ id: id! });
    const [updateUserGroup, { isSuccess, isError, reset }] = useUpdateUserGroupsMutation();
    const [isEditOverrideMode, setIsEditOverrideMode] = useState(false);

    const handleCancelOrLeave = useCallback(() => {
        if (isEditOverrideMode) {
            setIsEditOverrideMode(false);
        } else {
            goTo.GroupHome();
        }
    }, [isEditOverrideMode, goTo]);

    useSuccessfulUpdateSnackbar('Group', isSuccess, () => {
        reset();
        refetch();
        handleCancelOrLeave();
    });
    useFailedUpdateSnackbar('group', isError, reset);

    const handleSave = (values: UserGroupDto) => {
        updateUserGroup(values);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error || !data) {
        return <ApiError onReloadClick={refetch} />;
    }

    if (data.isAdmin && !isEditOverrideMode) {
        return <ManageGroupForm group={data} handleEditClicked={() => setIsEditOverrideMode(true)} />;
    }

    return <GroupForm save={handleSave} cancel={handleCancelOrLeave} initValues={data} isAdmin={data.isAdmin} />;
};
