import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import LoginButton from './LoginButton';
import logo from '../../../src/logoHorizontal.png';

export const Login: FC = () => {
    return (
        <Box display='flex' flexDirection='column' p={1} justifyContent={'center'} height="100%">
            <Paper
                sx={{
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 1,
                }}
            >

                <Box
                    component="img"
                    sx={{
                        height: '100%',
                        width: '100%',
                        maxHeight: 'min(30vh, 200px)',
                        maxWidth: '75%',
                        objectFit: 'contain',
                        p: 2
                    }}
                    alt="Praying on Offense Logo."
                    src={logo}
                />
                <Typography variant="h6">Welcome to Praying on Offense</Typography>
                <Typography>
                    Get started by clicking the button below to log in or sign up.
                </Typography>
            </Paper>
            <LoginButton />
        </Box>
    );
};
