import { SettingsBackupRestore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { LoadingIndicator } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { ReflectionStepper } from './ReflectionStepper';
import { useGetGuidedReflectionCurrentReflectionQuery, useGuidedReflectionArchiveCompletedActsMutation } from '../../store/generated/generatedApi';

export interface ICompleteReflectionFormProps {
    onCompletion: () => void;
}

export const CompleteReflectionForm: FC<ICompleteReflectionFormProps> = ({ onCompletion }) => {
    const { data: currentReflection, isLoading: isLoadingCurrentReflection } = useGetGuidedReflectionCurrentReflectionQuery();
    const [archiveCompletedActs] = useGuidedReflectionArchiveCompletedActsMutation();

    useEffect(() => {
        archiveCompletedActs();
        // eslint-disable-next-line
    }, []);

    if (isLoadingCurrentReflection) {
        return <LoadingIndicator />;
    }

    return (
        <Box height='100%' display='flex' flexDirection='column'>
            <PageHeader icon={<SettingsBackupRestore />} title='Reflection' />
            <Box flexGrow={1} overflow='hidden'>
                <ReflectionStepper reflection={currentReflection} onCompletion={onCompletion} />
            </Box>
        </Box>
    );
};
