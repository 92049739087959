import { Box } from '@mui/system';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ShareToGroupIcon } from '../../Components/CustomIcons';
import { InfiniteLoadingListView, PageHeader } from '../../Components/Global';
import { GroupSharingDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useLazyGetUserGroupsSharedWithByIdQuery } from '../../store/generated/generatedApi';
import { GroupListViewItem } from './GroupListViewItem';

export interface IShareActViewProps {
    returnTo: string;
};

export const ShareActView: FC<IShareActViewProps> = ({ returnTo }) => {
    const goTo = useGoTo();
    const { id, actId = '' } = useParams();

    const isValidReturnTo = useCallback((returnTo: string): returnTo is keyof typeof goTo => {
        return Object.keys(goTo).includes(returnTo);
    }, [goTo])

    const handleCloseClicked = useCallback(() => {
        if (isValidReturnTo(returnTo)) {
            const returnMethod = goTo[returnTo];
            if (returnMethod.length === 0) {
                (returnMethod as () => void)();
            } else if (id) {
                (returnMethod as (id: string) => void)(id);
            } else {
                console.error('Invalid return to value. Taking user to act home.');
                goTo.ActsHome();
            }
        } else {
            console.error('Invalid return to value. Taking user to act home.');
            goTo.ActsHome();
        }
    }, [isValidReturnTo, returnTo, goTo, id]);

    const renderRowItem = useCallback(
        (item: GroupSharingDto, updateItem: (updatedItem: GroupSharingDto) => void) => <GroupListViewItem group={item} actId={actId} onChange={updateItem} />,
        [actId]
    );

    return (
        <Box display='flex' flexDirection='column' height='100%'>
            <PageHeader
                icon={<ShareToGroupIcon />}
                title='Share to My Groups'
                onActionButtonClicked={handleCloseClicked}
            />
            <InfiniteLoadingListView
                dataLoadingQuery={useLazyGetUserGroupsSharedWithByIdQuery}
                renderRowDetails={renderRowItem}
                additionalQueryParameters={{
                    actId: actId
                }}
            />
        </Box>
    );
};