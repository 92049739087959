import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FC, useEffect } from 'react';
import { PageSubHeader } from '../../Components/Global';
import { useActBulkArchiveMutation, useLazyGetActQuery, useRecoverActBulkCompletedMutation } from '../../store/generated/generatedApi';
import { ManageReflectionActListItem } from './ManageReflectionActListItem';
import { useReflectionStepper } from './useReflectionStepper';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';

export interface IReflectionStepReCommitProps {
    onBackClicked?: () => void;
    onNextClicked: () => void;
}

export const ReflectionStepReCommit: FC<IReflectionStepReCommitProps> = ({ onBackClicked, onNextClicked }) => {
    const { handleArchiveActId, handleCompleteActId, handleRecommitActId, setCurrentActIds, archivedActIds, currentActIds, completedActIds } =
        useReflectionStepper(null, () => {});
    const [getRecords] = useLazyGetActQuery();

    const [actBulkCompleted, { isLoading: isActBulkCompletedLoading, isError: isActBulkCompletedError }] = useRecoverActBulkCompletedMutation();
    const [actBulkArchive, { isLoading: isActBulkArchiveLoading, isError: isActBulkArchiveError }] = useActBulkArchiveMutation();

    useFailedActionSnackbar('archive an', 'act', isActBulkArchiveError);
    useFailedActionSnackbar('complete an', 'act', isActBulkCompletedError);

    const handleNextClick = async () => {
        await actBulkCompleted(completedActIds);
        await actBulkArchive(archivedActIds);
        onNextClicked();
    };

    useEffect(() => {
        getRecords({
            sortAsc: false,
            page: 0,
            pageSize: 5000,
            includeInactive: false,
            showOnlyInactive: false,
        })
            .unwrap()
            .then((response) => {
                setCurrentActIds(response.pageResults);
            });
        // eslint-disable-next-line
    }, []);
    return (
        <Box height='100%' display='flex' flexDirection='column'>
            <PageSubHeader text='My Fearless Acts' />
            <Box display='flex' flexDirection='column' gap={1} flexGrow={1}>
                <ManageReflectionActListItem
                    acts={currentActIds}
                    onCompletedActsChange={handleCompleteActId}
                    onArchivedActsChange={handleArchiveActId}
                    onRecommittedActsChange={handleRecommitActId}
                />
                <Box display='flex' gap={1} p={1} flexWrap='nowrap'>
                    {onBackClicked && (
                        <Button color='inherit' fullWidth variant='outlined' startIcon={<ChevronLeft />} onClick={onBackClicked}>
                            Back
                        </Button>
                    )}
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        endIcon={<ChevronRight />}
                        disabled={isActBulkArchiveLoading || isActBulkCompletedLoading}
                        onClick={handleNextClick}>
                        Next
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
