export const generatedApiTags = [
	'Act',
	'GuidedReflection',
	'Video',
	'Space',
	'Person',
	'Journal',
	'Metrics',
	'User',
	'UserGroups',
	'UserStory',
	'Verses'
]