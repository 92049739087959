import { FC } from 'react';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useCreateJournalMutation } from '../../store/generated/generatedApi';
import { DEFAULT_JOURNAL, StartJournalForm } from './StartJournalForm';

export const StartJournalView: FC = () => {
    const goTo = useGoTo();
    const [createJournal, { data, isError, error, isSuccess, reset }] = useCreateJournalMutation();
    useSuccessfulCreateSnackbar('Journal', isSuccess, () => goTo.JournalComplete(data?.id ?? ''));
    useFailedCreateSnackbar('journal', isError, reset, error);

    return <StartJournalForm save={createJournal} cancel={goTo.JournalHome} initValues={DEFAULT_JOURNAL} />;
};
