import { Box, ListItem, Typography } from '@mui/material';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { QueryResultsDto } from '../../dtos';
import { LoadingIndicator } from '../CoreLib/library';

// Other Constant Values
const NUMBER_ITEMS_FROM_BOTTOM_BEFORE_LOADING_MORE = 5;
const PAGE_SIZE = 25;

interface PaginationQueryParameters {
    searchText?: string;
    sortKey?: string;
    sortAsc: boolean;
    page: number;
    pageSize: number;
    includeInactive: boolean;
}

export interface IInfiniteLoadingListViewProps<P extends PaginationQueryParameters = any, T = any> {
    dataLoadingQuery: UseLazyQuery<QueryDefinition<P, any, any, QueryResultsDto<T>>>;
    renderRowDetails: (record: T, updateRecord: (updatedRecord: T) => void) => JSX.Element;
    sortKey?: string;
    sortAsc?: boolean;
    includeInactive?: boolean;
    additionalQueryParameters?: any;
    disableGutters?: boolean;
}

export const InfiniteLoadingListView: FC<IInfiniteLoadingListViewProps> = ({
    dataLoadingQuery,
    renderRowDetails,
    sortKey,
    sortAsc = true,
    includeInactive = false,
    additionalQueryParameters = {},
    disableGutters
}) => {
    const [getRecords, { isLoading, isFetching }] = dataLoadingQuery();
    const [loadedRecords, setLoadedRecords] = useState<any[]>([]);
    const previousQueryParameters = useRef(additionalQueryParameters);
    const hasRequestedInitialLoadRef = useRef(false);
    const [isMoreRecordsToLoad, setIsMoreRecordsToLoad] = useState(false);
    const itemCount = useMemo(() => (isLoading || isFetching ? loadedRecords.length + 1 : loadedRecords.length), [isLoading, isFetching, loadedRecords.length]);

    const replaceRecordInState = useCallback((record: any) => {
        // Right now we are just assuming that the type provided will have an id field but we may want to create an over-writable method for more flexibility later.
        setLoadedRecords((currentRecords) => currentRecords.map((cr) => (cr.id === record.id ? record : cr)));
    }, []);

    const renderRow = useCallback(
        (index: number, data: any, __: any) => {

            const rowComponent = (
                <ListItem component='div' disablePadding sx={{ px: disableGutters ? 0 : 1, py: .5 }}>
                    {renderRowDetails(data, replaceRecordInState)}
                </ListItem>
            )

            const loadingComponent = (
                <ListItem
                    key={index}
                    component='div'
                    disablePadding>
                    <LoadingIndicator />
                </ListItem>
            )
            if ((isLoading || isFetching) && index === (loadedRecords.length - 1)) {
                return (
                    <>
                        {rowComponent}
                        {loadingComponent}
                    </>
                )
            } else {
                return rowComponent;
            }
        },
        [renderRowDetails, replaceRecordInState, isLoading, isFetching, loadedRecords.length, disableGutters]
    );

    const buildItemKey = useCallback((index: number, data: any, _: any) => `${index}-${data.id}`, []);

    const loadRecords = useCallback(
        async (page: number) => {
            if (!isLoading && !isFetching) {
                var response = await getRecords({
                    sortKey,
                    sortAsc,
                    includeInactive,
                    page,
                    pageSize: PAGE_SIZE,
                    ...(additionalQueryParameters ?? {}),
                }).unwrap();
                setLoadedRecords((currentRecords) => [...currentRecords, ...response.pageResults]);
                setIsMoreRecordsToLoad(response.page * PAGE_SIZE < response.totalQueryResults);
            }
        },
        [getRecords, sortKey, sortAsc, includeInactive, isLoading, isFetching, additionalQueryParameters]
    );

    const loadMoreRecords = useCallback(() => {
        if (isLoading || !isMoreRecordsToLoad || !hasRequestedInitialLoadRef.current) {
            return;
        }
        loadRecords(Math.ceil(loadedRecords.length / PAGE_SIZE));
    }, [loadRecords, isMoreRecordsToLoad, isLoading, loadedRecords]);

    // Reset the list view if the additional query parameters change
    useEffect(() => {
        if (JSON.stringify(additionalQueryParameters) !== JSON.stringify(previousQueryParameters.current)) {
            setLoadedRecords([]);
            loadRecords(0);
            previousQueryParameters.current = additionalQueryParameters;
        }
    }, [additionalQueryParameters, loadRecords]);

    // Load Initial Records
    useEffect(() => {
        if (!hasRequestedInitialLoadRef.current) {
            loadRecords(0);
            hasRequestedInitialLoadRef.current = true;
        }
    }, [loadRecords]);

    if (itemCount === 0) {
        return (
            <Box width='100%' textAlign='center' p={1}>
                <Typography>No Results</Typography>
            </Box>
        );
    }

    return (
        <Box display='flex' flexDirection='column' height='100%' py={.5}>
            <Virtuoso
                data={loadedRecords}
                itemContent={renderRow}
                computeItemKey={buildItemKey}
                endReached={loadMoreRecords}
                atBottomThreshold={NUMBER_ITEMS_FROM_BOTTOM_BEFORE_LOADING_MORE}
                overscan={300}
                increaseViewportBy={200}
            />
        </Box>
    );
};
