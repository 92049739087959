import { AutoAwesome } from '@mui/icons-material';
import { Box, Button, Chip, Icon } from '@mui/material';
import { FC } from 'react';
import { useGoTo } from '../../routing/useGoTo';
import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import { UserStoryListViewDto } from '../../dtos';
import { useGetUserStoryQuery } from '../../store/generated/generatedApi';
import { standardDateFormat } from '../../util';
import { PageHeader } from '../../Components/Global';

const COLUMNS: DataTableColumn<UserStoryListViewDto>[] = [
    { key: 'date', label: 'Date', sortKey: 'DATE', fieldMapper: (row) => standardDateFormat(row.date) },
    {
        key: 'focusName',
        label: 'Focus',
        sortKey: 'FOCUS',
        unsorted: true,
        fieldMapper: (row) => (
            <Chip
                icon={
                    row.focusIcon ? (
                        <Icon
                            sx={{
                                color: 'white !important',
                                bgcolor: '#bdbdbd',
                                borderRadius: '50%',
                                p: '3px',
                                height: '24px',
                                width: '24px',
                                fontSize: '18px',
                            }}>
                            {row.focusIcon}
                        </Icon>
                    ) : undefined
                }
                label={row.focusName}
            />
        ),
        align: 'right',
    },
];

export const StoryHomeView: FC = () => {
    const goTo = useGoTo();

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <PageHeader icon={<AutoAwesome />} title='Stories' />
            <Box px={1} display='flex' flexDirection='column' rowGap={1}>
                <MobileListView
                    permissionName='stories'
                    getDataQuery={useGetUserStoryQuery}
                    tableColumns={COLUMNS}
                    handleRowClick={goTo.StoryDetails}
                    showInactiveStyling={false}
                />
                <Button color='primary' variant='contained' startIcon={<AutoAwesome />} size='large' onClick={goTo.StoryCreate}>
                    Discover New Story
                </Button>
            </Box>
        </Box>
    );
};
