import { BottomNavigationWrapper } from '../Components/ApplicationShell';
import {
    AddSpaceView,
    EditSpaceView,
    MyAccountView,
    SpacesListView,
    StartJournalView,
    ReviewJournalView,
    JournalsListView,
    CompleteJournalView,
    ActsHome,
    AddGroupView,
    EditGroupView,
    GroupListView,
    InvitationView,
    ManageActsView,
    GroupDetailsView,
    ShareActView,
    AddPeopleView,
    PeopleEditView,
    PeopleHomeView,
    OnboardingView,
    CreateStoryView,
    StoryDetailView,
    StoryHomeView,
    ReflectionView,
} from '../Views';

import LearnHome from '../Views/Learn/LearnHome';

export interface IRoute {
    component: any;
    path: string;
    routes?: IRoute[];
    requiredPermissions?: string[];
    permissionLogic?: 'and' | 'or';
}

export const routes: IRoute[] = [
    {
        component: (
            <BottomNavigationWrapper>
                <StartJournalView />
            </BottomNavigationWrapper>
        ),
        path: '/',
        requiredPermissions: ['read:journals'],
    },
    {
        component: <MyAccountView />,
        path: '/myaccount',
    },
    {
        component: (
            <BottomNavigationWrapper>
                <SpacesListView />
            </BottomNavigationWrapper>
        ),
        path: '/spaces',
        requiredPermissions: ['read:spaces'],
    },
    {
        component: <AddSpaceView />,
        path: '/space/create',
        requiredPermissions: ['create:spaces'],
    },
    {
        component: <EditSpaceView />,
        path: '/space/:id',
        requiredPermissions: ['edit:spaces'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <JournalsListView />
            </BottomNavigationWrapper>
        ),
        path: '/journals',
        requiredPermissions: ['read:journals'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <StartJournalView />
            </BottomNavigationWrapper>
        ),
        path: '/journal/start',
        requiredPermissions: ['create:journals'],
    },
    {
        component: <CompleteJournalView />,
        path: '/journal/:id/complete',
        requiredPermissions: ['edit:journals'],
    },
    {
        component: <ReviewJournalView />,
        path: '/journal/:id/review',
        requiredPermissions: ['edit:journals'],
    },

    {
        component: (
            <BottomNavigationWrapper>
                <ActsHome />
            </BottomNavigationWrapper>
        ),
        path: '/acts',
        requiredPermissions: ['read:journals'],
    },
    {
        component: <ShareActView returnTo='ActsHome' />,
        path: '/acts/shareAct/:actId',
        requiredPermissions: ['edit:journals'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <LearnHome />
            </BottomNavigationWrapper>
        ),
        path: '/learn',
        requiredPermissions: ['read:video'],
    },
    {
        component: <ManageActsView />,
        path: '/acts/manage',
        requiredPermissions: ['edit:journals'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <SpacesListView />
            </BottomNavigationWrapper>
        ),
        path: '/spaces',
        requiredPermissions: ['read:spaces'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <GroupListView />
            </BottomNavigationWrapper>
        ),
        path: '/groups',
        requiredPermissions: ['read:userGroups'],
    },
    {
        component: <AddGroupView />,
        path: '/group/create',
        requiredPermissions: ['create:userGroups'],
    },
    {
        component: <EditGroupView />,
        path: '/group/:id',
        requiredPermissions: ['edit:userGroups'],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <GroupDetailsView />
            </BottomNavigationWrapper>
        ),
        path: '/group/:id/details',
        requiredPermissions: ['read:userGroups'],
    },
    {
        component: <ShareActView returnTo='GroupDetails' />,
        path: '/group/:id/details/shareAct/:actId',
        requiredPermissions: ['edit:journals'],
    },
    {
        component: <InvitationView />,
        path: '/invite/:code',
        requiredPermissions: [],
    },
    {
        component: (
            <BottomNavigationWrapper>
                <PeopleHomeView />
            </BottomNavigationWrapper>
        ),
        path: '/people',
        requiredPermissions: ['read:people'],
    },
    {
        component: <PeopleEditView />,
        path: '/people/:id',
        requiredPermissions: ['edit:people'],
    },
    {
        component: <AddPeopleView />,
        path: '/people/create',
        requiredPermissions: ['create:people'],
    },
    {
        component: <OnboardingView />,
        path: '/onboarding',
    },
    {
        component: (
            <BottomNavigationWrapper>
                <StoryHomeView />
            </BottomNavigationWrapper>
        ),
        path: '/stories',
        requiredPermissions: ['read:stories'],
    },
    {
        component: <StoryDetailView />,
        path: '/stories/:id',
        requiredPermissions: ['read:stories'],
    },
    {
        component: <CreateStoryView />,
        path: '/stories/create',
        requiredPermissions: ['create:stories'],
    },
    {
        component: <ReflectionView />,
        path: '/reflect',
        requiredPermissions: ['create:journals'],
    },
];
