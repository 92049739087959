import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useCreateUserGroupsMutation } from '../../store/generated/generatedApi';
import { GroupForm } from './GroupForm';

export const AddGroupView = () => {
    const goTo = useGoTo();
    const [createUserGroups, { isError, isSuccess, reset }] = useCreateUserGroupsMutation();

    useSuccessfulCreateSnackbar('Group', isSuccess, goTo.GroupHome);
    useFailedCreateSnackbar('Group', isError, reset);

    return <GroupForm save={createUserGroups} cancel={goTo.GroupHome} isAdmin />;
};
