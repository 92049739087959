import { useParams } from 'react-router-dom';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { PersonDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetPersonByIdQuery, useUpdatePersonMutation } from '../../store/generated/generatedApi';
import { PeopleForm } from './PeopleForm';

export const PeopleEditView = () => {
    const goTo = useGoTo();
    const { id } = useParams();

    const { data } = useGetPersonByIdQuery({ id: id! });

    const [updatePerson, { isSuccess, isError, reset }] = useUpdatePersonMutation();

    useSuccessfulUpdateSnackbar('Person', isSuccess, goTo.PeopleHome);
    useFailedUpdateSnackbar('person', isError, reset);

    const handleUpdatePerson = (person: PersonDto) => {
        updatePerson(person);
    };

    return <PeopleForm initValues={data} save={(person) => handleUpdatePerson(person)} cancel={goTo.PeopleHome}></PeopleForm>;
};
