import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { SpaceDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetSpaceByIdQuery, useUpdateSpaceMutation } from '../../store/generated/generatedApi';
import { SpaceForm } from './SpaceForm';

export const EditSpaceView: FC = () => {
    const { id } = useParams();
    const goTo = useGoTo();
    const { data, isLoading, error, refetch } = useGetSpaceByIdQuery({ id: id! });
    const [updateSpace, { isSuccess, isError, reset }] = useUpdateSpaceMutation();

    useSuccessfulUpdateSnackbar('Space', isSuccess, () => {
        reset();
        refetch();
    });
    useFailedUpdateSnackbar('space', isError, reset);

    const handleSave = (values: SpaceDto) => {
        updateSpace(values);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <SpaceForm save={handleSave} cancel={goTo.SpaceHome} initValues={data} />;
};
