import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function useGoTo() {
    const navigate = useNavigate();

    // this is really just here because I like saying 'goTo' a page rather than 'navigate' a page
    const goTo = useCallback(
        (route: string) => {
            navigate(route);
        },
        [navigate]
    );

    const go = useMemo(
        () => ({
            Home: () => goTo('/'),
            MyAccount: () => goTo(`/myaccount`),
            SpaceHome: () => goTo('/spaces'),
            SpaceCreate: () => goTo('/space/create'),
            SpaceEdit: (spaceId: string) => goTo(`/space/${spaceId}`),
            JournalHome: () => goTo('/journals'),
            JournalStart: () => goTo('/journal/start'),
            JournalComplete: (journalId: string) => goTo(`/journal/${journalId}/complete`),
            JournalReview: (journalId: string) => goTo(`/journal/${journalId}/review`),
            GroupsHome: () => goTo('/groups'),
            ActsHome: () => goTo('/acts'),
            ActsHomeShareAct: (actId: string) => goTo(`/acts/shareAct/${actId}`),
            LearnHome: () => goTo('/learn'),
            ActsManager: () => goTo(`/acts/manage`),
            GroupHome: () => goTo('/groups'),
            GroupCreate: () => goTo('/group/create'),
            GroupEdit: (groupId: string) => goTo(`/group/${groupId}`),
            GroupDetails: (groupId: string) => goTo(`/group/${groupId}/details`),
            GroupDetailsShareAct: (groupId: string, actId: string) => goTo(`/group/${groupId}/details/shareAct/${actId}`),
            PeopleHome: () => goTo('/people'),
            PeopleEdit: (personId: string) => goTo(`/people/${personId}`),
            PeopleCreate: () => goTo('/people/create'),
            Onboarding: () => goTo('/onboarding'),
            StoryHome: () => goTo('/stories'),
            StoryDetails: (storyId: string) => goTo(`/stories/${storyId}`),
            StoryCreate: () => goTo('/stories/create'),
            PostJournalReflection: () => goTo('/reflect')
        }),
        [goTo]
    );

    return go;
}
