import { Place, AddCircle } from '@mui/icons-material';
import { Box, Button, Icon } from '@mui/material';
import { FC } from 'react';
import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import { SpaceListViewDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetSpaceQuery } from '../../store/generated/generatedApi';
import { useSnackbar } from 'notistack';
import { PageHeader } from '../../Components/Global';

const COLUMNS: DataTableColumn<SpaceListViewDto>[] = [
    { key: 'icon', label: 'Icon', sortKey: '', unsorted: true, fieldMapper: (row) => <Icon>{row.icon}</Icon>, width: '64px' },
    { key: 'name', label: 'Name', sortKey: 'NAME' },
];

export const SpacesListView: FC = () => {
    const goTo = useGoTo();
    const { enqueueSnackbar } = useSnackbar();

    const { currentData, isLoading } = useGetSpaceQuery({
        searchText: '',
        sortKey: '',
        sortAsc: true,
        page: 0,
        pageSize: 100,
        includeInactive: false,
    });

    const handleOnClick = () => {
        if (currentData && !isLoading) {
            const numberOfCurrentSpaces = currentData?.pageResults?.length || 0;
            if (numberOfCurrentSpaces >= 10) {
                enqueueSnackbar("You can't have more than 10 spaces. Please delete an existing space.", { variant: 'warning' });
            } else {
                goTo.SpaceCreate();
            }
        }
    };

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader icon={<Place />} title='Manage Spaces' />
            <Box px={1} display='flex' flexDirection='column' rowGap={1}>
                <MobileListView
                    permissionName='spaces'
                    getDataQuery={useGetSpaceQuery}
                    tableColumns={COLUMNS}
                    handleRowClick={goTo.SpaceEdit}
                    hideRowsPerPageSelect={true}
                    fixedPageSize={10}
                />
                <Button color='primary' variant='contained' startIcon={<AddCircle />} size='large' onClick={handleOnClick}>
                    Add New Space
                </Button>
            </Box>
        </Box>
    );
};
