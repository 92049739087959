import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { ActListViewDto, JournalQuestionDto } from '../../dtos';
import { useCreateVersesRecommendMutation } from '../../store/generated/generatedApi';

export function useQuestionInputFormManager(question: JournalQuestionDto, onChange: (value: JournalQuestionDto) => void) {
    const { enqueueSnackbar } = useSnackbar();
    const [createVerseRecommendations, { isLoading: isGeneratingVerseRecommendations, isError: isErrorGeneratingVerseRecommendations }] =
        useCreateVersesRecommendMutation();
    useFailedActionSnackbar('find', 'relevant verses', isErrorGeneratingVerseRecommendations);

    const handleAnswerChange = useCallback(
        (value: string) => {
            var updatedQuestion = _.cloneDeep(question);
            if (value.length > 1000) {
                updatedQuestion.answer = value.substring(0, 1000);
            } else {
                updatedQuestion.answer = value;
            }
            onChange(updatedQuestion);
        },
        [question, onChange]
    );

    const handleVerseGenerationButtonClicked = useCallback(() => {
        if (!question.answer) {
            enqueueSnackbar('You must provide a response before verses can be recommended. Please enter a prayer response and then try again.', {
                variant: 'warning',
            });
            return;
        }
        createVerseRecommendations({
            journalId: question.journalId,
            questionNumber: question.questionNumber,
            answer: question.answer,
        })
            .unwrap()
            .then((verseRecommendations) => onChange({ ...question, verseRecommendations }));
    }, [createVerseRecommendations, question, onChange, enqueueSnackbar]);

    const handleActChange = (updatedActs: ActListViewDto[]) => {
        var updatedQuestion = _.cloneDeep(question);
        updatedQuestion.acts = updatedActs;
        onChange(updatedQuestion);
    };
    return {
        handleAnswerChange,
        isGeneratingVerseRecommendations,
        handleVerseGenerationButtonClicked,
        handleActChange,
    };
}
