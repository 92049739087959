import { Check, ChevronLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FC, useState } from 'react';
import { PageSubHeader } from '../../Components/Global';
import { UserStoryDto } from '../../dtos';
import { CreateStoryForm } from '../Stories/CreateStoryForm';
import { StoryDetails } from '../Stories/StoryDetails';

export interface IReflectionStepStoriesProps {
    onBackClicked: () => void;
    onStartPrayingClicked: () => void;
}

export const ReflectionStepStories: FC<IReflectionStepStoriesProps> = ({ onBackClicked, onStartPrayingClicked }) => {
    const [selectedStory, setSelectedStory] = useState<UserStoryDto | null>(null);

    if (selectedStory) {
        return (
            <Box height='100%' display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='column' >
                    <StoryDetails story={selectedStory} />
                </Box>
                <Box mx={1} mb={1}>
                    <Button color='inherit' fullWidth variant='outlined' startIcon={<ChevronLeft />} onClick={() => setSelectedStory(null)}>
                        Back
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <PageSubHeader text='Which Story would you like to reflect on?' />
            <Box display='flex' flexDirection='column' gap={1} py={1}>
                <CreateStoryForm onStoryCreated={setSelectedStory} onlyRecentlyMentioned />
                <Box display='flex' gap={1} mx={1} mb={1}>
                    <Button color='inherit' variant='outlined' fullWidth startIcon={<ChevronLeft />} onClick={onBackClicked}>
                        Back
                    </Button>
                    <Button variant='contained' fullWidth startIcon={<Check />} onClick={onStartPrayingClicked}>
                        Done
                    </Button>
                </Box>
            </Box>
        </>
    );
};
