import { AddCircle, Close, DirectionsRun, Groups2, Menu as MenuIcon } from '@mui/icons-material';
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Drawer,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import logoWhite from '../../../src/logoHorizontal-alt.png';
import { useGoTo } from '../../routing/useGoTo';
import { AuthenticatedComponent } from '../CoreLib/library';
import { InfoVideoIcon } from '../CustomIcons/InfoVideoIcon';
import { NavigationDrawerRouteDTO } from './ApplicationBottomBarContainer';
import { useHandleLogout } from '../../util/useHandleLogout';

const NAVIGATION_KEYS = {
    Social: 'social',
    FearlessActs: 'acts',
    StartPraying: 'praying',
    Learn: 'learn',
};

export interface INavigationDrawerProps {
    routes: NavigationDrawerRouteDTO[];
    adminRoutes?: NavigationDrawerRouteDTO[];
}

export const ApplicationBottomBar: FC<INavigationDrawerProps> = ({ routes, adminRoutes }) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const goTo = useGoTo();
    const { pathname } = useLocation();
    const handleLogout = useHandleLogout();

    const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[]) => {
        const visibleRoutes = routesToRender.filter((r) => !r.isHidden);
        return visibleRoutes.map((routeToRender) => {
            return createNavButton(routeToRender);
        });
    };

    const createNavButton = (route: NavigationDrawerRouteDTO) => {
        return (
            <AuthenticatedComponent key={route.route} requiredPermissions={route.requiredPermissions ?? []}>
                <Button
                    key={route.route}
                    className='nav-button'
                    fullWidth
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(route.route!);
                        setIsOpen(false);
                    }}
                    href={route.route!}
                    startIcon={route.icon}
                    sx={pathname === route.route ? { backgroundColor: 'rgba(255,255,255,.2)', fontWeight: 'bold' } : { fontWeight: 'normal' }}>
                    {<div>{route.name}</div>}
                </Button>
            </AuthenticatedComponent>
        );
    };


    const bottomNavigationItem = useMemo(() => {
        const currentRoute = location.pathname;
        if (currentRoute === '/' || currentRoute.startsWith('/journal')) {
            return NAVIGATION_KEYS.StartPraying;
        } else if (currentRoute === '/groups') {
            return NAVIGATION_KEYS.Social;
        } else if (currentRoute.startsWith('/act')) {
            return NAVIGATION_KEYS.FearlessActs;
        } else if (currentRoute.startsWith('/learn')) {
            return NAVIGATION_KEYS.Learn;
        }
        return null;
    }, [location]);


    return (
        <>
            <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)} PaperProps={{ sx: { width: '560px', maxWidth: '100vw', height: '100%' } }}>
                <Box py={1} display='flex' justifyContent='center' pt='max(env(safe-area-inset-top), 16px)'>
                    <img src={logoWhite} alt='PROOF Logo' height={'64px'} />
                </Box>
                {renderNavigationButtons(routes)}
                <Box display='flex' justifyContent='space-between' mt='auto' pb='max(env(safe-area-inset-bottom), 16px)'>
                    <Button sx={{ textDecoration: 'underline' }} onClick={handleLogout}>Log Out</Button>
                    <IconButton onClick={() => setIsOpen(false)}><Close /></IconButton>
                </Box>
            </Drawer>
            <BottomNavigation value={bottomNavigationItem} sx={{ position: 'fixed', bottom: 'calc(env(safe-area-inset-bottom))', left: 0, right: 0, zIndex: 100 }}>
                <BottomNavigationAction label='Social' value={NAVIGATION_KEYS.Social} icon={<Groups2 />} onClick={goTo.GroupsHome} />
                <BottomNavigationAction label='Fearless Acts' value={NAVIGATION_KEYS.FearlessActs} icon={<DirectionsRun />} onClick={goTo.ActsHome} />
                <BottomNavigationAction label='Start Praying' value={NAVIGATION_KEYS.StartPraying} icon={<AddCircle />} onClick={goTo.JournalStart} />
                <BottomNavigationAction label='Learn' value={NAVIGATION_KEYS.Learn} icon={<InfoVideoIcon />} onClick={goTo.LearnHome} />
                <BottomNavigationAction label='More' value='more' icon={<MenuIcon />} onClick={() => setIsOpen(true)} />
            </BottomNavigation>
        </>
    );
};