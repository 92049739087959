import { useCallback, useMemo } from 'react';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { ActListViewDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import {
    useArchiveActByIdMutation,
    useRecoverActByIdMutation,
    useRecoverActCompleteMutation,
    useRecoverActUncompleteMutation,
} from '../../store/generated/generatedApi';

export function useActItemManager(act: ActListViewDto, onChange?: (act: ActListViewDto) => void, suppressMutations?: boolean) {
    const goTo = useGoTo();
    const [completeAct, { isError: isCompleteActError, isLoading: isCompletingAct }] = useRecoverActCompleteMutation();
    const [uncompleteAct, { isError: isUncompleteActError, isLoading: isUncompletingAct }] = useRecoverActUncompleteMutation();
    const [archiveAct, { isError: isArchiveActError, isLoading: isArchivingAct }] = useArchiveActByIdMutation();
    const [recoverAct, { isError: isRecoveringActError, isLoading: isRecoveringAct }] = useRecoverActByIdMutation();

    useFailedActionSnackbar('complete', 'Fearless Act', isCompleteActError);
    useFailedActionSnackbar('uncomplete', 'Fearless Act', isUncompleteActError);
    useFailedActionSnackbar('archive', 'Fearless Act', isArchiveActError);
    useFailedActionSnackbar('recovering', 'Fearless Act', isRecoveringActError);

    const isLoading = useMemo(() => isCompletingAct || isUncompletingAct, [isCompletingAct, isUncompletingAct]);

    const handleCompleteAct = useCallback(() => {
        if (suppressMutations) {
            onChange && onChange({ ...act, isComplete: true, completedOn: new Date() });
            return;
        }
        if (!isCompletingAct) {
            completeAct({ actId: act.id })
                .unwrap()
                .then(() => onChange && onChange({ ...act, isComplete: true, completedOn: new Date() }));
        }
    }, [suppressMutations, isCompletingAct, completeAct, act, onChange]);

    const handleUncompleteAct = useCallback(() => {
        if (suppressMutations) {
            onChange && onChange({ ...act, isComplete: false, completedOn: undefined });
            return;
        }
        if (!isUncompletingAct) {
            uncompleteAct({ actId: act.id })
                .unwrap()
                .then(() => onChange && onChange({ ...act, isComplete: false, completedOn: undefined }));
        }
    }, [suppressMutations, isUncompletingAct, uncompleteAct, act, onChange]);

    const handleViewJournalClicked = useCallback(() => {
        goTo.JournalReview(act.journalId);
    }, [act, goTo]);

    const handleArchive = useCallback(() => {
        if (suppressMutations) {
            onChange && onChange({ ...act, isActive: false });
            return;
        }
        archiveAct(act)
            .then(() => onChange && onChange({ ...act, isActive: false }))
            .catch((error) => {
                console.error('An error occurred during the retrieving process', error);
            });
    }, [suppressMutations, onChange, archiveAct, act]);

    const handleUnarchive = useCallback(() => {
        if (suppressMutations) {
            onChange && onChange({ ...act, isActive: true });
            return;
        }
        recoverAct(act)
            .then(() => onChange && onChange({ ...act, isActive: true }))
            .catch((error) => {
                console.error('An error occurred during the archiving or retrieving process', error);
            });
    }, [suppressMutations, onChange, recoverAct, act]);

    return {
        handleCompleteAct,
        handleUncompleteAct,
        handleViewJournalClicked,
        isLoading,
        handleArchive,
        handleUnarchive,
        isArchivingAct,
        isRecoveringAct,
    };
}
