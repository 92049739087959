import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGetUserStoryByIdQuery } from '../../store/generated/generatedApi';
import { emptyGuid, standardDateFormat } from '../../util';
import { StoryDetails } from './StoryDetails';
import { Box } from '@mui/system';
import { AutoAwesome } from '@mui/icons-material';
import { PageHeader } from '../../Components/Global';
import { useGoTo } from '../../routing/useGoTo';

export const StoryDetailView: FC = () => {
    const goTo = useGoTo();
    const { id = emptyGuid } = useParams<{ id: string }>();
    const { data: userStory, isLoading: isLoadingUserStory, isError: isErrorLoadingUserStory, refetch: refetchUserStory } = useGetUserStoryByIdQuery({ id });

    if (isLoadingUserStory) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingUserStory || !userStory) {
        return <ApiError onReloadClick={refetchUserStory} />;
    }

    return (
        <Box display='flex' flexDirection='column'>
            <PageHeader icon={<AutoAwesome />} title='Story' onActionButtonClicked={goTo.StoryHome} endText={standardDateFormat(userStory.date)} />
            <StoryDetails story={userStory} />
        </Box>)
};