import { Box, Button, Chip, Icon, Paper, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { ConfirmActionButton, PageHeader } from '../../Components/Global';
import PeopleIcon from '@mui/icons-material/People';
import { useGoTo } from '../../routing/useGoTo';
import { FormInput, IFormProps, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { PersonDto, SpaceListViewDto } from '../../dtos';
import { emptyGuid } from '../../util';
import { usePeopleManager } from './usePeopleManager';
import { useArchivePersonByIdMutation } from '../../store/generated/generatedApi';
import { SpaceSelect } from '../../Components/CommonInputs';

const DEFAULT_PERSON: PersonDto = {
    name: '',
    spaces: [],
    id: emptyGuid,
    isActive: false,
    createdOn: new Date(),
};

export const PeopleForm: FC<IFormProps<PersonDto>> = ({ initValues = DEFAULT_PERSON, save }) => {
    const goTo = useGoTo();

    const { isNotNew, hasSpaces, handleTextFieldChange, validateForm, formPerson, fieldErrors, handleSpaceAdded, handleRemoveSpace } =
        usePeopleManager(initValues);

    const [archivePerson, { isSuccess: isSuccessfullyDeleted, isError: isErrorDeleting, reset: resetDelete }] = useArchivePersonByIdMutation();

    useSuccessfulActionSnackbar('deleted', 'person', isSuccessfullyDeleted, goTo.PeopleHome);
    useFailedActionSnackbar('delete', 'person', isErrorDeleting, resetDelete);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formPerson);
        } else {
        }
    };
    const handleDelete = () => {
        archivePerson({ id: formPerson.id });
    };

    const handleSpaceSelected = useCallback(
        (selectedSpace?: SpaceListViewDto) => {
            if (selectedSpace) {
                handleSpaceAdded(selectedSpace);
            }
        },
        [handleSpaceAdded]
    );
    const spaceIsNotAlreadySelectedFilter = useCallback(
        (space: SpaceListViewDto) => !formPerson.spaces.map((p) => p.id).includes(space.id),
        [formPerson.spaces]
    );
    const chipDeleteHandler = useCallback((spaceId: string) => () => handleRemoveSpace(spaceId), [handleRemoveSpace]);

    return (
        <Box display='flex' flexDirection='column'>
            <PageHeader icon={<PeopleIcon />} title={'Add Person'} onActionButtonClicked={goTo.PeopleHome}></PageHeader>
            <Paper sx={{ p: 1, m: 1 }}>
                <FormInput
                    onChange={handleTextFieldChange}
                    label='Name'
                    name='name'
                    value={formPerson.name}
                    fullWidth
                    errorText={fieldErrors.get('name')}></FormInput>
                <Typography> Active Spaces</Typography>

                {hasSpaces ? (
                    formPerson.spaces.map((space) => (
                        <Chip
                            size='small'
                            id={space.id}
                            key={space.id}
                            onDelete={chipDeleteHandler(space.id)}
                            sx={{ m: 0.5 }}
                            icon={<Icon>{space.icon}</Icon>}
                            label={space.name}></Chip>
                    ))
                ) : (
                    <Typography variant='caption'> Person is not active in any spaces...</Typography>
                )}
                <SpaceSelect value={''} onChange={handleSpaceSelected} localSpaceFilter={spaceIsNotAlreadySelectedFilter} fullWidth></SpaceSelect>
            </Paper>

            <Box display='flex' flexDirection='row' px={1} pb={1} gap={1}>
                {isNotNew && <ConfirmActionButton actionName={'Delete'} onConfirm={handleDelete} color='error' startIcon={<DeleteIcon />} />}
                <Button variant='contained' startIcon={<SaveIcon />} fullWidth onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </Box>
    );
};
