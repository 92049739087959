import { FC } from 'react';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useCreateSpaceMutation } from '../../store/generated/generatedApi';
import { SpaceForm } from './SpaceForm';

export const AddSpaceView: FC = () => {
    const goTo = useGoTo();
    const [createSpace, { isError, isSuccess, reset }] = useCreateSpaceMutation();

    useSuccessfulCreateSnackbar('Space', isSuccess, goTo.SpaceHome);
    useFailedCreateSnackbar('space', isError, reset)

    return <SpaceForm save={createSpace} cancel={goTo.SpaceHome} />
};
