import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { PageHeader } from '../../Components/Global';
import { useGoTo } from '../../routing/useGoTo';
import { Home } from '@mui/icons-material';

export const InvitationNotFound: FC = () => {
    const goTo = useGoTo();

    return (
        <>
            <PageHeader title={`Sorry, we couldn't find that invitation`} />
            <Paper sx={{ m: 1, textAlign: 'center' }}>
                <Box p={1} textAlign='center'>
                    <Typography>
                        The invitation link you have use may be outdated. Please contact the group admin to get a new invitation link and try again.
                    </Typography>
                </Box>
            </Paper>
            <Box px={1}>
                <Button variant='outlined' onClick={goTo.Home} fullWidth startIcon={<Home />}>
                    Go Home
                </Button>
            </Box>
        </>
    );
};
