import { Grid, Typography, Divider, Menu, MenuItem } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { UserAvatar } from '../CoreLib/library';
import { useGetUserMeQuery } from '../../store/generated/generatedApi';
import { useHandleLogout } from '../../util/useHandleLogout';

export const UserMenu: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleLogout = useHandleLogout();
    const { data: currentUserDetails, isLoading: isLoadingCurrentUserDetails } = useGetUserMeQuery();

    function handleLogoutClick() {
        setAnchorEl(null);
        handleLogout();
    }

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const userName = useMemo(() => currentUserDetails ? `${currentUserDetails.firstName} ${currentUserDetails.lastName}` : '', [currentUserDetails])

    if (isLoadingCurrentUserDetails || !currentUserDetails) {
        return null;
    }

    return (
        <Grid container direction='row' justifyContent='end'>
            <div style={{ cursor: 'pointer', paddingRight: '8px' }} onClick={handleProfileMenuOpen}>
                <UserAvatar name={userName} />
            </div>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleProfileMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    sx: {
                        mt: 1.5,
                        border: 'solid 1px #AAAAAA',
                        borderRadius: '10px',
                        padding: '8px',
                    },
                }}>
                <Grid container direction='column' alignItems='center' width='240px' overflow='hidden' textAlign='center'>
                    <UserAvatar name={userName} />
                    <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>{userName}</Typography>
                    <Typography sx={{ fontSize: '14px', marginBottom: '12px', lineBreak: 'anywhere' }}>{currentUserDetails.email}</Typography>
                </Grid>
                <Divider sx={{ marginBottom: '12px' }} />
                <MenuItem onClick={handleLogoutClick} sx={{ fontSize: '14px' }}>
                    Log Out
                </MenuItem>
            </Menu>
        </Grid>
    );
};