import { useCallback, useState } from 'react';
import { PersonListViewDto, SpaceListViewDto } from '../../dtos';
import { IJournalFilterProps } from './FilterJournal';

export function useJournalFilterManager(initalFilters: IJournalFilterProps) {
    const [date, setDate] = useState<Date | null>(initalFilters.date);
    const [space, setSpace] = useState<SpaceListViewDto | null>(initalFilters.space);
    const [people, setPeople] = useState<PersonListViewDto[]>(initalFilters.people ?? []);

    const handlePersonAdded = useCallback((personToAdd: PersonListViewDto) => {
        setPeople((currentPeople) => [...currentPeople, personToAdd]);
    }, []);

    const handleRemovePerson = useCallback((personId: string) => {
        setPeople((currentPeople) => currentPeople.filter((p) => p.id !== personId));
    }, []);

    const handleSpaceChange = useCallback((updatedSpace: SpaceListViewDto | null) => {
        setSpace(updatedSpace);
        setPeople([]);
    }, []);

    const handleClearFilterButton = () => {
        setSpace(null);
        setPeople([]);
        setDate(null);
    };

    return {
        handlePersonAdded,
        handleRemovePerson,
        date,
        setDate,
        people,
        setPeople,
        setSpace,
        space,
        handleClearFilterButton,
        handleSpaceChange,
    };
}
