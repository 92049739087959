import { AccountBox, Check, Delete, Save, Visibility } from '@mui/icons-material';
import { Box, Button, Grid, Paper } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { FormInput, IFormProps, LoadingIndicator } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { UserDto } from '../../dtos';
import { useArchiveUserMeMutation } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util/constants';
import { UserDeleteConfirmationModal } from './UserDeleteConfirmationModal';
import { useUserFormManager } from './useUserFormManager';
import { useHandleLogout } from '../../util/useHandleLogout';

export const DEFAULT_USER: UserDto = {
    firstName: '',
    lastName: '',
    email: '',
    auth0UserId: '',
    displayName: '',
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
    isEmailEditable: true,
};

export const UserForm: FC<IFormProps<UserDto>> = ({ save, cancel, initValues = DEFAULT_USER, isLoading }) => {
    const { formUser, isNew, isFormDirty, fieldErrors, validateForm, handleTextFieldChange } = useUserFormManager(initValues);
    const [isOpen, setIsOpen] = useState(false);
    const [deleteUser, { isLoading: isDeleting }] = useArchiveUserMeMutation();
    const handleLogout = useHandleLogout();

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formUser);
        }
    }, [validateForm, formUser, save]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const handleDeleteClicked = () => {
        deleteUser();
        handleLogout();
    };

    const handleOpenDialog = () => {
        setIsOpen(true);
    };

    const handleCloseDialog = () => {
        setIsOpen(false);
    };

    const handleTOSClicked = () => {
        window.open('https://prayingonoffense.com/terms-and-conditions/', '_blank');
    };

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader onActionButtonClicked={cancel} icon={<AccountBox fontSize='large' />} title={`${isNew ? 'Add Account' : 'My Account'}`} />
            <Paper sx={{ py: 1, mx: 1 }}>
                <Grid container direction='row' spacing={1} px={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormInput
                            fullWidth
                            label='First Name'
                            name='firstName'
                            value={formUser.firstName}
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormInput
                            fullWidth
                            label='Last Name'
                            name='lastName'
                            value={formUser.lastName}
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('lastName')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <FormInput
                            fullWidth
                            label='Email'
                            name='email'
                            value={formUser.email}
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('email')}
                            disabled={!formUser.isEmailEditable}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            fullWidth
                            startIcon={<Visibility />}
                            variant='contained'
                            onClick={handleTOSClicked}>
                            Terms and Conditions
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Box display='flex' gap={1} mx={1}>
                <UserDeleteConfirmationModal isOpen={isOpen} onClose={handleCloseDialog} onConfirm={handleDeleteClicked} />
                <Button onClick={handleOpenDialog} fullWidth variant='outlined' color='error' startIcon={<Delete />} disabled={isDeleting}>
                    Delete
                </Button>
                <Button fullWidth startIcon={isFormDirty ? <Save /> : <Check />} variant='contained' size='large' onClick={handleSave} disabled={!isFormDirty}>
                    {isFormDirty ? 'Save' : 'Saved'}
                </Button>
            </Box>
        </Box>
    );
};
