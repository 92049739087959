import { Box, Dialog, Paper } from '@mui/material';
import { FC, useEffect } from 'react';
import { PageHeader } from '../../Components/Global';
import { VideoCard } from '../Learn/VideoCard';
import { VideoDto } from '../../dtos';
import { InfoVideoIcon } from '../../Components/CustomIcons';

export interface IVideoDialogProps {
    open: boolean;
    onClose: () => void;
    video?: VideoDto;
}

export const VideoDialog: FC<IVideoDialogProps> = ({ open, onClose, video }) => {
    const handleBackButtonEvent = () => {
        onClose();
    };

    useEffect(() => {
        if (open) {
            window.history.pushState({ page: 'videoDialog' }, 'videoDialog');

            window.addEventListener('popstate', handleBackButtonEvent);

            return () => {
                window.removeEventListener('popstate', handleBackButtonEvent);
            };
        }
        // eslint-disable-next-line
    }, [open]);

    const handleOnClose = () => {
        window.history.back();
        window.removeEventListener('popstate', handleBackButtonEvent);
        onClose();
    };

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <Box display='flex' flexDirection='column'>
                <PageHeader icon={<InfoVideoIcon />} title={video?.title || 'Unknown title'} onActionButtonClicked={handleOnClose} />
                <Paper sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>{video && <VideoCard video={video} hideTitle={true} />}</Paper>
            </Box>
        </Dialog>
    );
};
