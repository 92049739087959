import { FC, useState } from 'react';
import { OnboardingDataCollectionScreen } from './OnboardingDataCollectionScreen';

export const CreateAccountStepper: FC = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const handleNextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    switch (activeStep) {
        case 0:
            return <OnboardingDataCollectionScreen onUserAccountCreated={handleNextStep} />;

        default:
            setActiveStep(0);
            return null;
    }
};
