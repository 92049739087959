import { useCallback, useMemo } from 'react';
import { PersonDto, SpaceListViewDto } from '../../dtos';
import { emptyGuid } from '../../util';
import {
    FormValidationConfig,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    useStandardValidatedFormManager,
} from '../../Components/CoreLib/library';

const PERSON_VALIDATION_CONFIG: FormValidationConfig<PersonDto> = new Map([['name', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Name')]]);

export function usePeopleManager(personData: PersonDto) {
    const {
        formRecord: formPerson,
        setFormRecord: setFormPerson,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
    } = useStandardValidatedFormManager(personData, PERSON_VALIDATION_CONFIG);

    const isNotNew = useMemo(() => formPerson.id !== emptyGuid, [formPerson.id]);
    const hasSpaces = useMemo(() => formPerson.spaces.length > 0, [formPerson.spaces]);
    const handleSpaceAdded = (space: SpaceListViewDto) => {
        setFormPerson((currentForm) => ({ ...currentForm, spaces: [...currentForm.spaces, space] }));
    };

    const handleRemoveSpace = useCallback(
        (spaceId: string) => {
            setFormPerson((currentPerson) => ({ ...currentPerson, spaces: currentPerson.spaces.filter((p) => p.id !== spaceId) }));
        },
        [setFormPerson]
    );

    return { isNotNew, hasSpaces, handleTextFieldChange, validateForm, formPerson, fieldErrors, handleSpaceAdded, handleRemoveSpace };
}
