import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#267FEA', contrastText: '#ffffff' },
        secondary: { main: '#151719', contrastText: '#ffffff' },
        error: { main: '#f44336', contrastText: '#ffffff' },
        info: { main: '#cccccc', contrastText: '#ffffff' },
        background: {
            default: '#FFFFFF',
        },
        text: {
            primary: '#58585A',
        },
    },
    components: {
        MuiBottomNavigationAction: {
            styleOverrides: {
                label: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&::before': {
                        content: 'none',
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root .hover-actions': {
                        display: 'none',
                    },
                    '&:hover .MuiTableCell-root .hover-actions': {
                        display: 'block',
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: '#361735',
                    color: '#ffffff',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: '32px',
                    fontWeight: 'bold',
                },
                h2: {
                    fontSize: '28px',
                    fontWeight: 'bold',
                },
                h3: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                },
                h4: {
                    fontSize: '22px',
                    fontWeight: 'bold',
                },
                h5: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                },
                h6: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                },
                subtitle1: {
                    color: '#58585A',
                    fontSize: '20px',
                    fontWeight: 'normal',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper': {
                        background: '#267FEA',
                        color: '#ffffff',
                        padding: '0px 20px',
                        borderRight: 'none',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root': {
                        color: '#ffffff',
                        fontSize: '16px',
                        textTransform: 'none',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button': {
                        padding: '8px',
                        marginLeft: '-8px',
                        justifyContent: 'start',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button.full-width': {
                        padding: '8px 12px',
                        marginLeft: '-12px',
                        width: 'calc(100% + 12px)',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button:hover': {
                        backgroundColor: 'rgba(255,255,255,.2)',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiSvgIcon-root': {
                        fontSize: '32px',
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiButton-iconSizeMedium': {
                        marginRight: '24px',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                    '& .MuiButton-root': {
                        minWidth: '100px',
                    },
                    '& > :not(:first-of-type)': {
                        marginLeft: '24px',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: '0px !important',
                },
            },
        },
    },
});

export default theme;
