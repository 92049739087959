import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useGetVideoByIdQuery } from '../../store/generated/generatedApi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { VideoCard } from '../Learn/VideoCard';

interface IVideoProps {
    videoId?: string;
    handleOnClickNext?: () => void;
    handleOnClickBack?: () => void;
}

export const OnboardingVideo: FC<IVideoProps> = ({ videoId, handleOnClickNext, handleOnClickBack }) => {
    const { data: video } = useGetVideoByIdQuery({ id: videoId ?? '' });

    return (
        <Box display='flex' flexDirection='column' p={1} textAlign='center'>
            {video && <VideoCard video={video} />}

            <Box display='flex' flexDirection='row' py={1} gap={1}>
                {handleOnClickBack && (
                    <Button fullWidth startIcon={<ChevronLeftIcon />} variant='outlined' onClick={handleOnClickBack}>
                        Back
                    </Button>
                )}
                <Button fullWidth startIcon={<NavigateNextIcon />} variant='contained' onClick={handleOnClickNext}>
                    Next
                </Button>
            </Box>
        </Box>
    );
};
