import { Archive, ExpandMore, ThumbUp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Icon, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, MouseEventHandler, useCallback } from 'react';
import { ActListViewDto } from '../../dtos';
import { standardDateFormat } from '../../util';
import { useActItemManager } from './useActItemManager';


interface IManageActListItemProps {
    act: ActListViewDto;
    onChange?: (act: ActListViewDto) => void;
    suppressMutations?: boolean;
}

export const ManageActListItem: FC<IManageActListItemProps> = ({ act, onChange, suppressMutations }) => {
    const { handleArchive, handleUnarchive, isArchivingAct, isRecoveringAct } = useActItemManager(act, onChange, suppressMutations);

    const stopPropagationThen = useCallback((action: () => void): MouseEventHandler<HTMLButtonElement> => (event) => {
        event.stopPropagation();
        action();
    }, []);

    return (
        <Paper
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 1,
                p: 1,
                overflow: 'hidden',
                ...(!act.isActive && {
                    bgcolor: '#dcdcdc',
                    color: 'rgba(0, 0, 0, .38)',
                }),
            }}>
            <Typography sx={{ textDecoration: act.isComplete ? 'line-through' : undefined }}>{act.text}</Typography>
            {act.completedOn && <Box display='flex' flexWrap='wrap' justifyContent='space-between' width='100%'>
                <Chip label={`Completed: ${standardDateFormat(act.completedOn)}`} />
            </Box>}
            <Accordion sx={{
                width: '100%', ...(!act.isActive && {
                    bgcolor: '#dcdcdc',
                    color: 'rgba(0, 0, 0, .38)'
                })
            }} elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 0 }}>
                    <Box display='flex' gap={1} flexWrap='wrap' width='100%'>
                        {act.isActive ? (
                            <Button
                                color='primary'
                                variant='contained'
                                startIcon={<Archive fontSize='large' />}
                                onClick={stopPropagationThen(handleArchive)}
                                disabled={isArchivingAct}>
                                Archive
                            </Button>
                        ) : (
                            <Button
                                color='primary'
                                variant='contained'
                                startIcon={<ThumbUp fontSize='large' />}
                                onClick={stopPropagationThen(handleUnarchive)}
                                disabled={isRecoveringAct}>
                                Commit
                            </Button>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0 }}>
                    <Box display='flex' gap={1} flexWrap='wrap' width='100%' overflow='hidden'>
                        <Chip label={standardDateFormat(act.journalDate)} />
                        <Chip
                            icon={
                                <Icon
                                    sx={{
                                        color: 'white !important',
                                        bgcolor: '#bdbdbd',
                                        borderRadius: '50%',
                                        p: '2px',
                                        height: '24px',
                                        width: '24px',
                                        fontSize: '20px',
                                    }}>
                                    {act.spaceIcon}
                                </Icon>
                            }
                            label={act.spaceName}
                        />
                        {act.involvedPeople.map((person, idx) => (
                            <Chip key={`${idx}-${person}`} label={person} />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};
