import { AppBar, Grid, Toolbar } from '@mui/material';
import { FC, useContext } from 'react';
import logo from '../../../src/logoHorizontal.png';
import { UserPermissionContext } from '../CoreLib/library';
import { UserMenu } from './UserMenu';

export const ApplicationTopBar: FC = () => {
    const { isAuthenticated } = useContext(UserPermissionContext);

    return (
        <Grid display='flex'>
            <AppBar position='sticky' color='transparent' elevation={0} sx={{ height: '48px', background: '#ffffff' }}>
                <Toolbar variant='dense' disableGutters>
                    <Grid item paddingLeft='8px'>
                        <a href='/' style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={logo} alt='PROOF Logo' height={'40px'} />
                        </a>
                    </Grid>
                    {isAuthenticated && <UserMenu />}
                </Toolbar>
            </AppBar>
        </Grid>
    );
};
