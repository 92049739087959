import { DirectionsRun } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, useCallback, useState } from 'react';
import { InfiniteLoadingListView, PageHeader } from '../../Components/Global';
import { ActListViewDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useLazyGetActQuery } from '../../store/generated/generatedApi';
import { ManageActListItem } from './ManageActListItem';

export const ManageActsView: FC = () => {
    const goTo = useGoTo();
    const handleSettingsClicked = useCallback(() => {
        goTo.ActsHome();
    }, [goTo]);

    const renderRowItem = useCallback(
        (item: ActListViewDto, updatedAct: (updatedItem: ActListViewDto) => void) => <ManageActListItem act={item} onChange={updatedAct} />,
        []
    );

    const [value, setValue] = useState('committed');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box display='flex' flexDirection='column' height='100%'>
            <PageHeader
                icon={<DirectionsRun />}
                title='My Fearless Acts'
                actionButtonIcon={<CloseIcon fontSize='large' />}
                onActionButtonClicked={handleSettingsClicked}
            />
            <Paper square>
                <Tabs value={value} variant='fullWidth' onChange={handleChange} centered>
                    <Tab label='Committed' value='committed' />
                    <Tab label='Archived' value='archived' />
                </Tabs>
            </Paper>
            {value === 'committed' && (
                <InfiniteLoadingListView
                    dataLoadingQuery={useLazyGetActQuery}
                    renderRowDetails={renderRowItem}
                    sortKey='COMPLETED_THEN_JOURNAL_DATE'
                    sortAsc={false}
                />
            )}
            {value === 'archived' && (
                <InfiniteLoadingListView
                    dataLoadingQuery={useLazyGetActQuery}
                    renderRowDetails={renderRowItem}
                    sortKey='COMPLETED_THEN_JOURNAL_DATE'
                    sortAsc={false}
                    additionalQueryParameters={{ showOnlyInactive: true }}
                />
            )}
        </Box>
    );
};
