import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingIndicator, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { useCreateUserGroupsInvitationByIdMutation, useGetUserGroupsInvitationByIdQuery } from '../../store/generated/generatedApi';
import { InvitationAccepted } from './InvitationAccepted';
import { InvitationFound } from './InvitationFound';
import { InvitationNotFound } from './InvitationNotFound';
import { InvitationPending } from './InvitationPending';

export const InvitationView: FC = () => {
    const { code: inviteCode } = useParams();
    const { data: groupInvitationDetails, isLoading } = useGetUserGroupsInvitationByIdQuery({ inviteCode });
    const [acceptInvitation, { isLoading: isAcceptingInvitation, isError: isErrorAcceptingInvitation, error: acceptInvitationError, reset: resetAcceptInvitation }] = useCreateUserGroupsInvitationByIdMutation();
    useFailedActionSnackbar('accept', 'invitation', isErrorAcceptingInvitation, resetAcceptInvitation, acceptInvitationError);
    const handleJoinClicked = useCallback(() => {
        if (inviteCode) {
            acceptInvitation({ inviteCode })
        }
    }, [acceptInvitation, inviteCode]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (!groupInvitationDetails) {
        return <InvitationNotFound />;
    }

    if (groupInvitationDetails.isInvitationAccepted) {
        return <InvitationAccepted groupName={groupInvitationDetails.groupName} />;
    }

    if (groupInvitationDetails.isInvitationPending) {
        return <InvitationPending groupName={groupInvitationDetails.groupName} />;
    }

    return <InvitationFound groupName={groupInvitationDetails.groupName} onJoinClicked={handleJoinClicked} isLoading={isAcceptingInvitation} />;
};
