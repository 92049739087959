import { useCallback, useMemo } from 'react';
import { UserGroupDto } from '../../dtos';
import { emptyGuid } from '../../util';
import {
    FormValidationConfig,
    FormValidationMethod,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    useStandardValidatedFormManager,
} from '../../Components/CoreLib/library';

const isFutureDate: FormValidationMethod = (value: any) => {
    let isValid = false;
    if (value == null) {
        isValid = true;
    } else {
        value = new Date(Date.parse(value.toString()));
        isValid = value > new Date();
    }
    let errorMessageBuilder = (fieldName: string) => `${fieldName} must be a future date`;
    return {
        isValid,
        errorMessageBuilder,
    };
};

export function useUserGroupsFormManager(initialUserGroup: UserGroupDto) {
    const USER_GROUP_VALIDATION_CONFIG: FormValidationConfig<UserGroupDto> = new Map([
        ['name', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Name')],
        ['endDate', createValidatorConfig([isNotBlank, isFutureDate], 'End Date')],
    ]);

    const {
        formRecord: formUserGroup,
        setFormRecord: setFormUserGroup,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
    } = useStandardValidatedFormManager(initialUserGroup, USER_GROUP_VALIDATION_CONFIG);

    const handleDateChange = useCallback(
        (date: Date | null) => {
            if (date == null) {
                return;
            }
            setFormUserGroup((currentUserGroup) => ({ ...currentUserGroup, endDate: date }));
        },
        [setFormUserGroup]
    );

    const isNew = useMemo(() => formUserGroup.id === emptyGuid, [formUserGroup.id]);

    return {
        handleDateChange,
        formUserGroup,
        handleTextFieldChange,
        validateForm,
        fieldErrors,
        isNew,
    };
}
