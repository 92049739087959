import { FC } from 'react';

interface InfoVideoIconProps {
    height?: number;
    width?: number;
}
export const InfoVideoIcon: FC<InfoVideoIconProps> = ({ height = 24, width = 24 }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
        <path
            d='M19 13C16.24 13 14 15.24 14 18C14 20.76 16.24 23 19 23C21.76 23 24 20.76 24 18C24 15.24 21.76 13 19 13ZM19.5 20.5H18.5V17.5H19.5V20.5ZM19.5 16.5H18.5V15.5H19.5V16.5Z'
            fill='currentColor'
        />
        <path d='M15 12L9 8V16L15 12Z' fill='currentColor' />
        <path d='M3 6H21V11H23V6C23 4.9 22.1 4 21 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H12V18H3V6Z' fill='currentColor' />
    </svg>
);
