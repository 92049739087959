import { FC, useCallback } from 'react';
import { PersonSelect } from '../../Components/CommonInputs';
import { Box } from '@mui/system';
import { Button, Chip, FormLabel, Paper } from '@mui/material';
import { PersonListViewDto, SpaceDto } from '../../dtos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface IOnboardingPeopleFormProps {
    handleOnClickNext?: () => void;
    handleOnClickBack?: () => void;
    formSpace: SpaceDto;
    handlePersonAdded?: (person: PersonListViewDto) => void;
    handleRemovePerson?: (personId: string) => void;
    fieldErrors: Map<string, string>;
    validateForm: () => boolean;
}

export const OnboardingPeopleForm: FC<IOnboardingPeopleFormProps> = ({
    handleOnClickBack,
    handleOnClickNext,
    formSpace,
    handlePersonAdded,
    handleRemovePerson,
    fieldErrors,
    validateForm,
}) => {
    const chipDeleteHandler = useCallback((personId: string) => () => handleRemovePerson && handleRemovePerson(personId), [handleRemovePerson]);
    const personIsNotAlreadySelectedFilter = useCallback(
        (person: PersonListViewDto) => !formSpace?.people.map((p) => p.id).includes(person.id),
        [formSpace?.people]
    );

    const handlePersonSelected = useCallback(
        (selectedPerson?: PersonListViewDto) => {
            if (selectedPerson) {
                handlePersonAdded && handlePersonAdded(selectedPerson);
            }
        },
        [handlePersonAdded]
    );

    const handleSaveAndNext = useCallback(() => {
        const isValid = validateForm();
        if (isValid) {
            handleOnClickNext?.();
        }
    }, [handleOnClickNext, validateForm]);

    return (
        <Box display='flex' flexDirection='column' gap={1} p={1}>
            <Paper sx={{ p: 1 }} >
                <Box display='flex' flexDirection='column' gap={1}>
                    <FormLabel>Who am I committed to praying for at {formSpace?.name}</FormLabel>
                    {formSpace?.people?.length > 0 && (
                        <Box display='flex' flexDirection='row' flexWrap='wrap' gap={1}>
                            {formSpace?.people.map((p) => (
                                <Chip key={p.id} label={p.name} onDelete={chipDeleteHandler(p.id)} variant='outlined' />
                            ))}
                        </Box>
                    )}
                    <PersonSelect
                        label=''
                        value=''
                        onChange={handlePersonSelected}
                        errorMessage={fieldErrors.get('people')}
                        localPersonFilter={personIsNotAlreadySelectedFilter}
                        helperText={formSpace?.people.length > 3 ? 'It is recommended to commit to praying for just 3 people at a given time.' : 'Start typing to add a person.'}
                    />
                </Box>
            </Paper>
            <Box display='flex' gap={1}>
                <Button fullWidth startIcon={<ChevronLeftIcon />} variant='outlined' size='large' onClick={handleOnClickBack}>
                    Back
                </Button>
                <Button fullWidth startIcon={<NavigateNextIcon />} variant='contained' size='large' onClick={handleSaveAndNext}>
                    Next
                </Button>
            </Box>
        </Box>
    );
};
