import { FC, PropsWithChildren } from 'react';
import { ApplicationBottomBarContainer } from './ApplicationBottomBarContainer';
import { Box } from '@mui/system';

export const BottomNavigationWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box pb={7} height='100%' sx={{ overflowY: 'auto' }}>
            {children}
            <ApplicationBottomBarContainer />
        </Box>
    );
};
