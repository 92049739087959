import { AddCircle } from '@mui/icons-material';
import { Autocomplete, Divider, FormControl, FormHelperText, FormLabel, Icon, TextField } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { SpaceListViewDto } from '../../dtos';
import { useGetSpaceQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface ISpaceSelectProps {
    label?: string;
    value: string;
    onChange: (value?: SpaceListViewDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: () => void;
    localSpaceFilter?: (person: SpaceListViewDto) => boolean;
    disableClearable?: boolean;
    customSortMethod?: (options: SpaceListViewDto[]) => SpaceListViewDto[];
    autoSelectFirstValue?: boolean;
    fullWidth?: boolean;
    canAddNew?: boolean;
    dense?: boolean;
}

export const SpaceSelect: FC<ISpaceSelectProps> = ({
    label = 'Space',
    value,
    onChange,
    required,
    errorMessage,
    disabled,
    onBlur,
    localSpaceFilter,
    disableClearable,
    customSortMethod,
    autoSelectFirstValue,
    fullWidth,
    canAddNew,
    dense,
}) => {
    const { data: people, isLoading: isLoadingSpaces } = useGetSpaceQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: SpaceListViewDto | null) => {
        onChange(value ?? undefined);
    };

    const selectedValue = useMemo(() => {
        if (value === '' || value === emptyGuid) {
            return null;
        }
        return people?.pageResults.find((x) => x.id === value) ?? null;
    }, [value, people])

    const personOptions = useMemo(() => {
        let options = people?.pageResults ?? [];
        if (localSpaceFilter) {
            options = options.filter(localSpaceFilter);
        }
        if (customSortMethod) {
            options = customSortMethod(options);
        }
        return options;
    }, [people?.pageResults, localSpaceFilter, customSortMethod]);

    useEffect(() => {
        if (autoSelectFirstValue && (!value || value === emptyGuid) && personOptions.length > 0) {
            onChange(personOptions[0]);
        }
    }, [autoSelectFirstValue, value, personOptions, onChange]);

    const renderListItemsWithOptionalAdd = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: SpaceListViewDto) => {
        const isAddableOption = option.id === emptyGuid;
        if (isAddableOption) {
            return (
                <>
                    <Divider key={`${option.id}-add-divider`} />
                    <li {...props} key={option.id}>
                        <AddCircle color='primary' sx={{ mr: 1 }} /> Add "{option.name}"
                    </li>
                </>
            );
        }
        return (
            <li {...props} key={option.id}>
                <Icon sx={{ mr: 1 }}>{option.icon}</Icon> {option.name}
            </li>
        );
    }, []);

    return (
        <FormControl error={!!errorMessage} fullWidth={fullWidth} required={required} disabled={disabled} sx={{ color: 'white !important' }}>
            {label && <FormLabel>{label}</FormLabel>}
            <Autocomplete
                value={selectedValue}
                size={dense ? 'small' : 'medium'}
                onChange={handleSelectChange}
                loading={isLoadingSpaces}
                options={personOptions}
                getOptionLabel={(x) => x.name}
                renderOption={renderListItemsWithOptionalAdd}
                filterOptions={(options, params) => {
                    if (canAddNew && params.inputValue !== '') {
                        options.push({
                            name: params.inputValue,
                            icon: 'place',
                            id: emptyGuid,
                            isActive: true,
                            createdOn: new Date(),
                        });
                    }

                    return options;
                }}
                renderInput={(params) => {
                    if (selectedValue) {
                        params.InputProps.startAdornment = <Icon>{selectedValue.icon}</Icon>
                    }
                    return <TextField error={!!errorMessage} {...params} />;
                }}
                disabled={disabled}
                onBlur={onBlur}
                disableClearable={disableClearable}
            />
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};
