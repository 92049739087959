import { AccountBox, Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Paper } from '@mui/material';
import { FC } from 'react';
import { StandardDatePicker } from '../../Components/CommonInputs';
import { FormInput, IFormProps, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { ConfirmActionButton, PageHeader } from '../../Components/Global';
import { UserGroupDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useArchiveUserGroupsByIdMutation } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util/constants';
import { useUserGroupsFormManager } from './useUserGroupsFormManager';

const DEFAULT_GROUP: UserGroupDto = {
    name: '',
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
    endDate: new Date(),
    isAdmin: false,
    metrics: {
        numSharedActs: 0,
        numCompletedActs: 0,
        numAcceptedMembers: 0,
    },
};

interface IGroupFormProps extends IFormProps<UserGroupDto> {
    isAdmin: boolean;
}

export const GroupForm: FC<IGroupFormProps> = ({ save, cancel, initValues = DEFAULT_GROUP, isLoading, isAdmin }) => {
    const goTo = useGoTo();
    const { handleDateChange, formUserGroup, handleTextFieldChange, validateForm, fieldErrors, isNew } = useUserGroupsFormManager(initValues);

    const [archiveUserGroups, { isLoading: isDeleting, isSuccess: isSuccessfullyDeleted, isError: isErrorDeleting, reset: resetDelete }] =
        useArchiveUserGroupsByIdMutation();

    useSuccessfulActionSnackbar('deleted', 'group', isSuccessfullyDeleted, goTo.GroupHome);
    useFailedActionSnackbar('delete', 'group', isErrorDeleting, resetDelete);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formUserGroup);
        }
    };

    const handleDelete = () => {
        archiveUserGroups({ id: formUserGroup.id });
    };

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader onActionButtonClicked={cancel} icon={<AccountBox fontSize='large' />} title={`${isNew ? 'Add' : 'Edit'} Group`} />
            <Paper sx={{ mx: 1, pb: 1 }}>
                <Box display='flex' flexDirection='row' gap={1} px={1} pt={1}>
                    <FormInput
                        value={formUserGroup.name}
                        fullWidth
                        name='name'
                        onChange={handleTextFieldChange}
                        errorText={fieldErrors.get('name')}
                        label='Name'
                        disabled={isLoading || !isAdmin}
                    />
                    <StandardDatePicker
                        value={formUserGroup.endDate}
                        fullWidth
                        errorText={fieldErrors.get('endDate')}
                        onChange={(e) => handleDateChange(e)}
                        label='End Date'
                        disabled={isLoading || !isAdmin}
                    />
                </Box>
            </Paper>
            {isAdmin && (
                <Box display='flex' flexDirection='row' gap={1} px={1}>
                    {!isNew && (
                        <ConfirmActionButton startIcon={<DeleteIcon />} actionName='Delete' color='error' onConfirm={handleDelete} disabled={isDeleting} />
                    )}
                    <Button fullWidth startIcon={<Save />} variant='contained' size='large' onClick={handleSave} disabled={isLoading}>
                        Save
                    </Button>
                </Box>
            )}
        </Box>
    );
};
