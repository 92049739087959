import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import HistoryIcon from '@mui/icons-material/History';
import { Box, Button, Chip, Icon, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { JournalListViewDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetJournalQuery } from '../../store/generated/generatedApi';
import { standardDateFormat } from '../../util';
import { FilterJournal, IJournalFilterProps } from './FilterJournal';

const COLUMNS: DataTableColumn<JournalListViewDto>[] = [
    { key: 'date', label: 'Date', sortKey: 'DATE', fieldMapper: (row) => standardDateFormat(row.date), width: '80px' },
    {
        key: 'space',
        label: 'Space',
        sortKey: '',
        unsorted: true,
        fieldMapper: (row) => (
            <Tooltip title={row.space?.name}>
                <Icon>{row.space?.icon}</Icon>
            </Tooltip>
        ),
        align: 'center',
    },
    { key: 'people', label: 'People', sortKey: '', unsorted: true, fieldMapper: (row) => row.people.map((p) => p.name).join(', ') },
];

export const JournalsListView: FC = () => {
    const goTo = useGoTo();
    const [open, setOpen] = useState(false);
    const [filterValues, setFilterValues] = useState<IJournalFilterProps>({ date: null, space: null });

    const handleFilterButton = () => {
        setOpen(!open);
    };

    const handleApplyButton = (data: IJournalFilterProps) => {
        setFilterValues(data);
    };

    const handleOnDelete = (filterType: string, personToDeleteId: string = '') => {
        if (filterType !== 'person') {
            setFilterValues((prevValues) => ({
                ...prevValues,
                [filterType]: null,
            }));
        } else {
            setFilterValues((prevValues) => ({
                ...prevValues,
                people: prevValues.people?.filter((currentPerson) => currentPerson.id !== personToDeleteId),
            }));
        }
    };

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <Box display='flex' flexDirection='column' rowGap={1} px={1}></Box>
            {open && <FilterJournal open={open} onClose={handleFilterButton} onApply={handleApplyButton} initialValues={filterValues} />}
            {(filterValues.space || filterValues.date || (filterValues.people && filterValues.people.length > 0)) && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {filterValues.space && (
                        <Chip variant='filled' label={'Space: ' + filterValues.space?.name} color='primary' onDelete={() => handleOnDelete('space')}></Chip>
                    )}
                    {filterValues.date && (
                        <Chip
                            variant='filled'
                            label={'Date: ' + filterValues.date?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                            color='primary'
                            onDelete={() => handleOnDelete('date')}></Chip>
                    )}
                    {filterValues.people &&
                        filterValues.people.map((person, idx) => (
                            <Chip
                                variant='filled'
                                key={person.id}
                                label={'Person: ' + person.name}
                                color='primary'
                                onDelete={() => handleOnDelete('person', person.id)}></Chip>
                        ))}
                </Box>
            )}
            <PageHeader icon={<HistoryIcon />} title='Journal History' />
            <Box display='flex' px={1} flexDirection='column' gap={1}>
                <Button color='primary' variant='outlined' onClick={handleFilterButton} startIcon={<FilterListIcon />} size='large'>
                    Filter Journal History
                </Button>
                <MobileListView
                    permissionName='journals'
                    getDataQuery={useGetJournalQuery}
                    tableColumns={COLUMNS}
                    handleRowClick={goTo.JournalReview}
                    hideSearch
                    additionalQueryParameters={{
                        date: filterValues.date?.toISOString().slice(0, 10),
                        spaceId: filterValues.space?.id ?? '',
                        people: filterValues.people?.map((p) => p.id) ?? [],
                    }}
                    hideRowsPerPageSelect={true}
                    fixedPageSize={10}
                />
                <Button color='primary' variant='contained' startIcon={<AddCircleIcon />} size='large' onClick={goTo.JournalStart}>
                    Start Praying
                </Button>
            </Box>
        </Box>
    );
};
