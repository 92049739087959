import { Capacitor } from '@capacitor/core';
import { Share as CapacitorShare } from '@capacitor/share';
import { ContentCopy, IosShare, Share } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export interface IGroupInviteLinkSectionProps {
    inviteCode: string;
};

export const GroupInviteLinkSection: FC<IGroupInviteLinkSectionProps> = ({ inviteCode }) => {
    const baseUrl = Capacitor.isNativePlatform() ? process.env.REACT_APP_BASE_URL : window.location.origin;
    var inviteLink = useMemo(() => `${baseUrl}/invite/${inviteCode}`, [baseUrl, inviteCode]);
    const [isShareEnabled, setIsShareEnabled] = useState(false);

    useEffect(() => {
        const checkShareCapability = async () => {
            const canShare = await CapacitorShare.canShare();
            setIsShareEnabled(canShare.value);
        };
        checkShareCapability();
    }, []);

    var handleCopyClicked = useCallback(() => {
        navigator.clipboard.writeText(inviteLink);
    }, [inviteLink]);

    var handleShareClicked = useCallback(async () => {
        await CapacitorShare.share({
            url: inviteLink,
        });
    }, [inviteLink]);

    const shareIcon = Capacitor.getPlatform() === 'android' ? <Share /> : <IosShare />;

    return (
        <Paper sx={{ p: 1 }}>
            <Typography variant='h3'>Group Invite Link</Typography>
            <Box display='flex' alignItems='center' mt={1} gap={1}>
                <Box flexGrow={1}>
                    <Typography variant='body2' sx={{ wordBreak: 'break-all' }}>{inviteLink}</Typography>
                </Box>
                <Box display='flex' flexDirection='column' rowGap={1}>
                    <Box>
                        <Button fullWidth variant='contained' startIcon={<ContentCopy />} onClick={handleCopyClicked}>Copy</Button>
                    </Box>
                    {isShareEnabled && <Box>
                        <Button fullWidth variant='contained' startIcon={shareIcon} onClick={handleShareClicked}>Share</Button>
                    </Box>}
                </Box>
            </Box>
        </Paper>
    );
};

