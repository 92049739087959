import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { useContext } from 'react';
import { UserPermissionContext } from '../Components/CoreLib/library';


export const useHandleLogout = () => {
    const { logout } = useAuth0();
    const { clearPermissions } = useContext(UserPermissionContext)

    const handleLogout = async () => {
        const redirectUri = process.env.REACT_APP_REDIRECT_URI || window.location.origin;
        await logout({
            logoutParams: {
                returnTo: redirectUri,
            },
            openUrl: (url) => {
                Browser.open({
                    url,
                    windowName: '_self',
                });
            },
        }).then(() => {
            clearPermissions();
        });
        
    };
    return handleLogout;
};
