import { Box, Button, Checkbox, Link, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { FormInput } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { useOnboardingDataCollector } from './useOnboardingDataCollector';
import CheckIcon from '@mui/icons-material/Check';

export interface IOnboardingDataCollectionScreenProps {
    onUserAccountCreated: () => void;
}

export const OnboardingDataCollectionScreen: FC<IOnboardingDataCollectionScreenProps> = ({ onUserAccountCreated }) => {
    const { formCollectedData, fieldErrors, handleTextFieldChange, handleSubmitClicked } = useOnboardingDataCollector(onUserAccountCreated);
    const [hasReadTOC, setHasReadTOC] = useState(false);

    const handleTermsAndConditionsCheckBoxChange = () => {
        setHasReadTOC(!hasReadTOC);
    };

    return (
        <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column' gap={1}>
                <PageHeader title='What Is Your Name?' />
                <Box px={1}>
                    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
                        <FormInput
                            fullWidth
                            label='First Name'
                            name='firstName'
                            value={formCollectedData.firstName}
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('firstName')}
                        />
                        <FormInput
                            fullWidth
                            label='Last Name'
                            name='lastName'
                            value={formCollectedData.lastName}
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('lastName')}
                        />
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Checkbox onChange={handleTermsAndConditionsCheckBoxChange} checked={hasReadTOC} />
                            <Typography pr={1}> Accept </Typography>
                            <Link href='https://prayingonoffense.com/terms-and-conditions/' target='_blank'>
                                Terms and Conditions
                            </Link>
                        </Box>
                    </Paper>
                </Box>
                <Box px={1}>
                    <Button disabled={!hasReadTOC} startIcon={<CheckIcon />} onClick={handleSubmitClicked} fullWidth variant='contained'>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
