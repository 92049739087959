import { FormValidationMethod } from "../Components/CoreLib/library";

// TODO: this may be worth adding to the core library
export function isValidArray(minLength?: number, maxLength?: number): FormValidationMethod {
    return (value: any) => {
        if (!value) {
            return {
                isValid: true,
                errorMessageBuilder: () => ''
            }
        }

        if (Array.isArray(value)) {
            if (minLength && value.length < minLength) {
                return {
                    isValid: false,
                    errorMessageBuilder: (fieldName: string) => `At least ${minLength} ${fieldName} must be selected`
                }
            }
            if (maxLength && value.length > maxLength) {
                return {
                    isValid: false,
                    errorMessageBuilder: (fieldName: string) => `There must have fewer than ${maxLength} ${fieldName}`
                }
            }
        } else {
            return {
                isValid: false,
                errorMessageBuilder: (fieldName: string) => `${fieldName} must be an array`
            }
        }

        return {
            isValid: true,
            errorMessageBuilder: () => ''
        }
    }
}