import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useGetUserGroupsQuery } from '../../store/generated/generatedApi';
import { UserGroupListViewDto } from '../../dtos/generated/UserGroupListViewDto';
import GroupsIcon from '@mui/icons-material/Groups';
import { standardDateFormat } from '../../util/formatDate';
import { PageHeader } from '../../Components/Global';

export const GroupListView = () => {
    const goTo = useGoTo();

    const COLUMNS: DataTableColumn<UserGroupListViewDto>[] = [
        { key: 'name', label: 'Group Name', sortKey: 'NAME', unsorted: true },
        { key: 'endDate', fieldMapper: (row) => standardDateFormat(row.endDate), label: 'End Date', sortKey: 'ENDDATE' },
    ];

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader icon={<GroupsIcon />} title='Social' />
            <Box px={1} display='flex' flexDirection='column' rowGap={1}>
                <MobileListView
                    permissionName='userGroups'
                    getDataQuery={useGetUserGroupsQuery}
                    tableColumns={COLUMNS}
                    handleRowClick={goTo.GroupDetails}
                    hideRowsPerPageSelect={true}
                    fixedPageSize={10}
                />
                <Button color='primary' variant='contained' startIcon={<AddCircle />} size='large' onClick={goTo.GroupCreate}>
                    Add Group
                </Button>
            </Box>
        </Box>
    );
};
