import { useCallback, useMemo } from "react";
import { useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { FocusType, UserStoryDto } from "../../dtos";
import { useGetPersonQuery, useGetSpaceQuery, useCreateUserStoryMutation } from "../../store/generated/generatedApi";

export function useCreateStoryForm(onStoryCreated: (story: UserStoryDto) => void, onlyRecentlyMentioned: boolean = false) {
    const { data: people, isLoading: isLoadingPeople } = useGetPersonQuery({
        sortAsc: true,
        page: 0,
        pageSize: 1000,
        includeInactive: false,
        onlyMentionedSinceLastReflection: onlyRecentlyMentioned
    });

    const { data: spaces, isLoading: isLoadingSpaces } = useGetSpaceQuery({
        sortAsc: true,
        page: 0,
        pageSize: 1000,
        includeInactive: false,
        onlyMentionedSinceLastReflection: onlyRecentlyMentioned
    });

    const isLoadingData = useMemo(() => isLoadingPeople || isLoadingSpaces, [isLoadingPeople, isLoadingSpaces]);

    const [createStory, { isLoading: isCreatingStory, error: createStoryError }] = useCreateUserStoryMutation();
    useFailedActionSnackbar('create', 'story', !!createStoryError, undefined, createStoryError);

    const sendCreateStoryRequest = useCallback(
        (focusType: FocusType, focusId?: string) => {
            if (isCreatingStory) {
                return;
            }

            createStory({ focusType, focusId })
                .unwrap()
                .then((result) => onStoryCreated(result))
                .catch(() => console.error('Failed to generate user story'));
        },
        [isCreatingStory, createStory, onStoryCreated]
    );

    const handleCreatePersonalStoryClicked = useCallback(() => {
        sendCreateStoryRequest(FocusType.Self);
    }, [sendCreateStoryRequest]);

    const handleCreatePersonStoryClicked = useCallback(
        (personId: string) => () => {
            sendCreateStoryRequest(FocusType.Person, personId);
        },
        [sendCreateStoryRequest]
    );

    const handleCreateSpaceStoryClicked = useCallback(
        (spaceId: string) => () => {
            sendCreateStoryRequest(FocusType.Space, spaceId);
        },
        [sendCreateStoryRequest]
    );

    const loadingStates = useMemo(() => [{ isLoading: isCreatingStory, loadingMessage: 'Discovering Story...' }], [isCreatingStory]);

    return {
        people,
        spaces,
        isLoadingData,
        loadingStates,
        handleCreatePersonalStoryClicked,
        handleCreatePersonStoryClicked,
        handleCreateSpaceStoryClicked
    }
}