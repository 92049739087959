import { useAuth0 } from '@auth0/auth0-react';
import { History, Place, AccountBox, AutoAwesome } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { ApplicationBottomBar } from './ApplicationBottomBar';
import PeopleIcon from '@mui/icons-material/People';
export interface NavigationDrawerRouteDTO {
    icon: React.ReactElement<SvgIconProps>;
    name: string;
    route?: string;
    isHidden?: boolean;
    requiredPermissions?: string[];
    permissionLogic?: 'or' | 'and';
}

export const ApplicationBottomBarContainer: FC = () => {
    const { isAuthenticated, error } = useAuth0();

    const publicRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <History />,
            name: 'Journal History',
            route: '/journals',
            requiredPermissions: ['read:journals'],
        },
        {
            icon: <AutoAwesome />,
            name: 'Stories',
            route: '/stories',
            requiredPermissions: ['read:stories'],
        },
        {
            icon: <Place />,
            name: 'Manage Spaces',
            route: '/spaces',
            requiredPermissions: ['read:spaces']
        },
        {
            icon: <PeopleIcon />,
            name: 'Manage People',
            route: '/people',
            requiredPermissions: ['read:people'],
        },
        {
            icon: <AccountBox />,
            name: 'My Account',
            route: `/myaccount`,
        }
    ];

    if (!isAuthenticated) {
        return null;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    
    return <ApplicationBottomBar routes={publicRoutes}/>;
};
