import { Typography, Box, Paper } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface IPageSubHeaderProps {
    text: string;
    action?: ReactNode;
}

export const PageSubHeader: FC<IPageSubHeaderProps> = ({ text, action }) => {
    return (
        <Paper square sx={{ width: '100%' }}>
            <Box
                display='flex'
                textAlign='center'
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                gap={2}
                sx={{ width: '100%', borderBottom: '2px solid', borderColor: 'primary.main' }}
                p={1}>
                <Typography variant='button' color='primary' sx={{ fontSize: '16px' }}>
                    {text}
                </Typography>
                {action}
            </Box>
        </Paper>
    );
};
