import { Box, Chip, Icon, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { UserStoryDto } from '../../dtos';
import { PageSubHeaderComponentVariant } from '../../Components/Global/PageSubHeaderComponentVariant';

export interface IStoryDetailsProps {
    story: UserStoryDto;
}

export const StoryDetails: FC<IStoryDetailsProps> = ({ story }) => {
    return (
        <>
            <PageSubHeaderComponentVariant child={
                <Box>
                    <Chip icon={story.focusIcon ? <Icon>{story.focusIcon}</Icon> : undefined} label={story.focusName} color='primary' />
                </Box>
            } />
            <Box display='flex' flexDirection='column' gap={1} maxHeight='100%' overflow='hidden'>
                <Paper sx={{ m: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, p: 1, overflow: 'hidden' }}>
                    <Box width='100%' flexGrow={1} overflow='auto'>
                        <Typography>{story.text}</Typography>
                    </Box>
                </Paper>
            </Box>
        </>
    );
};
