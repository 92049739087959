import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { App as CapApp } from '@capacitor/app';
import { jwtDecode } from "jwt-decode";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, setToken } from "../store";
import { useHandleLogout } from "./useHandleLogout";

export function useHandleLogin()
{
    const handleLogout = useHandleLogout();
    const { isAuthenticated, loginWithRedirect, handleRedirectCallback, getAccessTokenSilently, isLoading: isAuth0Loading, error: authError } = useAuth0();
    const dispatch = useAppDispatch();
    const [isRequestingNewAccessToken, setIsRequestingNewAccessToken] = useState(false);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [userPermissions, setUserPermissions] = useState<string[] | null>(null);
    const [isTokenSetInRedux, setIsTokenSetInRedux] = useState(false);
    const navigate = useNavigate();
    const isFullyAuthenticated = isAuthenticated && !!accessToken && isTokenSetInRedux;
    // Native apps don't reliably redirect, but PWA does. Hiding the Login screen on the PWA.
    const isRedirectReliable = !Capacitor.isNativePlatform();

    const isAuthenticating = useMemo(() => isAuth0Loading || isRequestingNewAccessToken, [isAuth0Loading, isRequestingNewAccessToken]);

    const togglePermission = useCallback(
        (permission: string): void => {
            if (!userPermissions) {
                return;
            }
            const isPermissionActive = userPermissions.includes(permission);
            if (isPermissionActive) {
                setUserPermissions([...userPermissions.filter((p) => p !== permission)]);
            } else {
                setUserPermissions([...userPermissions, permission]);
            }
        },
        [userPermissions]
    );

    const triggerLogin = useCallback(async () => {
        await loginWithRedirect({
            authorizationParams: {
                prompt: 'login',
            },
            async openUrl(url) {
                await Browser.open({
                    url,
                    windowName: '_self',
                });
            },
        });
    }, [loginWithRedirect]);

    const requestNewAccessToken = useCallback(async () => {
        try {
            setIsRequestingNewAccessToken(true);
            let accessToken = '';
            let redirect_uri = process.env.REACT_APP_REDIRECT_URI || window.location.origin;
            let getTokenOptions : GetTokenSilentlyOptions = {
                authorizationParams: {
                    redirect_uri: redirect_uri,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'SCOPE profile email offline_access',
                },
                cacheMode: 'off'
            };

            try {
                accessToken = await getAccessTokenSilently(getTokenOptions); 
            } catch (error) {
                console.error('Error getting access token silently:', error);
                handleLogout();
                return;
            }

            setAccessToken(accessToken);
        } catch (error) {
            console.error('Error getting user permissions, refreshing login:', error);
            triggerLogin();
        } finally {
            setIsRequestingNewAccessToken(false);
        }
    }, [getAccessTokenSilently, triggerLogin, handleLogout]);

    const clearTokensAndPermissions = useCallback(() => {
        setAccessToken(null);
        setUserPermissions(null);
        setIsTokenSetInRedux(false)
    }, []);

    // Handle when access token has changed
    useEffect(() => {
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const permissions = ((decodedToken as any)?.permissions as string[]) ?? [];
            setUserPermissions(permissions);
            dispatch(setToken(accessToken));
            setIsTokenSetInRedux(true);
        } else {
            dispatch(setToken(''));
            clearTokensAndPermissions()
        }
    }, [accessToken, dispatch, clearTokensAndPermissions]);

    // If we are authenticated but don't have an access token, request one
    useEffect(() => {
        if (isAuthenticated && !isAuth0Loading && !accessToken && !isRequestingNewAccessToken) {
            requestNewAccessToken();
        }

    }, [accessToken, isAuthenticated, isAuth0Loading, requestNewAccessToken, isRequestingNewAccessToken]);

    useEffect(() => {
        try {
            getAccessTokenSilently({
                authorizationParams: {
                    redirect_uri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'SCOPE profile email offline_access',
                },
                cacheMode: 'cache-only',
            });   
        } catch (error) {
            console.error('Error getting access token silently: ', error);
        }
    }, [getAccessTokenSilently]);

    // Handle mobile app deep links and redirect callbacks
    useEffect(() => {
        CapApp.addListener('appUrlOpen', async ({ url }) => {
            const callbackUri = process.env.REACT_APP_REDIRECT_URI || window.location.origin;
            const slug = url.split('.com').pop();

            if (url.startsWith(callbackUri)) {
                if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
                    await handleRedirectCallback(url);
                }
                await Browser.close();
            } else if (Capacitor.isNativePlatform() && url.startsWith(process.env.REACT_APP_BASE_URL ?? '') && slug) {
                // Deeplink redirect
                navigate(slug);
            }
        });
    }, [handleRedirectCallback, navigate]);

    // If we are on the web version and we are logged out then automatically log in
    useEffect(() => {
        if (!isAuthenticated && !isAuth0Loading && isRedirectReliable) {
            triggerLogin();
        }
    }, [isAuthenticated, isAuth0Loading, triggerLogin, isRedirectReliable]);

    return {
        userPermissions,
        isAuthenticating,
        authError,
        togglePermission,
        clearTokensAndPermissions,
        isFullyAuthenticated
    }
}