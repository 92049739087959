import { DirectionsRun, Settings } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useCallback } from 'react';
import { InfiniteLoadingListView, PageHeader } from '../../Components/Global';
import { ActListViewDto } from '../../dtos';
import { useLazyGetActQuery } from '../../store/generated/generatedApi';
import { ActListViewItem } from './ActListViewItem';
import { useGoTo } from '../../routing/useGoTo';

export const ActsHome: FC = () => {
    const goTo = useGoTo();
    const handleSettingsClicked = useCallback(() => {
        goTo.ActsManager();
    }, [goTo]);

    const renderRowItem = useCallback(
        (item: ActListViewDto, updateItem: (updatedItem: ActListViewDto) => void) => (
            <ActListViewItem act={item} onChange={updateItem} onShareClicked={() => goTo.ActsHomeShareAct(item.id)} />
        ),
        [goTo]
    );

    return (
        <Box display='flex' flexDirection='column' height='100%'>
            <PageHeader
                icon={<DirectionsRun />}
                title='My Fearless Acts'
                actionButtonIcon={<Settings fontSize='large' />}
                onActionButtonClicked={handleSettingsClicked}
            />
            <InfiniteLoadingListView
                dataLoadingQuery={useLazyGetActQuery}
                renderRowDetails={renderRowItem}
                sortKey='COMPLETED_THEN_JOURNAL_DATE'
                sortAsc={false}
            />
        </Box>
    );
};
