import { Close } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

export interface IPageHeaderProps {
    actionButtonIcon?: JSX.Element;
    onActionButtonClicked?: () => void;
    icon?: JSX.Element;
    title: string;
    endText?: string;
}

export const PageHeader: FC<IPageHeaderProps> = ({
    onActionButtonClicked,
    icon,
    title,
    endText,
    actionButtonIcon = <Close fontSize='large' />,
}) => {
    return (
        <Paper square>
            <Box display='flex' alignItems='stretch' sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
                {onActionButtonClicked && (
                    <Box bgcolor='#ffffff' display='flex' flexDirection='column' justifyContent='center'>
                        <IconButton onClick={onActionButtonClicked}>{actionButtonIcon}</IconButton>
                    </Box>
                )}
                <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1} mx={1} gap={1}>
                    {icon}
                    <Typography variant='h2' py={1}>
                        {title}
                    </Typography>
                    {endText && (
                        <Box marginLeft='auto'>
                            <Typography variant='h2'>{endText}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Paper>
    );
};
