import { AccountBox, Check, Delete, Save } from '@mui/icons-material';
import { Box, Button, Chip, FormLabel, Paper } from '@mui/material';
import { FC, useCallback } from 'react';
import { PersonSelect } from '../../Components/CommonInputs';
import { IconSelect } from '../../Components/CommonInputs/IconSelect';
import { FormInput, IFormProps, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { ConfirmActionButton, PageHeader } from '../../Components/Global';
import { PersonListViewDto, SpaceDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useArchiveSpaceByIdMutation } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util/constants';
import { useSpaceFormManager } from './useSpaceFormManager';

const DEFAULT_SPACE: SpaceDto = {
    name: '',
    icon: '',
    people: [],
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
};

export const SpaceForm: FC<IFormProps<SpaceDto>> = ({ save, cancel, initValues = DEFAULT_SPACE, isLoading }) => {
    const goTo = useGoTo();
    const {
        formSpace,
        isNew,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        handlePersonAdded,
        handleRemovePerson,
        isAddingPerson,
        handleIconChange,
    } = useSpaceFormManager(initValues);

    const [archiveSpace, { isLoading: isDeleting, isSuccess: isSuccessfullyDeleted, isError: isErrorDeleting, reset: resetDelete }] =
        useArchiveSpaceByIdMutation();

    useSuccessfulActionSnackbar('deleted', 'space', isSuccessfullyDeleted, goTo.SpaceHome);
    useFailedActionSnackbar('delete', 'space', isErrorDeleting, resetDelete);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formSpace);
        }
    }, [validateForm, formSpace, save]);

    const handleDeleteClicked = useCallback(() => {
        archiveSpace({ id: formSpace.id });
    }, [archiveSpace, formSpace.id]);

    const handlePersonSelected = useCallback(
        (selectedPerson?: PersonListViewDto) => {
            if (selectedPerson) {
                handlePersonAdded(selectedPerson);
            }
        },
        [handlePersonAdded]
    );

    const chipDeleteHandler = useCallback((personId: string) => () => handleRemovePerson(personId), [handleRemovePerson]);
    const personIsNotAlreadySelectedFilter = useCallback(
        (person: PersonListViewDto) => !formSpace.people.map((p) => p.id).includes(person.id),
        [formSpace.people]
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader onActionButtonClicked={cancel} icon={<AccountBox fontSize='large' />} title={`${isNew ? 'Add' : 'Edit'} Space`} />
            <Paper sx={{ pb: 1, mx: 1 }}>
                <Box display='flex' flexDirection='column' gap={1} px={1} pt={1}>
                    <Box display='flex' flexDirection='row' flexWrap='nowrap' gap={1}>
                        <Box flexGrow={1}>
                            <FormInput
                                fullWidth
                                label='Name'
                                name='name'
                                value={formSpace.name}
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('name')}
                                disabled={isDeleting}
                                required
                            />
                        </Box>
                        <Box width='96px'>
                            <IconSelect
                                fullWidth
                                label='Icon'
                                value={formSpace.icon}
                                onChange={handleIconChange}
                                errorMessage={fieldErrors.get('icon')}
                                disabled={isDeleting}
                                required
                            />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <FormLabel>Who am I actively praying for in this Space?</FormLabel>
                        {formSpace.people.length > 0 && (
                            <Box display='flex' flexDirection='row' flexWrap='wrap' gap={1}>
                                {formSpace.people.map((p) => (
                                    <Chip key={p.id} label={p.name} onDelete={chipDeleteHandler(p.id)} variant='outlined' />
                                ))}
                            </Box>
                        )}
                        <PersonSelect
                            label=''
                            value=''
                            onChange={handlePersonSelected}
                            errorMessage={fieldErrors.get('people')}
                            disabled={isAddingPerson || isDeleting}
                            localPersonFilter={personIsNotAlreadySelectedFilter}
                            helperText={formSpace?.people.length > 3 ? 'It is recommended to commit to praying for just 3 people at a given time.' : 'Start typing to add a person.'}
                        />
                    </Box>
                </Box>
            </Paper>
            <Box display='flex' gap={1} px={1}>
                {!isNew && (
                    <ConfirmActionButton startIcon={<Delete />} actionName='Delete' color='error' onConfirm={handleDeleteClicked} disabled={isDeleting} />
                )}
                <Button
                    fullWidth
                    startIcon={isFormDirty ? <Save /> : <Check />}
                    variant='contained'
                    size='large'
                    onClick={handleSave}
                    disabled={isDeleting || !isFormDirty}>
                    {isFormDirty ? 'Save' : 'Saved'}
                </Button>
            </Box>
        </Box>
    );
};
