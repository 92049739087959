import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import Shell from './Components/ApplicationShell/Shell';
import { LoadingIndicator, NotAuthorizedError, UserPermissionContext, userHasPermissions } from './Components/CoreLib/library';
import theme from './Theme';
import { OnboardCheckingWrapper } from './Views/Onboarding/OnboardCheckingWrapper';
import { IRoute, routes } from './routing/Routes';
import { useHandleLogin } from './util';

function App() {
    const { userPermissions, isAuthenticating, authError, togglePermission, clearTokensAndPermissions, isFullyAuthenticated } = useHandleLogin();

    const renderRoute = useCallback(
        (route: IRoute) => {
            if (userPermissions && userHasPermissions(route.requiredPermissions ?? [], userPermissions, route.permissionLogic)) {
                return <Route key={route.path} path={route.path} element={<OnboardCheckingWrapper>{route.component}</OnboardCheckingWrapper>} />;
            } else {
                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            isFullyAuthenticated ? (
                                <OnboardCheckingWrapper>
                                    <NotAuthorizedError />
                                </OnboardCheckingWrapper>
                            ) : null
                        }
                    />
                );
            }
        },
        [userPermissions, isFullyAuthenticated]
    );

    const DismissSnackbar = ({ id }: { id: SnackbarKey }) => {
        const { closeSnackbar } = useSnackbar();
        return (
            <IconButton onClick={() => closeSnackbar(id)} color={'inherit'}>
                <Close />
            </IconButton>
        );
    };

    if (isAuthenticating) {
        return <LoadingIndicator />;
    }

    if (authError) {
        return <p>{authError.message}</p>;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <SnackbarProvider
                    maxSnack={3}
                    style={{ marginTop: 'env(safe-area-inset-top)' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    action={(key) => <DismissSnackbar id={key} />}>
                    <UserPermissionContext.Provider
                        value={{
                            isAuthenticated: isFullyAuthenticated,
                            permissions: userPermissions ?? [],
                            togglePermission: togglePermission,
                            clearPermissions: clearTokensAndPermissions,
                        }}>
                        <Routes>
                            <Route path='/' element={<Shell />}>
                                {routes.map(renderRoute)}
                            </Route>
                        </Routes>
                    </UserPermissionContext.Provider>
                </SnackbarProvider>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;
