import { useCallback, useMemo } from 'react';
import {
    IFormFieldValidationConfig,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    useStandardValidatedFormManager,
} from '../../Components/CoreLib/library';
import { PersonListViewDto, SpaceDto } from '../../dtos';
import { emptyGuid, isValidArray } from '../../util';
import { useCreatePersonMutation } from '../../store/generated/generatedApi';

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

const SPACE_VALIDATION_CONFIG: FormValidationConfig<SpaceDto> = new Map([
    ['name', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Name')],
    ['icon', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Icon')],
    ['people', createValidatorConfig([isValidArray(1)], 'Person')],
]);

export function useSpaceFormManager(initialSpace: SpaceDto) {
    const {
        formRecord: formSpace,
        setFormRecord: setFormSpace,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        resetForm,
        validateField,
    } = useStandardValidatedFormManager(initialSpace, SPACE_VALIDATION_CONFIG);
    const [createPerson, { isLoading: isCreatingPerson }] = useCreatePersonMutation();

    const handlePersonAdded = useCallback(
        (personToAdd: PersonListViewDto) => {
            if (personToAdd.id === emptyGuid) {
                createPerson(personToAdd)
                    .unwrap()
                    .then((person) => handlePersonAdded(person))
                    .catch((error) => console.error('Failed to create person', error));
            } else {
                setFormSpace((currentSpace) => ({ ...currentSpace, people: [...currentSpace.people, personToAdd] }));
                validateField('people', [...formSpace.people, personToAdd]);
            }
        },
        [setFormSpace, createPerson, validateField, formSpace.people]
    );

    const handleRemovePerson = useCallback(
        (personId: string) => {
            setFormSpace((currentSpace) => ({ ...currentSpace, people: currentSpace.people.filter((p) => p.id !== personId) }));
        },
        [setFormSpace]
    );

    const handleIconChange = useCallback(
        (icon: string) => {
            setFormSpace((currentSpace) => ({ ...currentSpace, icon }));
        },
        [setFormSpace]
    );

    const isNew = useMemo(() => formSpace.id === emptyGuid, [formSpace.id]);

    return {
        formSpace,
        isNew,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        resetForm,
        handlePersonAdded,
        handleRemovePerson,
        isAddingPerson: isCreatingPerson,
        handleIconChange,
        validateField,
    };
}
