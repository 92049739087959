import { useCallback, useContext } from 'react';
import {
    IFormFieldValidationConfig,
    UserPermissionContext,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    useFailedActionSnackbar,
    useStandardValidatedFormManager,
} from '../../Components/CoreLib/library';
import { useCreateUserProvisionMutation } from '../../store/generated/generatedApi';
import { DEFAULT_USER } from '../User';
import { useAuth0 } from '@auth0/auth0-react';

export interface IOnboardingCollectedData {
    firstName: string;
    lastName: string;
}

const INITIAL_COLLECTED_DATA: IOnboardingCollectedData = {
    firstName: '',
    lastName: '',
};

type FormValidationConfig<T> = Map<keyof T, IFormFieldValidationConfig>;

const USER_VALIDATION_CONFIG: FormValidationConfig<IOnboardingCollectedData> = new Map([
    ['firstName', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'First Name')],
    ['lastName', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Last Name')],
]);

export function useOnboardingDataCollector(onUserAccountCreated: () => void) {
    const [createUser, { isLoading: isCreatingUser, isError: isErrorCreatingUser, reset: resetCreateUser }] = useCreateUserProvisionMutation();
    const { clearPermissions } = useContext(UserPermissionContext);
    const { user } = useAuth0();
    useFailedActionSnackbar('submit', 'user information', isErrorCreatingUser, resetCreateUser);

    const {
        formRecord: formCollectedData,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
    } = useStandardValidatedFormManager(INITIAL_COLLECTED_DATA, USER_VALIDATION_CONFIG);

    const handleSubmitClicked = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            createUser({
                ...DEFAULT_USER,
                firstName: formCollectedData.firstName,
                lastName: formCollectedData.lastName,
                email: user?.email ?? '',
                auth0UserId: user?.sub ?? '',
            })
                .unwrap()
                .then(() => {
                    clearPermissions();
                    onUserAccountCreated();
                });
        }
    }, [validateForm, formCollectedData, createUser, onUserAccountCreated, user, clearPermissions]);

    return {
        formCollectedData,
        fieldErrors,
        handleTextFieldChange,
        handleSubmitClicked,
        isLoading: isCreatingUser,
    };
}
