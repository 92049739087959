import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import { ApplicationTopBar } from './ApplicationTopBar';

export const ApplicationTopBarContainer: FC = () => {
    const { isAuthenticated, error } = useAuth0();

    if (!isAuthenticated) {
        return null;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    
    return <ApplicationTopBar />;
};
