import { Check, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Icon, LinearProgress, Paper, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { PageHeader, PageSubHeader } from '../../Components/Global';
import { JournalDto, JournalQuestionDto } from '../../dtos';
import { standardDateFormat } from '../../util';
import { QuestionInput } from './QuestionInput';
import { useJournalFormManager } from './useJournalFormManager';
import { InfoVideoIcon } from '../../Components/CustomIcons';
import { VideoDialog } from './VideoDialog';
import { QuestionSetDto } from '../../dtos/generated/QuestionSetDto';
import { IconButtonRound } from '../../Components/Global/IconButtonRound';

interface ICompleteJournalFormProps {
    save: (values: JournalDto) => void;
    initValues: JournalDto;
}

export const CompleteJournalForm: FC<ICompleteJournalFormProps> = ({ save, initValues }) => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const { formJournal, validateForm, handleQuestionChange } = useJournalFormManager(initValues);
    const [open, setOpen] = useState(false);
    const MIN = 0;
    const MAX = formJournal.questions.length - 1;
    const normalize = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formJournal);
        }
    }, [validateForm, formJournal, save]);

    const currentQuestion = useMemo(() => {
        if (questionIndex >= 0 && formJournal.questions.length > questionIndex) {
            return formJournal.questions[questionIndex];
        }
    }, [formJournal.questions, questionIndex]);

    const isFirstQuestion = useMemo(() => questionIndex === 0, [questionIndex]);
    const isLastQuestion = useMemo(() => formJournal.questions.length === questionIndex + 1, [questionIndex, formJournal.questions]);
    const handleNextClicked = useCallback(() => !isLastQuestion && setQuestionIndex((idx) => idx + 1), [isLastQuestion]);
    const handleBackClicked = useCallback(() => !isFirstQuestion && setQuestionIndex((idx) => idx - 1), [isFirstQuestion]);

    const handleVideoDialog = () => {
        setOpen(!open);
    };

    const getVideoForQuestion = (question?: JournalQuestionDto, questionSet?: QuestionSetDto) => {
        switch (question?.questionTypeName) {
            case 'Personal':
                return question?.typeName === 'Do' ? questionSet?.personalVideoDo : questionSet?.personalVideoKnow;
            case 'Space':
                return question?.typeName === 'Do' ? questionSet?.spaceVideoDo : questionSet?.spaceVideoKnow;
            case 'People':
                return question?.typeName === 'Do' ? questionSet?.peopleVideoDo : questionSet?.peopleVideoKnow;
            default:
                return null;
        }
    };

    return (
        <Box display='flex' flexDirection='column'>
            <PageHeader icon={<Icon>{formJournal.space?.icon}</Icon>} title={formJournal.space?.name ?? ''} endText={standardDateFormat(formJournal.date)} />
            <Box display='flex' width='100%'>
                <PageSubHeader text={`${currentQuestion?.questionTypeName} - ${currentQuestion?.typeName} `}></PageSubHeader>

                <VideoDialog open={open} onClose={handleVideoDialog} video={getVideoForQuestion(currentQuestion, formJournal.questionSet) || undefined} />
            </Box>
            <Paper sx={{ py: 1, display: 'flex', flexDirection: 'column', gap: 1, m: 1 }}>
                {!currentQuestion && (
                    <Typography textAlign='center' fontStyle='italic' py={2}>
                        No Step Data
                    </Typography>
                )}
                {currentQuestion && (
                    <>
                        <QuestionInput
                            question={currentQuestion}
                            onChange={handleQuestionChange}
                            icon={<IconButtonRound onClick={handleVideoDialog} icon={<InfoVideoIcon />} />}
                        />
                    </>
                )}
            </Paper>
            <Box display='flex' gap={1} px={1} flexDirection='column'>
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                    <LinearProgress sx={{ width: '100%' }} color='primary' value={normalize(questionIndex)} variant='determinate' />
                    {questionIndex + 1}/{formJournal.questions.length}
                </Box>
                <Box display='flex' gap={1} flexDirection='row'>
                    {!isFirstQuestion && (
                        <Button fullWidth startIcon={<KeyboardArrowLeft />} variant='outlined' size='large' onClick={handleBackClicked}>
                            Back
                        </Button>
                    )}
                    {!isLastQuestion && (
                        <Button fullWidth endIcon={<KeyboardArrowRight />} variant='contained' size='large' onClick={handleNextClicked}>
                            Next
                        </Button>
                    )}
                    {isLastQuestion && (
                        <Button fullWidth startIcon={<Check />} variant='contained' size='large' onClick={handleSave}>
                            Done
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
