import { Avatar, IconButton } from '@mui/material';
import { FC } from 'react';

export interface IIconButtonRoundProps {
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
    icon: any;
    size?: number;
}

export const IconButtonRound: FC<IIconButtonRoundProps> = ({ onClick, icon, size = 32 }) => {
    return (
        <IconButton sx={{ p: 0 }}>
            <Avatar
                sx={{
                    bgcolor: 'primary.main', height: size, width: size, alignSelf: 'center'
                }}
                onClick={onClick}
            >
                {icon}
            </Avatar>
        </IconButton>
    );
};
