import { FC } from 'react';
import { VideoListViewDto } from '../../dtos';
import { Box, Paper, Typography } from '@mui/material';

export interface IVideoCardProps {
    video: VideoListViewDto;
    hideTitle?: boolean;
    isPaper?: boolean;
}

const VideoCardContent: FC<IVideoCardProps> = ({ video, hideTitle = false }) => {
    return (
        <>
            {!hideTitle && <Typography fontWeight='bold'>{video.title}</Typography>}
            <Typography>{video.description}</Typography>
            <Box width={'100%'} maxWidth={'400px'}>
                <div
                    style={{
                        overflow: 'hidden',
                        paddingBottom: '56.25%' /* 16:9 Aspect Ratio */,
                        position: 'relative',
                        height: 0,
                    }}>
                    <iframe
                        src={video.url}
                        title={video.title}
                        style={{
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            border: '0px',
                        }}
                        allowFullScreen></iframe>
                </div>
            </Box>
        </>
    );
};

export const VideoCard: FC<IVideoCardProps> = ({ video, hideTitle = false, isPaper = true }) => {
    if (isPaper) {
        return (
            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1, width: '100%', alignItems: 'center' }}>
                <VideoCardContent video={video} hideTitle={hideTitle} />
            </Paper>
        );
    } else {
        return (
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1, width: '100%', alignItems: 'center' }}>
                <VideoCardContent video={video} hideTitle={hideTitle} />
            </Box>
        );
    }
};
