import { serverApi } from '../api';
import _ from 'lodash';
import {
	ActDto,
	ActGeneratorDto,
	JournalDto,
	PersonDto,
	SpaceDto,
	UserDto,
	UserGroupDto,
	CreateStoryRequestDto,
	RecommendVersesRequestDto,
	VideoDto,
	QueryResultsDto,
	ActListViewDto,
	BulkUpdateResultDto,
	GuidedReflectionDto,
	JournalListViewDto,
	PersonListViewDto,
	SpaceListViewDto,
	UserListViewDto,
	UserRoleDto,
	UserStatus,
	UserGroupListViewDto,
	UserGroupUserListViewDto,
	GroupInvitationDetailsDto,
	UserGroupUserSharedActsSummaryListItemDto,
	GroupSharingDto,
	UserStoryListViewDto,
	UserStoryDto,
	VerseRecommendationDto,
	VideoListViewDto,
	TagDto
} from '../../dtos';

import {
	GetActQueryParameters,
	GetActByIdQueryParameters,
	ArchiveActByIdQueryParameters,
	RecoverActByIdQueryParameters,
	RecoverActCompleteQueryParameters,
	RecoverActUncompleteQueryParameters,
	GetJournalQueryParameters,
	GetJournalByIdQueryParameters,
	ArchiveJournalByIdQueryParameters,
	RecoverJournalByIdQueryParameters,
	GetPersonQueryParameters,
	GetPersonByIdQueryParameters,
	ArchivePersonByIdQueryParameters,
	RecoverPersonByIdQueryParameters,
	GetSpaceQueryParameters,
	GetSpaceByIdQueryParameters,
	ArchiveSpaceByIdQueryParameters,
	RecoverSpaceByIdQueryParameters,
	GetUserQueryParameters,
	GetUserByIdQueryParameters,
	ArchiveUserByIdQueryParameters,
	RecoverUserByIdQueryParameters,
	GetUserGroupsQueryParameters,
	ArchiveUserGroupsByIdQueryParameters,
	GetUserGroupsByIdQueryParameters,
	RecoverUserGroupsByIdQueryParameters,
	GetUserGroupsMembersQueryParameters,
	RecoverUserGroupsMembersAcceptQueryParameters,
	RecoverUserGroupsMembersDenyQueryParameters,
	RecoverUserGroupsMembersRemoveQueryParameters,
	RecoverUserGroupsLeaveQueryParameters,
	GetUserGroupsInvitationByIdQueryParameters,
	CreateUserGroupsInvitationByIdQueryParameters,
	GetUserGroupsSharedActSummariesQueryParameters,
	GetUserGroupsSharedActsQueryParameters,
	GetUserGroupsSharedWithByIdQueryParameters,
	CreateUserGroupsShareActByIdQueryParameters,
	ArchiveUserGroupsShareActByIdQueryParameters,
	GetUserStoryQueryParameters,
	GetUserStoryByIdQueryParameters,
	GetVideoQueryParameters,
	ArchiveVideoByIdQueryParameters,
	GetVideoByIdQueryParameters,
	RecoverVideoByIdQueryParameters
} from './generatedParameterTypes';

const buildQueryString = (obj: any, routeParams: string[] = []) => {
	var args = _.omitBy(_.clone(obj), _.isNil);
	routeParams.forEach(rp => delete args[rp]);
	return new URLSearchParams(args).toString();
}

const generatedApi = serverApi.injectEndpoints({
	endpoints: (builder) => ({
		getAct: builder.query<QueryResultsDto<ActListViewDto>, GetActQueryParameters>({
			query: (args) => ({
				url: `/api/Act?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Act']
		}),
		createAct: builder.mutation<ActDto, ActDto>({
			query: (args) => ({
				url: `/api/Act`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		updateAct: builder.mutation<void, ActDto>({
			query: (args) => ({
				url: `/api/Act`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		getActById: builder.query<ActDto, GetActByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Act/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Act']
		}),
		archiveActById: builder.mutation<void, ArchiveActByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Act/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Act']
		}),
		recoverActById: builder.mutation<void, RecoverActByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Act/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Act']
		}),
		actBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Act/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		actBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Act/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		createActGenerate: builder.mutation<ActListViewDto[], ActGeneratorDto>({
			query: (args) => ({
				url: `/api/Act/generate`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		recoverActComplete: builder.mutation<void, RecoverActCompleteQueryParameters>({
			query: (args) => ({
				url: `/api/Act/${args.actId}/complete`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Act']
		}),
		recoverActBulkCompleted: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Act/bulkCompleted`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Act']
		}),
		recoverActUncomplete: builder.mutation<void, RecoverActUncompleteQueryParameters>({
			query: (args) => ({
				url: `/api/Act/${args.actId}/uncomplete`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Act']
		}),
		getActTotalCount: builder.query<number, void>({
			query: () => ({
				url: `/api/Act/totalCount`,
				method: 'GET',
			}),
			providesTags: ['Act']
		}),
		getGuidedReflectionIsReadyForReflection: builder.query<boolean, void>({
			query: () => ({
				url: `/api/GuidedReflection/isReadyForReflection`,
				method: 'GET',
			}),
			providesTags: ['GuidedReflection']
		}),
		getGuidedReflectionCurrentReflection: builder.query<GuidedReflectionDto, void>({
			query: () => ({
				url: `/api/GuidedReflection/currentReflection`,
				method: 'GET',
			}),
			providesTags: ['GuidedReflection']
		}),
		guidedReflectionArchiveCompletedActs: builder.mutation<void, void>({
			query: () => ({
				url: `/api/GuidedReflection/archiveCompletedActs`,
				method: 'DELETE',
			}),
			invalidatesTags: ['GuidedReflection','Video','Space','Person']
		}),
		getJournal: builder.query<QueryResultsDto<JournalListViewDto>, GetJournalQueryParameters>({
			query: (args) => ({
				url: `/api/Journal?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Journal']
		}),
		createJournal: builder.mutation<JournalDto, JournalDto>({
			query: (args) => ({
				url: `/api/Journal`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		updateJournal: builder.mutation<void, JournalDto>({
			query: (args) => ({
				url: `/api/Journal`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		getJournalById: builder.query<JournalDto, GetJournalByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Journal/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Journal']
		}),
		archiveJournalById: builder.mutation<void, ArchiveJournalByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Journal/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		recoverJournalById: builder.mutation<void, RecoverJournalByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Journal/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		journalBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Journal/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		journalBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Journal/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Journal','GuidedReflection','Space','Person']
		}),
		getMetricsTotalActs: builder.query<number, void>({
			query: () => ({
				url: `/api/Metrics/total/acts`,
				method: 'GET',
			}),
			providesTags: ['Metrics']
		}),
		getMetricsTotalJournalQuestions: builder.query<number, void>({
			query: () => ({
				url: `/api/Metrics/total/journal-questions`,
				method: 'GET',
			}),
			providesTags: ['Metrics']
		}),
		getMetricsTotalUsers: builder.query<number, void>({
			query: () => ({
				url: `/api/Metrics/total/users`,
				method: 'GET',
			}),
			providesTags: ['Metrics']
		}),
		getMetricsTotalSpaces: builder.query<number, void>({
			query: () => ({
				url: `/api/Metrics/total/spaces`,
				method: 'GET',
			}),
			providesTags: ['Metrics']
		}),
		getMetricsTotalPeople: builder.query<number, void>({
			query: () => ({
				url: `/api/Metrics/total/people`,
				method: 'GET',
			}),
			providesTags: ['Metrics']
		}),
		getPerson: builder.query<QueryResultsDto<PersonListViewDto>, GetPersonQueryParameters>({
			query: (args) => ({
				url: `/api/Person?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Person']
		}),
		createPerson: builder.mutation<PersonDto, PersonDto>({
			query: (args) => ({
				url: `/api/Person`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Person']
		}),
		updatePerson: builder.mutation<void, PersonDto>({
			query: (args) => ({
				url: `/api/Person`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Person']
		}),
		getPersonById: builder.query<PersonDto, GetPersonByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Person/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Person']
		}),
		archivePersonById: builder.mutation<void, ArchivePersonByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Person/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Person']
		}),
		recoverPersonById: builder.mutation<void, RecoverPersonByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Person/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Person']
		}),
		personBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Person/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Person']
		}),
		personBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Person/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Person']
		}),
		getSpace: builder.query<QueryResultsDto<SpaceListViewDto>, GetSpaceQueryParameters>({
			query: (args) => ({
				url: `/api/Space?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Space']
		}),
		createSpace: builder.mutation<SpaceDto, SpaceDto>({
			query: (args) => ({
				url: `/api/Space`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Space','Person']
		}),
		updateSpace: builder.mutation<void, SpaceDto>({
			query: (args) => ({
				url: `/api/Space`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Space','Person']
		}),
		getSpaceById: builder.query<SpaceDto, GetSpaceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Space/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Space']
		}),
		archiveSpaceById: builder.mutation<void, ArchiveSpaceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Space/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Space','Person']
		}),
		recoverSpaceById: builder.mutation<void, RecoverSpaceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Space/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Space','Person']
		}),
		spaceBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Space/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Space','Person']
		}),
		spaceBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Space/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Space','Person']
		}),
		getUser: builder.query<QueryResultsDto<UserListViewDto>, GetUserQueryParameters>({
			query: (args) => ({
				url: `/api/User?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		createUser: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		updateUser: builder.mutation<void, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserById: builder.query<UserDto, GetUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		archiveUserById: builder.mutation<void, ArchiveUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		recoverUserById: builder.mutation<void, RecoverUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserMe: builder.query<UserDto, void>({
			query: () => ({
				url: `/api/User/me`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		updateUserMe: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User/me`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		archiveUserMe: builder.mutation<void, void>({
			query: () => ({
				url: `/api/User/me`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		getUserPermissions: builder.query<string[], void>({
			query: () => ({
				url: `/api/User/permissions`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserRoles: builder.query<UserRoleDto[], void>({
			query: () => ({
				url: `/api/User/roles`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		createUserProvision: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User/provision`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserStatus: builder.query<UserStatus, void>({
			query: () => ({
				url: `/api/User/status`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserGroups: builder.query<QueryResultsDto<UserGroupListViewDto>, GetUserGroupsQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		createUserGroups: builder.mutation<UserGroupDto, UserGroupDto>({
			query: (args) => ({
				url: `/api/UserGroups`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['UserGroups']
		}),
		updateUserGroups: builder.mutation<void, UserGroupDto>({
			query: (args) => ({
				url: `/api/UserGroups`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['UserGroups']
		}),
		archiveUserGroupsById: builder.mutation<void, ArchiveUserGroupsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserGroups']
		}),
		getUserGroupsById: builder.query<UserGroupDto, GetUserGroupsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		recoverUserGroupsById: builder.mutation<void, RecoverUserGroupsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserGroups']
		}),
		userGroupsBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/UserGroups/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['UserGroups']
		}),
		userGroupsBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/UserGroups/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['UserGroups']
		}),
		getUserGroupsMembers: builder.query<QueryResultsDto<UserGroupUserListViewDto>, GetUserGroupsMembersQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/members?${buildQueryString(args, ["groupId"])}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		recoverUserGroupsMembersAccept: builder.mutation<void, RecoverUserGroupsMembersAcceptQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/members/${args.userId}/accept`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserGroups']
		}),
		recoverUserGroupsMembersDeny: builder.mutation<void, RecoverUserGroupsMembersDenyQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/members/${args.userId}/deny`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserGroups']
		}),
		recoverUserGroupsMembersRemove: builder.mutation<void, RecoverUserGroupsMembersRemoveQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/members/${args.userId}/remove`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserGroups']
		}),
		recoverUserGroupsLeave: builder.mutation<void, RecoverUserGroupsLeaveQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/leave`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserGroups']
		}),
		getUserGroupsInvitationById: builder.query<GroupInvitationDetailsDto, GetUserGroupsInvitationByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/invitation/${args.inviteCode}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		createUserGroupsInvitationById: builder.mutation<void, CreateUserGroupsInvitationByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/invitation/${args.inviteCode}`,
				method: 'POST',
			}),
			invalidatesTags: ['UserGroups']
		}),
		getUserGroupsSharedActSummaries: builder.query<UserGroupUserSharedActsSummaryListItemDto[], GetUserGroupsSharedActSummariesQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/sharedActSummaries`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		getUserGroupsSharedActs: builder.query<QueryResultsDto<ActListViewDto>, GetUserGroupsSharedActsQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/sharedActs?${buildQueryString(args, ["groupId"])}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		getUserGroupsSharedWithById: builder.query<QueryResultsDto<GroupSharingDto>, GetUserGroupsSharedWithByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/sharedWith/${args.actId}?${buildQueryString(args, ["actId"])}`,
				method: 'GET',
			}),
			providesTags: ['UserGroups','Act']
		}),
		createUserGroupsShareActById: builder.mutation<void, CreateUserGroupsShareActByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/shareAct/${args.actId}`,
				method: 'POST',
			}),
			invalidatesTags: ['UserGroups']
		}),
		archiveUserGroupsShareActById: builder.mutation<void, ArchiveUserGroupsShareActByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserGroups/${args.groupId}/shareAct/${args.actId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserGroups']
		}),
		getUserStory: builder.query<QueryResultsDto<UserStoryListViewDto>, GetUserStoryQueryParameters>({
			query: (args) => ({
				url: `/api/UserStory?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['UserStory']
		}),
		createUserStory: builder.mutation<UserStoryDto, CreateStoryRequestDto>({
			query: (args) => ({
				url: `/api/UserStory`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['UserStory']
		}),
		getUserStoryById: builder.query<UserStoryDto, GetUserStoryByIdQueryParameters>({
			query: (args) => ({
				url: `/api/UserStory/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['UserStory']
		}),
		createVersesRecommend: builder.mutation<VerseRecommendationDto[], RecommendVersesRequestDto>({
			query: (args) => ({
				url: `/api/Verses/recommend`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Verses']
		}),
		getVideo: builder.query<QueryResultsDto<VideoListViewDto>, GetVideoQueryParameters>({
			query: (args) => ({
				url: `/api/Video?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Video']
		}),
		createVideo: builder.mutation<VideoDto, VideoDto>({
			query: (args) => ({
				url: `/api/Video`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Video']
		}),
		updateVideo: builder.mutation<void, VideoDto>({
			query: (args) => ({
				url: `/api/Video`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Video']
		}),
		archiveVideoById: builder.mutation<void, ArchiveVideoByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Video/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Video']
		}),
		getVideoById: builder.query<VideoDto, GetVideoByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Video/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Video']
		}),
		recoverVideoById: builder.mutation<void, RecoverVideoByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Video/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Video']
		}),
		videoBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Video/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Video']
		}),
		videoBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Video/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Video']
		}),
		getVideoTags: builder.query<TagDto[], void>({
			query: () => ({
				url: `/api/Video/tags`,
				method: 'GET',
			}),
			providesTags: ['Video']
		}),
	})
});

export const {
	useGetActQuery,
	useLazyGetActQuery,
	useCreateActMutation,
	useUpdateActMutation,
	useGetActByIdQuery,
	useLazyGetActByIdQuery,
	useArchiveActByIdMutation,
	useRecoverActByIdMutation,
	useActBulkArchiveMutation,
	useActBulkUnarchiveMutation,
	useCreateActGenerateMutation,
	useRecoverActCompleteMutation,
	useRecoverActBulkCompletedMutation,
	useRecoverActUncompleteMutation,
	useGetActTotalCountQuery,
	useLazyGetActTotalCountQuery,
	useGetGuidedReflectionIsReadyForReflectionQuery,
	useLazyGetGuidedReflectionIsReadyForReflectionQuery,
	useGetGuidedReflectionCurrentReflectionQuery,
	useLazyGetGuidedReflectionCurrentReflectionQuery,
	useGuidedReflectionArchiveCompletedActsMutation,
	useGetJournalQuery,
	useLazyGetJournalQuery,
	useCreateJournalMutation,
	useUpdateJournalMutation,
	useGetJournalByIdQuery,
	useLazyGetJournalByIdQuery,
	useArchiveJournalByIdMutation,
	useRecoverJournalByIdMutation,
	useJournalBulkArchiveMutation,
	useJournalBulkUnarchiveMutation,
	useGetMetricsTotalActsQuery,
	useLazyGetMetricsTotalActsQuery,
	useGetMetricsTotalJournalQuestionsQuery,
	useLazyGetMetricsTotalJournalQuestionsQuery,
	useGetMetricsTotalUsersQuery,
	useLazyGetMetricsTotalUsersQuery,
	useGetMetricsTotalSpacesQuery,
	useLazyGetMetricsTotalSpacesQuery,
	useGetMetricsTotalPeopleQuery,
	useLazyGetMetricsTotalPeopleQuery,
	useGetPersonQuery,
	useLazyGetPersonQuery,
	useCreatePersonMutation,
	useUpdatePersonMutation,
	useGetPersonByIdQuery,
	useLazyGetPersonByIdQuery,
	useArchivePersonByIdMutation,
	useRecoverPersonByIdMutation,
	usePersonBulkArchiveMutation,
	usePersonBulkUnarchiveMutation,
	useGetSpaceQuery,
	useLazyGetSpaceQuery,
	useCreateSpaceMutation,
	useUpdateSpaceMutation,
	useGetSpaceByIdQuery,
	useLazyGetSpaceByIdQuery,
	useArchiveSpaceByIdMutation,
	useRecoverSpaceByIdMutation,
	useSpaceBulkArchiveMutation,
	useSpaceBulkUnarchiveMutation,
	useGetUserQuery,
	useLazyGetUserQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useArchiveUserByIdMutation,
	useRecoverUserByIdMutation,
	useUserBulkArchiveMutation,
	useUserBulkUnarchiveMutation,
	useGetUserMeQuery,
	useLazyGetUserMeQuery,
	useUpdateUserMeMutation,
	useArchiveUserMeMutation,
	useGetUserPermissionsQuery,
	useLazyGetUserPermissionsQuery,
	useGetUserRolesQuery,
	useLazyGetUserRolesQuery,
	useCreateUserProvisionMutation,
	useGetUserStatusQuery,
	useLazyGetUserStatusQuery,
	useGetUserGroupsQuery,
	useLazyGetUserGroupsQuery,
	useCreateUserGroupsMutation,
	useUpdateUserGroupsMutation,
	useArchiveUserGroupsByIdMutation,
	useGetUserGroupsByIdQuery,
	useLazyGetUserGroupsByIdQuery,
	useRecoverUserGroupsByIdMutation,
	useUserGroupsBulkArchiveMutation,
	useUserGroupsBulkUnarchiveMutation,
	useGetUserGroupsMembersQuery,
	useLazyGetUserGroupsMembersQuery,
	useRecoverUserGroupsMembersAcceptMutation,
	useRecoverUserGroupsMembersDenyMutation,
	useRecoverUserGroupsMembersRemoveMutation,
	useRecoverUserGroupsLeaveMutation,
	useGetUserGroupsInvitationByIdQuery,
	useLazyGetUserGroupsInvitationByIdQuery,
	useCreateUserGroupsInvitationByIdMutation,
	useGetUserGroupsSharedActSummariesQuery,
	useLazyGetUserGroupsSharedActSummariesQuery,
	useGetUserGroupsSharedActsQuery,
	useLazyGetUserGroupsSharedActsQuery,
	useGetUserGroupsSharedWithByIdQuery,
	useLazyGetUserGroupsSharedWithByIdQuery,
	useCreateUserGroupsShareActByIdMutation,
	useArchiveUserGroupsShareActByIdMutation,
	useGetUserStoryQuery,
	useLazyGetUserStoryQuery,
	useCreateUserStoryMutation,
	useGetUserStoryByIdQuery,
	useLazyGetUserStoryByIdQuery,
	useCreateVersesRecommendMutation,
	useGetVideoQuery,
	useLazyGetVideoQuery,
	useCreateVideoMutation,
	useUpdateVideoMutation,
	useArchiveVideoByIdMutation,
	useGetVideoByIdQuery,
	useLazyGetVideoByIdQuery,
	useRecoverVideoByIdMutation,
	useVideoBulkArchiveMutation,
	useVideoBulkUnarchiveMutation,
	useGetVideoTagsQuery,
	useLazyGetVideoTagsQuery
} = generatedApi;