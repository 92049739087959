import { ChevronRight } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { VideoDto } from '../../dtos';
import { VideoCard } from '../Learn/VideoCard';

export interface IReflectionStepVideoProps {
    video: VideoDto;
    onNextClicked: () => void;
};

export const ReflectionStepVideo: FC<IReflectionStepVideoProps> = ({ video, onNextClicked }) => {
    return (
        <Box display='flex' flexDirection='column' gap={1} p={1}>
            <VideoCard video={video} />
            <Button variant='contained' endIcon={<ChevronRight />} onClick={onNextClicked}>Next</Button>
        </Box>
    );
};