import { AutoAwesome } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useCallback } from 'react';
import { PageHeader } from '../../Components/Global';
import { UserStoryDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { standardDateFormat } from '../../util';
import { CreateStoryForm } from './CreateStoryForm';

export const CreateStoryView: FC = () => {
    const goTo = useGoTo();
    
    const handleStoryCreated = useCallback((createdStory: UserStoryDto) => {
        goTo.StoryDetails(createdStory.id);
    }, [goTo]);

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <PageHeader icon={<AutoAwesome />} title='New Story' onActionButtonClicked={goTo.StoryHome} endText={standardDateFormat(new Date())} />
            <CreateStoryForm onStoryCreated={handleStoryCreated} />
        </Box>
    );
};
