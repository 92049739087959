import { Cancel } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { ShareToGroupIcon } from '../../Components/CustomIcons';
import { GroupSharingDto } from '../../dtos';
import { useGroupListViewItemManager } from './useGroupListViewItemManager';

const CUSTOM_BUTTON_PROPS = {
    color: 'white',
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: 0.3,
};

interface IGroupListViewItemProps {
    group: GroupSharingDto;
    actId: string;
    onChange?: (act: GroupSharingDto) => void;
}

export const GroupListViewItem: FC<IGroupListViewItemProps> = ({ group, actId, onChange }) => {
    const { handleShareActWithGroup, handleUnShareActWithGroup, isLoading } = useGroupListViewItemManager(group, actId, onChange);

    return (
        <Paper sx={{ width: '100%' }}>
            <Box mt='auto' mb='auto' display='flex' alignItems='center' width='100%' gap={1}>
                {group.isActSharedWithGroup && (
                    <IconButton color='primary' onClick={handleUnShareActWithGroup} disabled={isLoading}>
                        <Box {...CUSTOM_BUTTON_PROPS} bgcolor={isLoading ? 'rgba(0, 0, 0, 0.26)' : 'primary.main'}>
                            <Cancel />
                        </Box>
                    </IconButton>
                )}
                {!group.isActSharedWithGroup && (
                    <IconButton color='primary' onClick={handleShareActWithGroup} disabled={isLoading}>
                        <Box {...CUSTOM_BUTTON_PROPS} bgcolor={isLoading ? 'rgba(0, 0, 0, 0.26)' : 'primary.main'} fontSize={10}>
                            <ShareToGroupIcon height={20} width={20} />
                        </Box>
                    </IconButton>
                )}
                <Box display='flex' flexDirection='column' overflow='hidden'>
                    <Typography>{group.name}</Typography>
                </Box>
            </Box>
        </Paper>
    );
};
