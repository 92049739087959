import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupsIcon from '@mui/icons-material/Groups';
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useContext, useMemo } from 'react';
import { SpaceDto } from '../../dtos';
import { useCreateSpaceMutation, useGetUserStatusQuery } from '../../store/generated/generatedApi';
import { RouteLocationContext } from './OnboardingView';
interface ICongratulationsPageProps {
    handleOnClickBack?: () => void;
    formSpace: SpaceDto;
}

export const CongratulationsPage: FC<ICongratulationsPageProps> = ({ handleOnClickBack, formSpace }) => {
    const [createSpace, { isLoading: isCreatingSpace }] = useCreateSpaceMutation();
    const { refetch, isLoading: isReloadingUserStatus } = useGetUserStatusQuery();

    const { setRouteLocation } = useContext(RouteLocationContext);

    const handleOnClick = useCallback((isPraying: boolean) => {
        createSpace(formSpace)
            .then(() => {
                setRouteLocation(isPraying ? 'praying' : 'groupCreate');
            })
            .then(refetch);
    }, [createSpace, setRouteLocation, refetch, formSpace]);

    const isLoading = useMemo(() => isCreatingSpace || isReloadingUserStatus, [isCreatingSpace, isReloadingUserStatus]);

    return (
        <Box display='flex' flexDirection='column' rowGap={1} p={1}>
            <Paper sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography> Congrats, you've completed the Praying on Offense Pathway!</Typography>
            </Paper>
            <Button onClick={handleOnClickBack} startIcon={<ChevronLeftIcon />} variant='outlined' disabled={isLoading}>
                Back
            </Button>
            <Button onClick={() => handleOnClick(true)} startIcon={<AddCircleIcon />} variant='contained' disabled={isLoading}>
                Start Praying
            </Button>
            <Button onClick={() => handleOnClick(false)} startIcon={<GroupsIcon />} variant='contained' disabled={isLoading}>
                Create A Group
            </Button>
        </Box>
    );
};
