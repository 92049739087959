import { Box, Button, Icon, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { LoadingIndicator, LoadingBackdrop } from '../../Components/CoreLib/library';
import { UserStoryDto } from '../../dtos';
import { useCreateStoryForm } from './useCreateStoryForm';

export interface ICreateStoryFormProps {
    onStoryCreated: (story: UserStoryDto) => void;
    onlyRecentlyMentioned?: boolean;
};

export const CreateStoryForm: FC<ICreateStoryFormProps> = ({
    onStoryCreated,
    onlyRecentlyMentioned
}) => {

    const {
        people,
        spaces,
        isLoadingData,
        loadingStates,
        handleCreatePersonalStoryClicked,
        handleCreatePersonStoryClicked,
        handleCreateSpaceStoryClicked
    } = useCreateStoryForm(onStoryCreated, onlyRecentlyMentioned)

    return (
        <>
            <Paper sx={{ mx: 1 }}>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={1} p={1}>
                    <Button variant='contained' onClick={handleCreatePersonalStoryClicked}>
                        My Personal Story
                    </Button>
                    <Typography variant='h5' mt={1}>
                        People
                    </Typography>
                    {isLoadingData && <LoadingIndicator />}
                    {people?.totalQueryResults === 0 && <Typography variant='caption'>No people found</Typography>}
                    {people && (
                        <Box display='flex' gap={1} flexWrap='wrap' justifyContent='center'>
                            {people.pageResults.map((person) => (
                                <Button key={person.id} variant='contained' onClick={handleCreatePersonStoryClicked(person.id)}>
                                    {person.name}
                                </Button>
                            ))}
                        </Box>
                    )}
                    <Typography variant='h5' mt={1} >
                        Spaces
                    </Typography>
                    {isLoadingData && <LoadingIndicator />}
                    {spaces?.totalQueryResults === 0 && <Typography variant='caption'>No Spaces found</Typography>}
                    {spaces && (
                        <Box display='flex' gap={1} flexWrap='wrap' justifyContent='center'>
                            {spaces.pageResults.map((space) => (
                                <Button
                                    key={space.id}
                                    variant='contained'
                                    startIcon={<Icon>{space.icon}</Icon>}
                                    onClick={handleCreateSpaceStoryClicked(space.id)}>
                                    {space.name}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
            </Paper>
            <LoadingBackdrop loadingOptions={loadingStates} />
        </>
    );
};