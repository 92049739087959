import { Box } from '@mui/system';
import { useCallback, useMemo, useState } from 'react';
import { StandardSingleSelectTag } from '../../Components/CommonInputs/StandardSingleSelectTag';
import { InfoVideoIcon } from '../../Components/CustomIcons/InfoVideoIcon';
import { InfiniteLoadingListView, PageHeader } from '../../Components/Global';
import { PageSubHeaderComponentVariant } from '../../Components/Global/PageSubHeaderComponentVariant';
import { TagDto, VideoListViewDto } from '../../dtos';
import { useLazyGetVideoQuery } from '../../store/generated/generatedApi';
import { VideoCard } from './VideoCard';
import { Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LearnHome = () => {
    const [filterTag, setFilerTag] = useState<TagDto | null>(null);
    const renderRowItem = useCallback((item: VideoListViewDto) => <VideoCard video={item} />, []);
    const isLandingVideoShowing = useMemo(() => filterTag === null, [filterTag]);

    const handleTagChange = (selectedTag: TagDto | null) => {
        setFilerTag(selectedTag);
    };

    const handleActionButtonClick = () => {
        handleTagChange(null);
    };

    return (
        <Box display='flex' flexDirection='column' height='100%'>
            <PageHeader
                icon={<InfoVideoIcon />}
                title='Learn'
                actionButtonIcon={!isLandingVideoShowing ? <CloseIcon fontSize='large' /> : undefined}
                onActionButtonClicked={!isLandingVideoShowing ? handleActionButtonClick : undefined}
            />
            {isLandingVideoShowing && (
                <Paper sx={{ pb: 1 }}>
                    <VideoCard
                        isPaper={false}
                        video={{
                            title: 'What is Praying on Offense?',
                            url: 'https://www.youtube.com/embed/f1hGG-sqpzw?si=E39wGpoG0L15JXgk',
                            description: '',
                            id: '',
                            isActive: false,
                            createdOn: new Date(),
                        }}
                    />
                    <Box px={1}>
                        <StandardSingleSelectTag selectedValue={filterTag} handleChange={handleTagChange} label='Find Videos by Category' />
                    </Box>
                </Paper>
            )}

            {!isLandingVideoShowing && (
                <>
                    <PageSubHeaderComponentVariant
                        child={<StandardSingleSelectTag selectedValue={filterTag} handleChange={handleTagChange} label='Find Videos by Category' />}
                    />
                    <InfiniteLoadingListView
                        key={filterTag?.id ?? null}
                        dataLoadingQuery={useLazyGetVideoQuery}
                        renderRowDetails={renderRowItem}
                        sortKey='CREATED_ON'
                        sortAsc={false}
                        additionalQueryParameters={{ tagId: filterTag?.id ?? null }}
                    />
                </>
            )}
        </Box>
    );
};

export default LearnHome;
