import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "SCOPE profile email offline_access",
            }}
            useRefreshTokens={true}
            useRefreshTokensFallback={false}
            cacheLocation='localstorage'
        >
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </LocalizationProvider>
            </BrowserRouter>
        </Auth0Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
