import { FC, PropsWithChildren, useEffect } from 'react';
import { LoadingIndicator } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useGetUserStatusQuery } from '../../store/generated/generatedApi';
import { UserStatus } from '../../dtos';

export const OnboardCheckingWrapper: FC<PropsWithChildren> = ({ children }) => {
    const goTo = useGoTo();
    const { data: userStatus, isLoading: isUserStatusLoading } = useGetUserStatusQuery();

    useEffect(() => {
        if (userStatus !== undefined && userStatus !== UserStatus.Onboard) {
            goTo.Onboarding();
        }
    }, [userStatus, goTo]);

    if (isUserStatusLoading) {
        return <LoadingIndicator />;
    }

    return children;
};
