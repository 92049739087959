import { FC, useEffect, useState } from 'react';
import { OnboardingStepper } from './OnboardingStepper';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useGetUserStatusQuery } from '../../store/generated/generatedApi';
import { UserStatus } from '../../dtos';
import { CreateAccountStepper } from './CreateAccountStepper';
import React from 'react';

export const RouteLocationContext = React.createContext({ routeLocation: '', setRouteLocation: (location: string) => {} });

export const OnboardingView: FC = () => {
    const goTo = useGoTo();

    const [routeLocation, setRouteLocation] = useState('');

    const {
        data: currentUserStatus,
        isLoading: isCheckingIfCurrentUserIsProvisioned,
        error: provisioningCheckError,
        refetch: retryProvisioningCheck,
    } = useGetUserStatusQuery();

    useEffect(() => {
        if (currentUserStatus !== undefined && currentUserStatus === UserStatus.Onboard && routeLocation === 'groupCreate') {
            goTo.GroupCreate();
        } else if (currentUserStatus !== undefined && currentUserStatus === UserStatus.Onboard && routeLocation === 'praying') {
            goTo.JournalStart();
        }
    }, [currentUserStatus, goTo, routeLocation]);

    if (isCheckingIfCurrentUserIsProvisioned) {
        return <LoadingIndicator />;
    }

    if (provisioningCheckError) {
        return <ApiError onReloadClick={retryProvisioningCheck} />;
    }

    if (currentUserStatus === UserStatus.NotMade) {
        return <CreateAccountStepper />;
    }
    if (currentUserStatus === UserStatus.Made) {
        return (
            <RouteLocationContext.Provider value={{ routeLocation, setRouteLocation }}>
                <OnboardingStepper />
            </RouteLocationContext.Provider>
        );
    }

    return null;
};
