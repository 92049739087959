import { useCallback, useState } from 'react';
import { ActListViewDto, GuidedReflectionDto } from '../../dtos';

export function useReflectionStepper(reflection: GuidedReflectionDto | null, onCompletion: () => void) {
    const [activeStep, setActiveStep] = useState(reflection === null ? 1 : 0);

    const [completedActIds, setCompletedActIds] = useState<string[]>([]);
    const [archivedActIds, setArchivedActIds] = useState<string[]>([]);
    const [recommittedActIds, setRecommittedActIds] = useState<string[]>([]);
    const [currentActIds, setCurrentActIds] = useState<ActListViewDto[]>([]);

    const handleSubmitClicked = useCallback(() => {
        onCompletion();
    }, [onCompletion]);

    const handleArchiveActId = (id: string) => {
        setCurrentActIds(currentActIds.filter((act) => act.id !== id));
        setArchivedActIds([...archivedActIds, id]);
    };

    const handleCompleteActId = (id: string) => {
        setCurrentActIds(currentActIds.filter((act) => act.id !== id));
        setCompletedActIds([...completedActIds, id]);
    };

    const handleRecommitActId = (id: string) => {
        setCurrentActIds(currentActIds.filter((act) => act.id !== id));
        setRecommittedActIds([...recommittedActIds, id]);
    };

    return {
        activeStep,
        setActiveStep,
        handleSubmitClicked,
        handleArchiveActId,
        handleCompleteActId,
        handleRecommitActId,
        setCurrentActIds,
        currentActIds,
        completedActIds,
        archivedActIds,
        recommittedActIds,
    };
}
