import { Archive, ExpandMore, ThumbUp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Icon, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { ActListViewDto } from '../../dtos';
import { standardDateFormat } from '../../util';
import CheckIcon from '@mui/icons-material/Check';

interface IManageReflectionActListItemProps {
    acts: ActListViewDto[];
    onCompletedActsChange: (completedActs: string) => void;
    onArchivedActsChange: (archivedActs: string) => void;
    onRecommittedActsChange: (recommittedActs: string) => void;
}

export const ManageReflectionActListItem: FC<IManageReflectionActListItemProps> = ({
    acts,
    onCompletedActsChange,
    onArchivedActsChange,
    onRecommittedActsChange,
}) => {
    if (acts.length === 0)
        return (
            <Box width='100%' textAlign='center' p={1}>
                <Typography>You don’t have any Fearless Acts of Humility to recommit to. Feel free to continue.</Typography>
            </Box>
        );

    return (
        <>
            {acts.map((act: ActListViewDto) => (
                <Paper
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        gap: 1,
                        p: 1,
                        overflow: 'hidden',
                        ...(!act.isActive && {
                            bgcolor: '#dcdcdc',
                            color: 'rgba(0, 0, 0, .38)',
                        }),
                    }}>
                    <Typography sx={{ textDecoration: act.isComplete ? 'line-through' : undefined }}>{act.text}</Typography>
                    {act.completedOn && (
                        <Box display='flex' flexWrap='wrap' justifyContent='space-between' width='100%'>
                            <Chip label={`Completed: ${standardDateFormat(act.completedOn)}`} />
                        </Box>
                    )}
                    <Accordion
                        sx={{
                            width: '100%',
                            ...(!act.isActive && {
                                bgcolor: '#dcdcdc',
                                color: 'rgba(0, 0, 0, .38)',
                            }),
                        }}
                        elevation={0}
                        disableGutters>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 0 }}>
                            <Box display='flex' gap={1} flexWrap='wrap' width='100%' flexDirection='column' rowGap={1}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    startIcon={<Archive fontSize='large' />}
                                    onClick={() => onArchivedActsChange(act.id)}>
                                    Archive
                                </Button>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    startIcon={<CheckIcon fontSize='large' />}
                                    onClick={() => onCompletedActsChange(act.id)}>
                                    Complete
                                </Button>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    startIcon={<ThumbUp fontSize='large' />}
                                    onClick={() => onRecommittedActsChange(act.id)}>
                                    Recommit
                                </Button>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0, pt: 1 }}>
                            <Box display='flex' gap={1} flexWrap='wrap' width='100%' overflow='hidden'>
                                <Chip label={standardDateFormat(act.journalDate)} />
                                <Chip
                                    icon={
                                        <Icon
                                            sx={{
                                                color: 'white !important',
                                                bgcolor: '#bdbdbd',
                                                borderRadius: '50%',
                                                p: '2px',
                                                height: '24px',
                                                width: '24px',
                                                fontSize: '20px',
                                            }}>
                                            {act.spaceIcon}
                                        </Icon>
                                    }
                                    label={act.spaceName}
                                />
                                {act.involvedPeople.map((person, idx) => (
                                    <Chip key={`${idx}-${person}`} label={person} />
                                ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            ))}
        </>
    );
};
