import { DataTableColumn, MobileListView } from '../../Components/CoreLib/library';
import PeopleIcon from '@mui/icons-material/People';
import { useGetPersonQuery } from '../../store/generated/generatedApi';
import { PersonListViewDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { Box } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { PageHeader } from '../../Components/Global';

const COLUMNS: DataTableColumn<PersonListViewDto>[] = [{ key: 'name', label: 'Name', sortKey: 'NAME' }];

export const PeopleHomeView = () => {
    const goTo = useGoTo();

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <PageHeader icon={<PeopleIcon />} title='Manage People' />
            <Box px={1} display='flex' flexDirection='column' gap={1}>
                <MobileListView
                    permissionName='people'
                    getDataQuery={useGetPersonQuery}
                    tableColumns={COLUMNS}
                    handleRowClick={goTo.PeopleEdit}
                    hideRowsPerPageSelect={true}
                    fixedPageSize={10}
                />
                <Button color='primary' variant='contained' startIcon={<AddCircleIcon />} size='large' onClick={goTo.PeopleCreate} sx={{ mx: 1, mt: 1 }}>
                    Add New Person
                </Button>
            </Box>
        </Box>
    );
};
