import { Button, Chip, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactNode } from 'react';
import { FormInput } from '../../Components/CoreLib/library';
import { JournalQuestionDto } from '../../dtos';
import ActManagementPanel from './ActManagementPanel';
import { useQuestionInputFormManager } from './useQuestionInputFormManager';
import { AutoAwesome } from '@mui/icons-material';
import { IconButtonRound } from '../../Components/Global/IconButtonRound';

export interface IQuestionInputProps {
    question: JournalQuestionDto;
    onChange: (value: JournalQuestionDto) => void;
    disableAutoFocus?: boolean;
    questionNumber?: number;
    icon?: ReactNode;
}

export const QuestionInput: FC<IQuestionInputProps> = ({ question, onChange, disableAutoFocus, icon, questionNumber }) => {
    const { handleAnswerChange, isGeneratingVerseRecommendations, handleVerseGenerationButtonClicked, handleActChange } = useQuestionInputFormManager(
        question,
        onChange
    );

    const isAnyVerses = question.verseRecommendations?.length > 0;

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <Box display='flex' flexDirection='row' px={1} gap={.5}>
                <Box display='flex' alignItems='center' flexGrow={1}>
                    <Typography fontWeight='450'>
                        {questionNumber && <>{questionNumber}.</>} {question.questionText}
                    </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                    {icon}
                </Box>

            </Box>
            <Box px={1}>
                <FormInput
                    key={question.questionNumber}
                    fullWidth
                    label=''
                    value={question.answer}
                    onChange={(e) => handleAnswerChange(e.target.value)}
                    rows={3}
                    maxLength={question.answer?.length > 900 ? 1000 : undefined}
                    autoFocus={!disableAutoFocus}
                />
            </Box>
            <Box px={1} display='flex' flexWrap='nowrap' flexDirection='row' gap={1}>
                <Box flexGrow={1} display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                    {!isGeneratingVerseRecommendations && question.verseRecommendations?.map((verse) => (
                        <Chip
                            key={verse.id}
                            label={verse.verseAbbreviation}
                            sx={{ color: 'primary.main', textDecoration: 'underline' }}
                            onClick={() => window.open(verse.link, '_blank')}
                        />
                    ))}
                </Box>
                <Box>
                    {isGeneratingVerseRecommendations ? (
                        <Button variant='contained' color='primary' disabled>
                            <CircularProgress color='inherit' size={16} sx={{ mr: 1 }} /> Finding Related Verses
                        </Button>
                    ) : isAnyVerses ? <IconButtonRound onClick={handleVerseGenerationButtonClicked} icon={<AutoAwesome fontSize='small' />}></IconButtonRound> : (
                        <Button variant='contained' color='primary' onClick={handleVerseGenerationButtonClicked} startIcon={<AutoAwesome />}>
                            Find Related Verses
                        </Button>
                    )}
                </Box>
            </Box>
            {question.typeName === 'Do' && <ActManagementPanel currentQuestion={question} onChange={handleActChange} disableAutoFocus={disableAutoFocus} />}
        </Box>
    );
};
