import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { PageHeader } from '../../Components/Global';
import { useGoTo } from '../../routing/useGoTo';
import { Home } from '@mui/icons-material';

interface IInvitationFoundProps {
    groupName: string;
    onJoinClicked: () => void;
    isLoading?: boolean;
}

export const InvitationFound: FC<IInvitationFoundProps> = ({ groupName, onJoinClicked, isLoading }) => {
    const goTo = useGoTo();

    return (
        <>
            <PageHeader title={`You have been invited!`} />
            <Paper sx={{ m: 1 }}>
                <Box p={1} textAlign='center'>
                    <Typography>You have been invited to join the group: {groupName}</Typography>
                    <Button onClick={onJoinClicked} disabled={isLoading} fullWidth variant='contained' sx={{ mt: 1 }}>
                        Accept Invitation
                    </Button>
                </Box>
            </Paper>
            <Box px={1}>
                <Button variant='outlined' onClick={goTo.Home} fullWidth startIcon={<Home />}>
                    Go Home
                </Button>
            </Box>
        </>
    );
};
