import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Button } from "@mui/material";
import { Login } from "@mui/icons-material";

const LoginButton: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    const login = async () => {
        await loginWithRedirect({
            async openUrl(url) {
                await Browser.open({
                    url,
                    windowName: "_self"
                });
            }
        });
    };

    return (
        <Button fullWidth startIcon={<Login />} variant='contained' size='large' onClick={login}>
            Log In / Sign Up
        </Button>
    );
};

export default LoginButton;
