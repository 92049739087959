import { FC, useEffect, useState } from 'react';
import { LoadingIndicator } from '../../Components/CoreLib/library';
import { useGoTo } from '../../routing/useGoTo';
import { useGetGuidedReflectionIsReadyForReflectionQuery } from '../../store/generated/generatedApi';
import { CompleteReflectionForm } from './CompleteReflectionForm';

export const ReflectionView: FC = () => {
    const goTo = useGoTo();
    const { data: isReadyForReflection, isLoading: isCheckingIfUserIsReadyForReflection } = useGetGuidedReflectionIsReadyForReflectionQuery();
    const [preventRedirect, setPreventRedirect] = useState(false);

    useEffect(() => {
        if (isReadyForReflection === false && !preventRedirect) {
            goTo.JournalStart();
        }
    }, [goTo, isReadyForReflection, preventRedirect]);

    useEffect(() => {
        if (isReadyForReflection) {
            setPreventRedirect(true);
        }
    }, [isReadyForReflection]);

    if (isReadyForReflection === false) {
        goTo.JournalStart();
        return null;
    }

    if (isCheckingIfUserIsReadyForReflection) {
        return <LoadingIndicator />;
    }

    return <CompleteReflectionForm onCompletion={goTo.JournalHome} />;
};
