import { useCallback, useMemo } from "react"
import { useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { useArchiveUserGroupsShareActByIdMutation, useCreateUserGroupsShareActByIdMutation } from "../../store/generated/generatedApi";
import { GroupSharingDto } from "../../dtos";

export function useGroupListViewItemManager(group: GroupSharingDto, actId: string, onChange?: (updatedGroup: GroupSharingDto) => void) {
    const [shareAct, { isError: isShareActError, isLoading: isSharingAct }] = useCreateUserGroupsShareActByIdMutation();
    useFailedActionSnackbar('share', 'Fearless Act', isShareActError);
    const [unshareAct, { isError: isUnshareActError, isLoading: isUnsharingAct }] = useArchiveUserGroupsShareActByIdMutation();
    useFailedActionSnackbar('un-share', 'Fearless Act', isUnshareActError);

    const isLoading = useMemo(() => isSharingAct || isUnsharingAct,[isSharingAct, isUnsharingAct]);

    const handleShareActWithGroup = useCallback(() => {
        if (!isSharingAct) {
            shareAct({ groupId: group.id, actId: actId })
                .unwrap()
                .then(() => onChange && onChange({...group, isActSharedWithGroup: true}));
        }
    }, [isSharingAct, shareAct, onChange, group, actId]);

    const handleUnShareActWithGroup = useCallback(() => {
        if (!isUnsharingAct) {
            unshareAct({ groupId: group.id, actId: actId })
                .unwrap()
                .then(() => onChange && onChange({...group, isActSharedWithGroup: false}));
        }
    }, [isUnsharingAct, unshareAct, onChange, group, actId]);

    return {
        handleShareActWithGroup,
        handleUnShareActWithGroup,
        isLoading
    };
}