import { ActDto, ActListViewDto, JournalQuestionDto } from '../../dtos';
import { emptyGuid } from '../../util';
import { useCreateActGenerateMutation } from '../../store/generated/generatedApi';
import { useSnackbar } from 'notistack';

const useActManager = (currentQuestion: JournalQuestionDto, onChange: (updatedActs: ActListViewDto[]) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const [generateActs, { isLoading: isGeneratingActs }] = useCreateActGenerateMutation();

    const handleIdentifyingActsButton = () => {
        generateActs(currentQuestion)
            .unwrap()
            .then((res) => {
                if (res.length === 0) {
                    enqueueSnackbar('No Fearless Acts of Humility could be found.', { variant: 'warning' });
                } else {
                    onChange([...(currentQuestion.acts?.filter((act) => !act.isAiGenerated) ?? []), ...res]);
                }
            });
    };

    const handleAddActButton = () => {
        const newAct: ActDto = {
            questionNumber: currentQuestion.questionNumber,
            isActive: true,
            id: emptyGuid,
            text: '',
            journalId: currentQuestion.journalId,
            isAiGenerated: false,
            createdOn: new Date(),
            isComplete: false,
            involvedPeople: [],
            journalDate: new Date(),
            spaceIcon: '',
            userName: '',
            spaceName: ''
        };
        onChange([...(currentQuestion.acts ?? []), newAct]);
    };

    const handleRemoveCustomAct = (idxToRemove: number) => {
        onChange(currentQuestion.acts.filter((act, currentIndex) => currentIndex !== idxToRemove));
    };

    const handleOnChangeAct = (idxToChange: number, value: string) => {
        const updatedAct = currentQuestion.acts.map((act, currentIndex) => {
            return currentIndex === idxToChange ? { ...act, text: value, isAiGenerated: false } : act;
        });
        onChange(updatedAct);
    };

    return {
        acts: currentQuestion.acts,
        handleAddActButton,
        handleRemoveCustomAct,
        handleOnChangeAct,
        handleIdentifyingActsButton,
        isGeneratingActs,
    };
};

export default useActManager;
