import { FC } from 'react';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetUserMeQuery, useUpdateUserMeMutation } from '../../store/generated/generatedApi';
import { UserForm } from './UserForm';
import { useHandleLogout } from '../../util/useHandleLogout';

export const MyAccountView: FC = () => {
    const goTo = useGoTo();
    const { data: currentUser, isLoading: isLoadingCurrentUser, error: errorCurrentUser, refetch: refetchCurrentUser } = useGetUserMeQuery();
    const [updateUser, { isSuccess, isError, reset }] = useUpdateUserMeMutation();
    const handleLogout = useHandleLogout();

    useSuccessfulUpdateSnackbar('Your Account', isSuccess, () => {
        reset();
        handleLogout();
    });
    useFailedUpdateSnackbar('your account', isError, reset);

    const handleSave = (values: UserDto) => {
        updateUser(values);
    };

    if (isLoadingCurrentUser) {
        return <LoadingIndicator />;
    }

    if (errorCurrentUser) {
        return <ApiError onReloadClick={refetchCurrentUser} />;
    }

    return <UserForm save={handleSave} cancel={goTo.Home} initValues={currentUser} />;
};
