import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar } from '../../Components/CoreLib/library';
import { JournalDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { useGetJournalByIdQuery, useLazyGetGuidedReflectionIsReadyForReflectionQuery, useUpdateJournalMutation } from '../../store/generated/generatedApi';
import { CompleteJournalForm } from './CompleteJournalForm';

export const CompleteJournalView: FC = () => {
    const { id } = useParams();
    const goTo = useGoTo();
    const { data: journal, isLoading: isLoadingJournal, error: errorLoadingJournal, refetch: refetchJournal } = useGetJournalByIdQuery({ id: id! });
    const [updateJournal, { isError, reset }] = useUpdateJournalMutation();
    useFailedUpdateSnackbar('journal', isError, reset);
    const [getIsReadyForReflection] = useLazyGetGuidedReflectionIsReadyForReflectionQuery();

    const handleSave = useCallback(
        (values: JournalDto) => {
            updateJournal(values).then(() => {
                getIsReadyForReflection()
                    .unwrap()
                    .then((isReady) => {
                        if (isReady) {
                            goTo.PostJournalReflection();
                        } else {
                            goTo.JournalHome();
                        }
                    })
                    .catch(() => {
                        console.error('Error checking if ready for reflection, sending the user to the Journal Home');
                        goTo.JournalHome();
                    });
            });
        },
        [getIsReadyForReflection, updateJournal, goTo]
    );

    if (isLoadingJournal) {
        return <LoadingIndicator />;
    }

    if (errorLoadingJournal || !journal) {
        return <ApiError onReloadClick={refetchJournal} />;
    }

    return <CompleteJournalForm save={handleSave} initValues={journal} />;
};
