import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { GuidedReflectionDto } from '../../dtos';
import { ReflectionStepReCommit } from './ReflectionStepReCommit';
import { ReflectionStepStories } from './ReflectionStepStories';
import { ReflectionStepVideo } from './ReflectionStepVideo';
import { useReflectionStepper } from './useReflectionStepper';

export interface IReflectionStepperProps {
    reflection?: GuidedReflectionDto;
    onCompletion: () => void;
}

export const ReflectionStepper: FC<IReflectionStepperProps> = ({ reflection = null, onCompletion }) => {
    const { activeStep, setActiveStep, handleSubmitClicked } = useReflectionStepper(reflection, onCompletion);

    const stepperContent = useMemo(() => {
        switch (activeStep) {
            case 0:
                if (reflection === null) {
                    setActiveStep(1);
                    return null;
                }
                return <ReflectionStepVideo video={reflection.video} onNextClicked={() => setActiveStep(1)} />;
            case 1:
                return (
                    <ReflectionStepReCommit onBackClicked={reflection === null ? undefined : () => setActiveStep(0)} onNextClicked={() => setActiveStep(2)} />
                );
            case 2:
                return <ReflectionStepStories onBackClicked={() => setActiveStep(1)} onStartPrayingClicked={handleSubmitClicked} />;
            default:
                setActiveStep(0);
                return null;
        }
    }, [activeStep, handleSubmitClicked, reflection, setActiveStep]);

    return <Box height='100%'>{stepperContent}</Box>;
};
