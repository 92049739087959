import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { DatePicker, PickersActionBarAction } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';

export interface IStandardDatePickerProps {
    value: Date | null;
    onChange: (value: Date | null) => void;
    errorText?: string;
    fullWidth?: boolean;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    clearable?: boolean;
};

export const StandardDatePicker: FC<IStandardDatePickerProps> = ({
    value,
    onChange,
    errorText,
    fullWidth,
    required,
    disabled,
    label,
    clearable
}) => {

    const currentValue = useMemo(() => value !== null ? dayjs(value) : null,[value]);
    const handleValueChange = useCallback((value: Dayjs | null) => onChange(value?.toDate() ?? null), [onChange]);
    const visibleActions = useMemo<PickersActionBarAction[]>(() => clearable ? ['cancel', 'clear'] : ['cancel'], [clearable]);

    return (
        <FormControl error={!!errorText} fullWidth={fullWidth} required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <DatePicker
                disabled={disabled}
                value={currentValue}
                onChange={handleValueChange}
                closeOnSelect
                slotProps={{
                    actionBar: {
                        actions: visibleActions
                    }
                }}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};