import { Edit, People } from '@mui/icons-material';
import { Box, Button, Paper } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { StandardDatePicker } from '../../Components/CommonInputs';
import { FormInput } from '../../Components/CoreLib/library';
import { PageHeader } from '../../Components/Global';
import { UserGroupDto } from '../../dtos';
import { useGoTo } from '../../routing/useGoTo';
import { GroupInviteLinkSection } from './GroupInviteLinkSection';
import { GroupMemberManagementSection } from './GroupMemberManagementSection';

interface IManageGroupFormProps {
    group: UserGroupDto;
    handleEditClicked: () => void;
}

export const ManageGroupForm: FC<IManageGroupFormProps> = ({ group, handleEditClicked }) => {
    const goTo = useGoTo();
    const isAdmin = useMemo(() => group.isAdmin, [group.isAdmin]);
    const handleCloseClicked = useCallback(() => {
        goTo.GroupDetails(group.id);
    }, [goTo, group.id]);

    return (
        <Box display='flex' flexDirection='column' rowGap={1}>
            <PageHeader title='Manage Group' icon={<People />} onActionButtonClicked={handleCloseClicked} />
            <Box display='flex' flexDirection='column' px={1} gap={1}>
                <Paper>
                    <Box display='flex' flexDirection='row' gap={1} p={1}>
                        <FormInput value={group.name} fullWidth onChange={() => { }} label='Name' disabled />
                        <StandardDatePicker value={group.endDate} fullWidth onChange={() => { }} label='End Date' disabled />
                    </Box>
                </Paper>
                {isAdmin && group.invitationCode && <GroupInviteLinkSection inviteCode={group.invitationCode} />}
                {isAdmin && <GroupMemberManagementSection groupId={group.id} />}
                <Button fullWidth startIcon={<Edit />} variant='contained' size='large' onClick={handleEditClicked}>
                    Edit
                </Button>
            </Box>
        </Box >
    );
};
