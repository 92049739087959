import { AutoStories, Check, ExpandMore, RemoveDone } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Icon, Paper, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';
import { ShareToGroupIcon } from '../../Components/CustomIcons';
import { ActListViewDto } from '../../dtos';
import { standardDateFormat } from '../../util';
import { useActItemManager } from './useActItemManager';

interface IActListViewItemProps {
    act: ActListViewDto;
    onChange?: (act: ActListViewDto) => void;
    onShareClicked: () => void;
    showActUser?: boolean;
}

export const ActListViewItem: FC<IActListViewItemProps> = ({ act, onChange, onShareClicked, showActUser }) => {
    const { handleCompleteAct, handleUncompleteAct, handleViewJournalClicked, isLoading } = useActItemManager(act, onChange);

    const stopPropagationThen = useCallback(
        (action: () => void): MouseEventHandler<HTMLButtonElement> =>
            (event) => {
                event.stopPropagation();
                action();
            },
        []
    );

    return (
        <Paper sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1, p: 1, overflow: 'hidden' }}>
            <Typography sx={{ textDecoration: act.isComplete ? 'line-through' : undefined }}>{act.text}</Typography>
            {(act.completedOn || showActUser) && (
                <Box display='flex' flexWrap='wrap' justifyContent='space-between' width='100%'>
                    {act.completedOn && <Chip label={`Completed: ${standardDateFormat(act.completedOn)}`} />}
                    {showActUser && <Chip label={act.userName} />}
                </Box>
            )}
            <Accordion disableGutters sx={{ width: '100%' }} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                        px: 0,
                        '&.Mui-expanded': {
                            minHeight: '48px',
                        },
                    }}>
                    <Box display='flex' gap={1} flexWrap='wrap' width='100%'>
                        {act.isCurrentUsersAct !== false && act.isComplete && (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={stopPropagationThen(handleUncompleteAct)}
                                disabled={isLoading}
                                startIcon={<RemoveDone />}>
                                Incomplete
                            </Button>
                        )}
                        {act.isCurrentUsersAct !== false && !act.isComplete && (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={stopPropagationThen(handleCompleteAct)}
                                disabled={isLoading}
                                startIcon={<Check />}>
                                Complete
                            </Button>
                        )}
                        {act.isCurrentUsersAct !== false && (
                            <Button variant='contained' color='primary' onClick={stopPropagationThen(onShareClicked)} startIcon={<ShareToGroupIcon />}>
                                Share
                            </Button>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0 }}>
                    <Box display='flex' gap={1} flexWrap='wrap' width='100%' overflow='hidden'>
                        <Chip label={standardDateFormat(act.journalDate)} />
                        <Chip
                            icon={
                                <Icon
                                    sx={{
                                        color: 'white !important',
                                        bgcolor: '#bdbdbd',
                                        borderRadius: '50%',
                                        p: '2px',
                                        height: '24px',
                                        width: '24px',
                                        fontSize: '20px',
                                    }}>
                                    {act.spaceIcon}
                                </Icon>
                            }
                            label={act.spaceName}
                        />
                        {act.involvedPeople.map((person, idx) => (
                            <Chip key={`${idx}-${person}`} label={person} />
                        ))}
                    </Box>
                    {act.isCurrentUsersAct !== false && (
                        <Button variant='contained' color='primary' onClick={handleViewJournalClicked} startIcon={<AutoStories />} sx={{ alignSelf: 'end' }}>
                            Go To Journal
                        </Button>
                    )}
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};
