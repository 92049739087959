import { Church, Home, Place, School, Work } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from '@mui/material';
import {FC} from 'react';

export interface IIconSelectProps {
    label?: string;
    value: string;
    onChange: (value: string) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: () => void;
    fullWidth?: boolean;
};

const MENU_ITEM_STYLES = { display: 'flex', justifyContent: 'center' };

export const IconSelect: FC<IIconSelectProps> = ({
    label,
    value,
    onChange,
    required,
    errorMessage,
    disabled,
    onBlur,
    fullWidth
}) => {
    return (
        <FormControl error={!!errorMessage} fullWidth={fullWidth} required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <Select size='small' value={value} onChange={e => onChange(e.target.value)} onBlur={onBlur} sx={{ height: '56px' }}>
                <MenuItem value='' sx={{ display: 'none' }}></MenuItem>
                <MenuItem value='place' sx={MENU_ITEM_STYLES}><Place /></MenuItem>
                <MenuItem value='work' sx={MENU_ITEM_STYLES}><Work /></MenuItem>
                <MenuItem value='home' sx={MENU_ITEM_STYLES}><Home /></MenuItem>
                <MenuItem value='school' sx={MENU_ITEM_STYLES}><School /></MenuItem>
                <MenuItem value='church' sx={MENU_ITEM_STYLES}><Church /></MenuItem>
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
};